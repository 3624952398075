<template>
  <div>
    <div class="item-detail-row cta">
      <button class="c-btn btn-view-details" @click="navigateToDetails">
        view details
      </button>
      <BtnLike :record="item" />
    </div>
    <div v-if="isBuyer" class="item-detail-row cta asking-price">
      <span class="label">Asking Price</span>
      <span class="label">{{ itemAskingPrice | currency }}</span>
    </div>
    <div class="item-detail-row cta">
      <btn-transfer-cart
        v-if="curUserPerms.canTransfer"
        :item="item"
        text="Transfer"
      />
      <btn-shopping-cart v-if="isBuyer" :item="item" text="Buy" />
    </div>
    <div v-if="curUserPerms.canSetAskingPrice" class="item-detail-row cta">
      <asking-price
        :item="item"
        @on-set-for-sale="handleSetForSale"
        @on-set-asking-price="handleSetAskingPrice"
      />
    </div>
    <div v-if="curUserPerms.canTransfer" class="item-detail-row cta">
      <compare :item="item" />
    </div>
  </div>
</template>
<script>
import AskingPrice from "@/components/ui/AskingPrice";
import Compare from "@/components/ui/CompareToggler.vue";
import BtnLike from "@/components/ui/buttons/BtnFavorite";
import BtnShoppingCart from "@/components/ui/buttons/BtnShoppingCart";
import BtnTransferCart from "@/components/ui/buttons/BtnTransferCart";
import router from "../../../router";
import { mapState } from "vuex";

export default {
  name: "ItemButtons",
  components: {
    AskingPrice,
    BtnLike,
    BtnShoppingCart,
    BtnTransferCart,
    Compare,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isItemForSale: false,
      itemAskingPrice: null,
    };
  },
  computed: {
    ...mapState(["curUserPerms"]),
    hasAskingPrice() {
      return (
        this.itemAskingPrice !== 0 &&
        this.itemAskingPrice !== undefined &&
        this.itemAskingPrice !== null &&
        this.itemAskingPrice !== ""
      );
    },
    isBuyer() {
      return (
        this.curUserPerms.canBuy && this.isItemForSale && this.hasAskingPrice
      );
    },
  },
  created() {
    this.itemAskingPrice = this.item.fields.AskingPrice;
    this.isItemForSale = this.item.fields.ForSale || false;
  },
  methods: {
    handleSetForSale(payload) {
      this.isItemForSale = payload;
      if (this.isBuyer) {
        this.$root.$emit("update-masonry");
      }
    },
    handleSetAskingPrice(payload) {
      this.itemAskingPrice = payload;
      if (this.isBuyer || payload === "") {
        this.$root.$emit("update-masonry");
      }
    },
    navigateToDetails() {
      this.$store.commit("setCurrentRecordId", this.item.id);
      router.push({
        path: `/item/${this.item.id}`,
        query: {
          hasNav: true,
        },
      });
    },
  },
};
</script>
