<template>
  <tr class="save-pdf__item">
    <td class="save-pdf__details">
      <table>
        <tr
          v-if="item.fields['Inventory No.'] && isInventory"
          class="detail-row item-inventory"
        >
          <td>
            <div class="label">Inventory #</div>
            <div class="value">#{{ item.fields["Inventory No."] }}</div>
          </td>
        </tr>
        <tr v-if="value && isValue" class="detail-row item-value">
          <td>
            <div class="label">Value</div>
            <div class="value">
              {{ value | currency }}
            </div>
          </td>
        </tr>
        <tr
          v-if="item.fields['Current Location'] && isLocation"
          class="detail-row item-location"
        >
          <td>
            <div class="label">Location</div>
            <div class="value">
              {{ item.fields["Current Location"] | titlecase }}
              <div v-if="sublocation" class="sublocation">
                ({{ sublocation }})
              </div>
            </div>
          </td>
        </tr>
        <tr
          v-if="dimensions && isDimensions"
          class="detail-row item-dimensions"
        >
          <td>
            <div class="label">Dimensions</div>
            <div class="value">{{ dimensions }}</div>
          </td>
        </tr>
        <tr v-if="qrCode && isQRCode" class="detail-row item-qrcode">
          <td>
            <div class="label">QR Code</div>
            <img :src="qrCode" alt="Thumnail Image" class="qr-code" />
          </td>
        </tr>
      </table>
    </td>
    <td class="save-pdf__description">
      <table>
        <tr class="detail-row item-artist">
          <td>
            <span class="item-artist">
              {{ item.fields.Artist | sanitize | titlecase }}
            </span>
          </td>
        </tr>
        <tr class="detail-row item-title">
          <td>
            <span class="item-title">
              {{ item.fields.TitleClean | sanitize | titlecase }}
            </span>
          </td>
        </tr>
        <tr class="detail-row item-description">
          <td>
            {{ preview }}
          </td>
        </tr>
      </table>
    </td>
    <td v-if="item.fields.Image" class="save-pdf__image">
      <span v-if="typeof item.fields.Image === 'object'">
        <img
          v-if="item.fields.Image[item.fields.Image.length - 1].thumbnails"
          :src="
            item.fields.Image[item.fields.Image.length - 1].thumbnails.large.url
          "
          alt="Thumnail Image"
        />
      </span>
      <span v-if="typeof item.fields.Image === 'string'">
        <img
          v-if="item.fields.Image.thumbnails"
          :src="item.fields.Image.thumbnails.large.url"
          alt="Thumnail Image"
        />
      </span>
    </td>
    <td v-else class="save-pdf__no-image">
      <img alt="No Image" src="/assets/images/no-image.jpg" />
    </td>
  </tr>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "SavePdfItem",
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    isInventory: {
      type: Boolean,
      required: true,
      default: () => true,
    },
    isValue: {
      type: Boolean,
      required: true,
      default: () => true,
    },
    isLocation: {
      type: Boolean,
      required: true,
      default: () => true,
    },
    isDimensions: {
      type: Boolean,
      required: true,
      default: () => true,
    },
    isQRCode: {
      type: Boolean,
      required: true,
      default: () => true,
    },
  },
  computed: {
    ...mapState(["filters"]),
    preview() {
      return (
        this.item &&
        this.item.fields &&
        this.item.fields.DescriptionClean &&
        this.item.fields.DescriptionClean.substring(0, 1000) + "..."
      );
    },
    sublocation() {
      return this.item && this.item.fields && this.item.fields.SubLocationName;
    },
    value() {
      return this.item && this.item.fields && this.item.fields.Value;
    },
    dimensions() {
      return this.item && this.item.fields && this.item.fields.Dimensions;
    },
    qrCode() {
      return (
        (this.item &&
          this.item.fields &&
          this.item.fields["QR Code"] &&
          this.item.fields["QR Code"][0] &&
          this.item.fields["QR Code"][0].thumbnails &&
          this.item.fields["QR Code"][0].thumbnails.large &&
          this.item.fields["QR Code"][0].thumbnails.large.url) ||
        "/assets/images/no-qr.jpg"
      );
    },
  },
};
</script>
