<template>
  <main id="currentRecordPage" aria-label="content">
    <nav v-if="hasNav === 'true'" class="item-detail-nav item-detail-wrap">
      <button
        :class="{ disabled: isFirst }"
        :disabled="isFirst"
        class="prev-item"
        @click="routerPush('prev')"
      >
        <ArrowLeft />
      </button>
      <button class="back-to-list" @click="backToInventory">
        BACK TO LIST
      </button>
      <button
        :class="{ disabled: isLast }"
        :disabled="isLast"
        class="next-item"
        @click="routerPush('next')"
      >
        <ArrowRight />
      </button>
    </nav>
    <!--    model viewer-->
    <div
      v-if="
        curUserPerms.permittedArtInventoryFields.includes('ModelGLB') && model
      "
      class="model-viewer"
    >
      <model-viewer
        :src="model"
        ar
        ar-modes="webxr scene-viewer quick-look"
        ar-scale="auto"
        auto-rotate
        camera-controls
        disable-zoom="true"
        exposure="1.69"
      />
      <footer class="controls">
        <span>Mousewheel: zoom</span>
        <span class="mx-2">|</span>
        <span>Left mouse: rotate</span>
      </footer>
    </div>
    <section
      v-if="currentRecord && currentRecord.fields"
      :class="{ 'has-nav': hasNav }"
      class="o-section__wrapper details"
    >
      <!-- Agile Slider -->
      <div
        v-if="
          showField(['Image', 'All']) && galleryImages && galleryImages.length
        "
        class="c-details__col slider"
      >
        <agile
          ref="mainCarousel"
          :as-nav-for="asNavFor1"
          :class="{
            'has-one-slide': galleryImages.length <= 1,
            'fade-in': galleryReady,
          }"
          :options="imageOptions"
        >
          <a
            v-for="(slideUrl, index) in galleryImages"
            :key="`slideUrl_${index}`"
            :href="slideUrl"
            class="slide"
            data-fancybox="images"
          >
            <img
              :src="slideUrl"
              alt="Main Slider Image"
              @load="handleImageLoaded(slideUrl)"
            />
          </a>
          <div v-if="galleryImages.length > 1" slot="prevButton">
            <svg
              fill="none"
              viewBox="0 0 11 20"
              width="11"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 1L1 10L10 19" stroke="#1A120F" />
            </svg>
          </div>
          <div v-if="galleryImages.length > 1" slot="nextButton">
            <svg
              fill="none"
              viewBox="0 0 11 20"
              width="11"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L10 10L1 19" stroke="#1A120F" />
            </svg>
          </div>
        </agile>
        <!-- Agile Thumbs -->
        <agile
          v-if="galleryThumbs && galleryThumbs.length"
          ref="thumbCarousel"
          :as-nav-for="asNavFor2"
          :class="{ 'fade-in': galleryReady }"
          :options="thumbOptions"
          class="thumbnails thumbnails__wrap"
        >
          <a
            v-for="(thumbUrl, index) in galleryThumbs"
            :key="`thumb_${index}`"
            :class="`slide--${index}`"
            class="thumbUrl slide--thumbnail"
            @click="changeSlideByIndex(index)"
          >
            <img :src="thumbUrl" alt="Image" />
          </a>
          <template slot="prevButton">
            <svg
              fill="none"
              viewBox="0 0 11 20"
              width="8"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 1L1 10L10 19" stroke="#1A120F" />
            </svg>
          </template>
          <template slot="nextButton">
            <svg
              fill="none"
              viewBox="0 0 11 20"
              width="8"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L10 10L1 19" stroke="#1A120F" />
            </svg>
          </template>
        </agile>
      </div>
      <div v-else>
        <div class="slide">
          <img alt="No image" src="/assets/images/no-image.jpg" />
        </div>
      </div>
      <!--      item details item details item details item details item details item details item details -->
      <div class="c-details__col info">
        <div class="c-modal__details">
          <!--         ARTIST -->
          <div class="c-modal__head">
            <div
              v-if="showField(['Artist', 'All']) && currentRecord.fields.Artist"
              class="c-modal__artist"
            >
              {{ currentRecord.fields.Artist | sanitize }}
            </div>
            <!--              TITLE-->
            <truncate
              v-if="showField(['TitleClean', 'All'])"
              :length="200"
              :text="currentRecord.fields.TitleClean | sanitize"
              action-class="c-btn c-btn--readmore"
              clamp="See More"
              class="modal-title"
              less="See Less"
              type="html"
            />
            <!--                DESCRIPTION-->
            <truncate
              v-if="
                showField(['DescriptionClean', 'All']) &&
                currentRecord.fields.DescriptionClean
              "
              :length="500"
              :text="
                currentRecord.fields.DescriptionClean | sanitizeDescription
              "
              action-class="c-btn c-btn--readmore"
              clamp="See More"
              class="c-modal__subtitle"
              less="See Less"
              type="html"
            />
            <!--          CATEGORY-->
            <div
              v-if="
                showField(['Category', 'All']) &&
                currentRecord.fields.CategoryName
              "
              class="c-tag"
            >
              <a
                class="c-tag__items c-tag--category"
                @click.stop="
                  setCurrentCategory(currentRecord.fields.CategoryName)
                "
              >
                <span class="mr-2">Category:</span>
                <span>{{ currentRecord.fields.CategoryName }}</span>
              </a>
              <div
                v-if="currentRecord.fields['Inventory number']"
                class="c-tag__items c-tag--symbol"
              >
                Inventory number:
                {{ currentRecord.fields["Inventory No."] }}
              </div>
            </div>
          </div>
        </div>
        <!--            ASKING PRICE -->
        <div v-if="isBuyer" class="item-detail-wrap asking-price">
          <div class="item-detail-wrap__title">ASKING PRICE:</div>
          <div class="c-modal__item__text">
            {{ currentRecord.fields.AskingPrice | currency }}
          </div>
        </div>
        <!--        BUTTON ROW-->
        <div class="c-modal__buttons">
          <BtnTransferCart
            v-if="curUserPerms.canTransfer"
            :item="currentRecord"
            text="Transfer"
          />
          <BtnShoppingCart v-if="isBuyer" :item="currentRecord" text="Buy" />
          <BtnLike :record="currentRecord" />
        </div>
        <!--        INVOICE NUMBER-->
        <div
          v-if="
            showField(['Invoice No.', 'All']) &&
            currentRecord.fields['Invoice No.']
          "
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">INVOICE #:</div>
          <div
            v-for="(element, index) in currentRecord.fields['Invoice Title']"
            :key="'C' + index"
            class="c-modal__item__text"
          >
            <button
              class="c-btn c-btn--invoice"
              @click="navigateToInvoice(currentRecord.fields['Invoice No.'])"
            >
              <span>{{ element }}</span>
            </button>
          </div>
        </div>
        <!--            INVENTORY NUMBER -->
        <div
          v-if="
            showField(['Inventory No.', 'All']) &&
            currentRecord.fields['Inventory No.']
          "
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">INVENTORY #:</div>
          <div class="c-modal__item__text">
            {{ currentRecord.fields["Inventory No."] }}
          </div>
        </div>
        <!--            STATUS-->
        <div
          v-if="showField(['Status', 'All']) && curStatus"
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">STATUS:</div>
          <div class="c-modal__item__text">
            <div class="c-modal__status">
              <span :class="`c-status--${curStatus}`" class="c-status" />{{
                curStatus
              }}
            </div>
          </div>
        </div>
        <!--            COLLECTIONS -->
        <div
          v-if="
            showField(['CollectionNames', 'All']) &&
            currentRecord.fields.CollectionNames
          "
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">COLLECTIONS:</div>
          <div class="c-modal__item__text">
            {{ currentRecord.fields.CollectionNames }}
          </div>
        </div>
        <!--            QR CODE -->
        <div
          v-if="showField(['QR Code', 'All']) && getQRCode"
          class="item-detail-wrap qr-code"
        >
          <div class="item-detail-wrap__title">QR CODE:</div>
          <div class="c-modal__item__text">
            <a
              :data-src="getQRCode"
              data-fancybox
              data-options='{"buttons": ["share", "close"]}'
            >
              <img :src="getQRCode" alt="QR Code" />
            </a>
          </div>
        </div>
        <!--            CARPET TAG NUMBER -->
        <div
          v-if="
            showField(['Carpet Tag No.', 'All']) &&
            currentRecord.fields['Carpet Tag No.']
          "
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">CARPET TAG #:</div>
          <div class="c-modal__item__text">
            {{ currentRecord.fields["Carpet Tag No."] }}
          </div>
        </div>
        <!--            SANJAY  NUMBER -->
        <div
          v-if="
            showField(['Sanjay No.', 'All']) &&
            currentRecord.fields['Sanjay No.']
          "
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">SANJAY #:</div>
          <div class="c-modal__item__text">
            {{ currentRecord.fields["Sanjay No."] }}
          </div>
        </div>
        <!--            LOCATION-->
        <div
          v-if="
            showField(['LocationName', 'All']) &&
            currentRecord.fields.LocationName
          "
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">LOCATION:</div>
          <div class="c-modal__item__text">
            {{ currentRecord.fields.LocationName }}
          </div>
        </div>
        <!--            SUBLOCATION-->
        <div
          v-if="
            showField(['SubLocationName', 'All']) &&
            currentRecord.fields.SubLocationName
          "
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">SUBLOCATION:</div>
          <div class="c-modal__item__text">
            {{ currentRecord.fields.SubLocationName }}
          </div>
        </div>
        <!--            DIMENSIONS-->
        <div
          v-if="
            showField(['Dimensions', 'All']) && currentRecord.fields.Dimensions
          "
          class="item-detail-wrap item-detail-wrap--vertical"
        >
          <div class="item-detail-wrap__title">DIMENSIONS:</div>
          <nl2br
            :text="currentRecord.fields.Dimensions | sanitize"
            class-name="c-modal__item__text"
            tag="div"
          />
        </div>
        <!--            QUALITY-->
        <!--        edit-->
        <div
          v-if="showField(['QualityName', 'All']) && curUserPerms.canSetQuality"
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">QUALITY:</div>
          <div id="qualityDropdown" ref="qualitySelect">
            <div
              :class="{ 'is-open': isOpen }"
              class="c-dropdown c-dropdown--sort js-dropdown quality-dropdown"
              @click="isOpen = !isOpen"
            >
              <div class="c-dropdown__title js-dropdown-title">
                <span class="c-dropdown__text">
                  {{ currentQuality }}
                </span>
              </div>
              <div class="modal-backdrop"></div>
              <dropdown-quality
                :has-checkbox="false"
                :quality-options="qualityOptions"
                @set-quality="handleSetQuality"
              />
            </div>
          </div>
        </div>
        <!--        view-->
        <div
          v-else-if="showField(['QualityName', 'All'])"
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">QUALITY:</div>
          <div class="c-modal__item__text">
            {{ currentQuality }}
          </div>
        </div>
        <!--            DIMENSIONS CM-->
        <div
          v-if="
            canViewDimensionsCM &&
            currentRecord.fields['Length CM'] &&
            currentRecord.fields['Width CM']
          "
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">DIMENSIONS (CM):</div>
          <div
            v-if="
              currentRecord.fields['Length CM'] &&
              currentRecord.fields['Width CM']
            "
            class="c-modal__item__text"
          >
            {{ currentRecord.fields["Length CM"] }}cm x
            {{ currentRecord.fields["Width CM"] }}cm
          </div>
        </div>
        <!--            DIMENSIONS IN-->
        <div
          v-if="
            canViewDimensionsIN &&
            currentRecord.fields['Length CM'] &&
            currentRecord.fields['Width CM']
          "
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">DIMENSIONS (IN):</div>
          <div class="c-modal__item__text">
            {{ currentRecord.fields["Length IN"] }}" x
            {{ currentRecord.fields["Width IN"] }}"
          </div>
        </div>
        <!--            DIMENSIONS FT IN-->
        <div
          v-if="
            canViewDimensionsFTIN &&
            currentRecord.fields['Length FT IN'] &&
            currentRecord.fields['Width FT IN']
          "
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">DIMENSIONS (FT IN):</div>
          <div class="c-modal__item__text">
            {{ currentRecord.fields["Length FT IN"] }} x
            {{ currentRecord.fields["Width FT IN"] }}
          </div>
        </div>
        <!--            VALUE-->
        <div
          v-if="showField(['Value', 'All']) && currentRecord.fields.Value"
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">VALUE:</div>
          <div class="c-modal__item__text">
            {{ currentRecord.fields.Value | currency }}
          </div>
        </div>
        <!--            FOR SALE -->
        <div v-if="curUserPerms.canSetAskingPrice" class="item-detail-wrap">
          <asking-price
            :item="currentRecord"
            @on-set-for-sale="handleSetForSale"
            @on-set-asking-price="handleSetAskingPrice"
          />
        </div>
        <!--            MERCHANT  -->
        <div
          v-if="showField(['Merchant', 'All']) && currentRecord.fields.Merchant"
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">MERCHANT:</div>
          <div class="c-modal__item__text">
            {{ currentRecord.fields.MerchantName }}
          </div>
        </div>
        <!--            SALE-->
        <div
          v-if="showField(['Sale', 'All']) && currentRecord.fields.Sale"
          class="item-detail-wrap item-detail-wrap--vertical"
        >
          <div class="item-detail-wrap__title">SALE:</div>
          <nl2br
            :text="currentRecord.fields.Sale | titlecase | sanitize"
            class-name="c-modal__item__text"
            tag="div"
          />
        </div>
        <!--            SALE DATE-->
        <div
          v-if="
            showField(['Sale Date', 'All']) && currentRecord.fields['Sale Date']
          "
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">SALE DATE:</div>
          <div class="c-modal__item__text">
            {{ currentRecord.fields["Sale Date"] | date("date") }}
          </div>
        </div>
        <!--            LOT NUMBER -->
        <div
          v-if="
            showField(['Lot No.', 'All']) && currentRecord.fields['Lot No.']
          "
          class="item-detail-wrap"
        >
          <div class="item-detail-wrap__title">LOT #:</div>
          <div class="c-modal__item__text">
            {{ currentRecord.fields["Lot No."] }}
          </div>
        </div>
        <!--        VIEW NOTES-->
        <div
          v-if="showField(['Notes', 'All']) && previousNote"
          class="item-detail-wrap notes-list"
        >
          <label class="item-detail-wrap__title" for="notesList">notes:</label>
          <TextareaAutosize
            id="notesList"
            v-model="previousNote"
            :max-height="450"
            :min-height="30"
            class="c-form__input c-form__notes"
            disabled
          />
        </div>
        <!--        EDIT NOTES-->
        <div v-if="curUserPerms.canEditNotes" class="item-detail-wrap notes">
          <label class="item-detail-wrap__title" for="newNote"
            >add / ammend note:</label
          >
          <textarea
            id="newNote"
            v-model="newNote"
            class="c-form__input c-form__notes"
            type="text"
            @keypress="updateNote"
          />
          <button
            :disabled="!textChanged"
            class="c-btn c-btn--update"
            @click="submitUpdatedNotes"
          >
            <span>Save Note</span>
          </button>
        </div>
      </div>
    </section>
    <section v-else class="spinner-wrap">
      <Spinner />
    </section>
  </main>
</template>
<script>
import "@google/model-viewer";
import AskingPrice from "@/components/ui/AskingPrice";
import BtnLike from "@/components/ui/buttons/BtnFavorite";
import BtnShoppingCart from "@/components/ui/buttons/BtnShoppingCart";
import BtnTransferCart from "@/components/ui/buttons/BtnTransferCart";
import Spinner from "@/components/ui/Spinner";
import Truncate from "vue-truncate-collapsed";
import _ from "lodash";
import router from "@/router/index";
import { VueAgile } from "vue-agile";
import { base, agileOptions } from "@/constants";
import { mapState } from "vuex";
import DropdownQuality from "@/components/ui/DropdownQuality";
import ArrowLeft from "vue-material-design-icons/ArrowLeft";
import ArrowRight from "vue-material-design-icons/ArrowRight";

export default {
  name: "ItemDetails",
  components: {
    ArrowLeft,
    ArrowRight,
    AskingPrice,
    BtnLike,
    BtnShoppingCart,
    BtnTransferCart,
    DropdownQuality,
    Spinner,
    Truncate,
    agile: VueAgile,
  },
  data() {
    return {
      asNavFor1: [],
      asNavFor2: [],
      combinedNote: "",
      currentQuality: "",
      locRecordId: "",
      currentRecord: null,
      fields: [],
      galleryImages: [],
      galleryReady: false,
      galleryThumbs: [],
      hasNav: "false",
      imageOptions: agileOptions.imageOptions,
      isItemForSale: false,
      isOpen: false,
      itemAskingPrice: null,
      loadedImageArr: [],
      loadedImageCount: 0,
      model: "",
      newNote: "",
      newRecordId: "",
      notes: "",
      previousNote: null,
      quality: "",
      qualityOptions: [],
      thumbOptions: agileOptions.thumbOptions,
      userName: "",
    };
  },
  computed: {
    ...mapState([
      "authData",
      "categories",
      "collections",
      "curUserPerms",
      "curItemDetailRecord",
      "currentQueryURL",
      "currentRecordId",
      "filteredItemIds",
      "fitlers",
      "locations",
      "merchants",
      "networkBusyStatus",
      "qualities",
      "queryURL",
      "sublocations",
      "userRoles",
    ]),
    curStatus() {
      return (
        this.currentRecord &&
        this.currentRecord.fields &&
        this.currentRecord.fields.Status &&
        this.currentRecord.fields.Status[0]
      );
    },
    canViewDimensionsCM() {
      return ["Length CM", "Width CM"].every((i) =>
        this.curUserPerms.permittedArtInventoryFields.includes(i),
      );
    },
    canViewDimensionsIN() {
      return ["Length IN", "Width IN"].every((i) =>
        this.curUserPerms.permittedArtInventoryFields.includes(i),
      );
    },
    canViewDimensionsFTIN() {
      return ["Length FT IN", "Width FT IN"].every((i) =>
        this.curUserPerms.permittedArtInventoryFields.includes(i),
      );
    },
    textChanged() {
      return this.notes !== this.newNote;
    },
    isFirst() {
      return this.filteredItemIds.indexOf(this.currentRecordId) === 0;
    },
    isLast() {
      return this.currentRecordIndex === this.filteredItemIds.length - 1;
    },
    isPrevious() {
      return this.filteredItemIds[
        this.filteredItemIds.indexOf(this.currentRecordId) - 1
      ];
    },
    currentRecordIndex() {
      return this.filteredItemIds.indexOf(this.currentRecordId);
    },
    isNext() {
      return this.filteredItemIds[
        this.filteredItemIds.indexOf(this.currentRecordId) + 1
      ];
    },
    hasAskingPrice() {
      return (
        this.itemAskingPrice !== 0 &&
        this.itemAskingPrice !== undefined &&
        this.itemAskingPrice !== null &&
        this.itemAskingPrice !== ""
      );
    },
    isBuyer() {
      return (
        this.curUserPerms.canBuy && this.isItemForSale && this.hasAskingPrice
      );
    },

    getQRCode() {
      return (
        this.currentRecord &&
        this.currentRecord.fields &&
        this.currentRecord.fields["QR Code"] &&
        this.currentRecord.fields["QR Code"][0] &&
        this.currentRecord.fields["QR Code"][0].thumbnails &&
        this.currentRecord.fields["QR Code"][0].thumbnails.full &&
        this.currentRecord.fields["QR Code"][0].thumbnails.full.url
      );
    },
  },
  watch: {
    curItemDetailRecord: {
      handler(newVal) {
        if (newVal) {
          this.currentRecord = _.clone(newVal);
          this.updateLocalData();
        }
      },
      deep: true,
    },
  },

  /**

   CREATED


   */
  created() {
    let url = window.location.href;
    let recId;
    if (url.includes("?")) {
      recId = window.location.href.split("item/")[1].split("?")[0];
    } else {
      recId = window.location.href.split("item/")[1];
    }
    this.locRecordId = recId || this.currentRecordId;
    this.$store.commit("setCurrentRecordId", this.locRecordId);
    this.$store.dispatch("getQualities");
    this.fetchData();
    this.hasNav = this.$route.query.hasNav || "false";
    this.qualities.forEach((item) => {
      this.qualityOptions.push(item.fields.Quality);
    });
    if (this.curUserPerms.permittedArtInventoryFields.includes("All")) {
      this.fields = [];
    } else {
      this.fields = _.clone(this.curUserPerms.permittedArtInventoryFields);
    }
    document.addEventListener("keyup", this.handleKeyup);
  },
  mounted() {
    this.asNavFor1.push(this.$refs.mainCarousel);
    this.asNavFor2.push(this.$refs.thumbCarousel);
    this.userName = this.$store.state.authData.user_display_name;
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("click", this.handleClick);
  },
  destroyed() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    fetchData() {
      this.$store.dispatch("getCurrentItemDetailRecord", this.currentRecordId);
      this.updateLocalData();
    },
    updateLocalData() {
      if (
        this.currentRecord &&
        this.currentRecord.fields &&
        this.currentRecord.fields.Image &&
        this.currentRecord.fields.Image.length
      ) {
        this.buildGallery();
      }
      if (this.itemAskingPrice) {
        this.itemAskingPrice = this.currentRecord.fields.AskingPrice || "";
      }
      if (this.isItemForSale) {
        this.isItemForSale = this.currentRecord.fields.ForSale || "";
      }
      if (this.currentQuality) {
        this.currentQuality = this.currentRecord.fields.QualityName || "";
      }
      if (this.previousNote) {
        this.previousNote = this.currentRecord.fields.Notes || null;
      }

      if (this.itemAskingPrice) {
        this.itemAskingPrice = this.currentRecord.fields.AskingPrice || "";
      }
      if (this.isItemForSale) {
        this.isItemForSale = this.currentRecord.fields.ForSale || "";
      }
      if (this.currentQuality) {
        this.currentQuality = this.currentRecord.fields.QualityName || "";
      }
    },
    buildGallery() {
      let slideUrl;
      this.currentRecord.fields.Image.forEach((image) => {
        if (
          image.thumbnails &&
          image.thumbnails.full &&
          image.thumbnails.full.url
        ) {
          slideUrl = image.thumbnails.full.url;
          this.galleryImages.unshift(slideUrl);
        } else if (
          image.thumbnails &&
          image.thumbnails.large &&
          image.thumbnails.large.url
        ) {
          slideUrl = image.thumbnails.large.url;
          this.galleryImages.unshift(slideUrl);
        }
      });
      let thumbUrl;
      this.currentRecord.fields.Image.forEach((image) => {
        if (
          image.thumbnails &&
          image.thumbnails.large &&
          image.thumbnails.large.url
        ) {
          thumbUrl = image.thumbnails.large.url;
        } else if (
          image.thumbnails &&
          image.thumbnails.full &&
          image.thumbnails.full.url
        ) {
          thumbUrl = image.thumbnails.full.url;
        }
        this.galleryThumbs.unshift(thumbUrl);
      });
    },
    showField(arr) {
      return arr.some((v) =>
        this.curUserPerms.permittedArtInventoryFields.includes(v),
      );
    },
    handleSetForSale(payload) {
      this.isItemForSale = payload;
    },
    handleSetAskingPrice(payload) {
      this.itemAskingPrice = payload;
    },
    setCurrentCategory(category) {
      this.$store.dispatch("resetFilters");
      this.$store.commit("setFilterCategoryFromMenu", category);
      this.$store.commit("setTotalResults", 0);
      this.$store.commit("setItems", []);
      this.$store.commit("setFetchingData", true);
      router.push({
        path: "/art-inventory",
      });
    },
    navigateToInvoice(invoiceId) {
      router.push(`/invoice/${invoiceId}`);
    },
    changeSlideByIndex(index) {
      this.$refs.mainCarousel.goTo(index);
      this.$refs.thumbCarousel.goTo(index);
    },
    updateNote(e) {
      this.newNote = e.target.value;
    },
    submitUpdatedNotes() {
      const dateTime = new Date();
      const date = dateTime.toLocaleDateString();
      const time = dateTime.toLocaleTimeString();
      if (!this.previousNote) {
        this.combinedNote = `\u25AA ${this.userName} on ${date} at ${time}: ${this.newNote}\n`;
      } else {
        this.combinedNote = `${this.previousNote}\n\u25AA ${this.userName} on ${date} at ${time}: ${this.newNote}\n`;
      }
      base("Art Inventory").update(
        [
          {
            id: this.currentRecord.id,
            fields: {
              Notes: this.combinedNote,
            },
          },
        ],
        (err) => {
          if (err) {
            this.$toasted.error(err, {
              className: "bg-danger",
              duration: 5000,
            });
            return;
          }
          this.$toasted.success("Note saved", {
            className: "bg-success",
            icon: "check",
            duration: 3000,
          });
          this.previousNote = this.combinedNote;
          this.newNote = "";
        },
      );
    },
    handleSetQuality(newQuality) {
      this.currentQuality = newQuality;
      let newQualityId = undefined;
      if (this.qualities) {
        this.qualities.forEach((item) => {
          if (newQuality === item.fields.Quality) {
            newQualityId = item.id;
          }
        });
        if (this.quality !== newQuality) {
          base("Art Inventory").update(
            [
              {
                id: this.currentRecordId,
                fields: {
                  Quality: [newQualityId],
                },
              },
            ],
            (err) => {
              if (err) {
                this.$toasted.error(err, {
                  className: "bg-danger",
                  duration: 5000,
                });
                return;
              }
              this.$toasted.success("Quality saved", {
                className: "bg-success",
                icon: "check",
                duration: 2000,
              });
              this.quality = newQuality;
            },
          );
        }
      }
    },

    routerPush(direction) {
      this.galleryImages = [];
      this.galleryThumbs = [];
      this.loadedImageCount = 0;
      this.loadedImageArr = [];
      if (direction === "prev") {
        this.newRecordId = this.isPrevious;
      } else {
        this.newRecordId = this.isNext;
      }
      this.galleryImages = [];
      this.$store.commit("setCurrentRecordId", this.newRecordId);
      this.currentRecord = {};
      window.history.pushState({}, {}, `/item/${this.newRecordId}`);
      setTimeout(() => {
        this.fetchNext();
      }, 300);
    },
    fetchNext: _.debounce(function () {
      this.fetchData();
      if (this.$refs.mainCarousel) {
        this.$refs.mainCarousel.reload();
      }
    }, 300),
    backToInventory() {
      this.$store.commit("setCurrentRecordId", this.newRecordId);
      this.currentRecord = {};
      router.push({
        path: this.currentQueryURL,
        query: { reload: false },
      });
    },
    handleKeyDown(event) {
      if (!this.filteredItemIds.length) {
        return;
      }
      if (event.keyCode === 27) {
        this.isOpen = false;
      } else if (event.keyCode === 37) {
        if (this.isFirst) {
          return;
        }
        this.routerPush("prev");
      } else if (event.keyCode === 39) {
        if (this.isLast) {
          return;
        }
        this.routerPush("next");
      }
    },
    handleClick(event) {
      if (this.isOpen === true) {
        const isInside = this.$refs.qualitySelect.contains(event.target);
        if (!isInside) {
          if (this.isOpen) {
            this.isOpen = false;
          }
        }
      }
    },
    handleImageLoaded(url) {
      if (!this.loadedImageArr.includes(url)) {
        this.loadedImageArr.push(url);
        if (this.loadedImageArr.length === this.galleryImages.length) {
          this.galleryReady = true;
        }
      }
    },
  },
};
</script>
