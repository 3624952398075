<template>
  <ul class="c-filter__list">
    <li
      class="c-filter__list__items"
      @click.stop="setFilterForSale(filters.forSale)"
    >
      <div class="li-inner">
        <a
          :class="{ 'is-selected': filters.forSale }"
          class="c-link c-link--filter"
        >
          <span class="label">For Sale</span>
        </a>
      </div>
    </li>
  </ul>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ForSale",
  computed: mapState(["filters"]),
  data() {
    return {
      isForSale: false,
    };
  },
  created() {
    this.isForSale = this.filters.forSale;
  },
  methods: {
    setFilterForSale() {
      this.isForSale = !this.isForSale;
      if (this.filters) {
        this.$store.commit("setFilterForSale", this.isForSale);
      }
    },
  },
};
</script>
