<template>
  <ul
    v-if="filteredCollections && filteredCollections.length"
    class="c-filter__list"
  >
    <li
      v-for="(item, index) in filteredCollections"
      :key="index"
      class="c-filter__list__items"
      @click.stop="setCurrentCollection(item.fields.CollectionName)"
    >
      <div class="li-inner">
        <a
          :class="{
            'is-selected': isSelected(item),
          }"
          class="c-link c-link--filter"
        >
          <span class="label">{{ item.fields.CollectionName }}</span>
          <span v-if="curUserPerms.canViewCollectionCount"
            >({{ item.fields.CollectionCount }})</span
          >
        </a>
      </div>
    </li>
  </ul>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Collections",
  components: {},
  data() {
    return {
      filteredCollections: [],
    };
  },
  computed: {
    ...mapState(["filters", "collections", "curUserPerms"]),
  },
  async created() {
    await this.$store.dispatch("getCollections");
    if (this.curUserPerms.permittedCollections[0] === "All") {
      this.filteredCollections = [...this.collections];
    } else {
      this.categories.forEach((col) => {
        this.curUserPerms.permittedCollections.forEach((permCol) => {
          if (col.fields.CollectionName === permCol) {
            this.filteredCollections.push(col);
          }
        });
      });
    }
  },
  mounted() {},
  methods: {
    isSelected(item) {
      return (
        this.filters &&
        this.filters.collections &&
        this.filters.collections.includes(item.fields.CollectionName)
      );
    },
    setCurrentCollection(col) {
      this.filters.collections.includes(col)
        ? this.$store.commit("delFilterCollection", col)
        : this.$store.commit("setFilterCollection", col);
    },
  },
};
</script>
