<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" :style="{ zIndex: zIndex }">
      <div
        class="c-modal c-modal--invoice"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header id="modalTitle" class="c-modal__header">
          <slot name="header">
            <button
              type="button"
              class="c-btn--close"
              aria-label="Close modal"
              @click="close"
            >
              <svg
                width="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 1L1 17M1 1l16 16"
                  stroke-miterlimit="10"
                  stroke="#73706E"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </slot>
        </header>
        <section
          v-if="Object.keys(detailsData).length"
          id="modalDescription"
          class="modal-body c-invoice"
        >
          <slot name="body">
            <div class="c-modal__wrap">
              <div class="c-modal__col">
                <div class="c-invoice__items">
                  <div class="c-invoice__head">Invoice no.</div>
                  <div class="c-invoice__no">
                    {{ invoiceData.fields["Invoice No."] }}
                  </div>
                </div>
                <div class="c-invoice__items">
                  <div class="c-invoice__title">Attachments</div>
                  <ul class="o-list o-list__wrap c-invoice__attachments">
                    <li
                      v-for="(element, index) in invoiceData.fields['Invoice']"
                      :key="'C' + index"
                      class="c-invoice__attachment__items"
                    >
                      <a
                        :href="element.url"
                        :target="'_blank'"
                        class="c-link c-link--attachment"
                      >
                        <img
                          class="c-invoice__attach__image"
                          alt="Thumnail Image"
                          :src="element.thumbnails.large.url"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="c-modal__col">
                <div
                  v-if="invoiceData.fields['Invoice Amount']"
                  class="item-detail-wrap"
                >
                  <div class="item-detail-wrap__title">Invoice Amount:</div>
                  <div class="c-modal__item__text">
                    {{ invoiceData.fields["Invoice Amount"] | currency }}
                  </div>
                </div>
                <div
                  v-if="invoiceData.fields['Sale No.']"
                  class="item-detail-wrap"
                >
                  <div class="item-detail-wrap__title">Sale No.:</div>
                  <div class="c-modal__item__text">
                    {{ invoiceData.fields["Sale No."] }}
                  </div>
                </div>
                <div
                  v-if="invoiceData.fields['Sale Date']"
                  class="item-detail-wrap"
                >
                  <div class="item-detail-wrap__title">sale date:</div>
                  <div class="c-modal__item__text">
                    {{ invoiceData.fields["Sale Date"] | date("date") }}
                  </div>
                </div>
                <div
                  v-for="(element, index) in invoiceData.fields.Sale"
                  :key="'B' + index"
                  class="item-detail-wrap item-detail-wrap--vertical"
                >
                  <div class="item-detail-wrap__title">sale:</div>
                  <nl2br
                    tag="div"
                    :text="element | titlecase"
                    class-name="c-modal__item__text"
                  />
                </div>
                <div
                  v-for="(element, index) in invoiceData.fields.Merchant"
                  :key="'A' + index"
                  class="item-detail-wrap item-detail-wrap--vertical"
                >
                  <div class="item-detail-wrap__title">Merchant:</div>
                  <nl2br
                    tag="div"
                    :text="element | titlecase"
                    class-name="c-modal__item__text"
                  />
                </div>
                <!-- <pre>{{ invoiceData }}</pre> -->
              </div>
            </div>
            <div class="c-modal__wrap">
              <div class="c-invoice__items">
                <div class="c-invoice__title">
                  Other items from this invoice
                </div>
                <ul
                  v-if="!loadingAirtableData"
                  class="o-list c-invoice__collections"
                >
                  <li
                    v-for="(element, index) in invoiceData.fields[
                      'Collection Title'
                    ]"
                    :key="index"
                    class="c-invoice__collections__item"
                  >
                    <truncate
                      action-class="is-hidden"
                      class=""
                      clamp="See More"
                      :length="200"
                      less="See Less"
                      type="html"
                      :text="element | titlecase | sanitize"
                    />
                    <a
                      class="c-btn c-btn--modal"
                      @click.stop.prevent="
                        prepareDetailsModal(
                          element,
                          invoiceData.fields['Collection'][index],
                        )
                      "
                    >
                      View
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </slot>
        </section>
      </div>
    </div>
  </transition>
</template>
<script>
import Truncate from "vue-truncate-collapsed";
import airtableClient from "../../services/airtableClient.service";

export default {
  name: "InvoiceModal",
  components: {
    Truncate,
  },
  props: {
    invoiceData: {
      type: Object,
      required: true,
    },
    detailsData: {
      type: Object,
      required: true,
    },
    zIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      itemCategory: "",
      loadingDetailsData: false,
    };
  },
  mounted() {
    this.itemCategory = this.$store.getters.getCategories
      .filter(
        (category) =>
          category.category_id === this.detailsData.fields.Category[0],
      )
      .map((item) => item.category_name)
      .join("");
  },
  methods: {
    close() {
      this.$emit("close");
    },
    prepareDetailsModal(detailItem, invoiceId) {
      this.$emit("close-details-modal");
      this.loadingDetailsData = true;
      airtableClient
        .get(`Collection/${invoiceId}`)
        .then((res) => {
          this.$emit("prepare-details-modal", res.data);
          this.loadingDetailsData = false;
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loadingDetailsData = false;
        });
    },
  },
};
</script>
