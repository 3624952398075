<template>
  <ul
    v-if="pager.pages && pager.pages.length"
    class="pagination"
    :style="ulStyles"
  >
    <li
      class="page-item first"
      :class="{ disabled: pager.currentPage === 1 }"
      :style="liStyles"
      @click="scrollTop"
    >
      <a class="page-link" :style="aStyles" @click="setPage(1)">
        {{ labels.first }}
      </a>
    </li>
    <li
      class="page-item previous"
      :class="{ disabled: pager.currentPage === 1 }"
      :style="liStyles"
      @click="scrollTop"
    >
      <a
        class="page-link"
        :style="aStyles"
        @click="setPage(pager.currentPage - 1)"
      >
        {{ labels.previous }}
      </a>
    </li>
    <li
      v-for="page in pager.pages"
      :key="page"
      class="page-item page-number"
      :class="{ active: pager.currentPage === page }"
      :style="liStyles"
      @click="scrollTop"
    >
      <a class="page-link" :style="aStyles" @click="setPage(page)">
        {{ page }}
      </a>
    </li>
    <li
      class="page-item next"
      :class="{ disabled: pager.currentPage === pager.totalPages }"
      :style="liStyles"
      @click="scrollTop"
    >
      <a
        class="page-link"
        :style="aStyles"
        @click="setPage(pager.currentPage + 1)"
      >
        {{ labels.next }}</a
      >
    </li>
    <li
      class="page-item last"
      :class="{ disabled: pager.currentPage === pager.totalPages }"
      :style="liStyles"
      @click="scrollTop"
    >
      <a class="page-link" :style="aStyles" @click="setPage(pager.totalPages)">
        {{ labels.last }}
      </a>
    </li>
  </ul>
</template>
<script>
import paginate from "jw-paginate";

const defaultLabels = {
  first: "First",
  last: "Last",
  previous: "Prev",
  next: "Next",
};

const defaultStyles = {
  ul: {
    margin: 0,
    padding: 0,
    display: "inline-block",
  },
  li: {
    listStyle: "none",
    display: "inline",
    textAlign: "center",
  },
  a: {
    cursor: "pointer",
    padding: "6px 12px",
    display: "block",
    float: "left",
  },
};

export default {
  name: "Paginate",
  props: {
    currentItems: {
      type: Array,
      required: true,
      default: () => [],
    },
    initialPage: {
      type: Number,
      default: 1,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 100,
    },
    maxPages: {
      type: Number,
      default: 10,
    },
    labels: {
      type: Object,
      default: () => defaultLabels,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    disableDefaultStyles: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pager: {},
      ulStyles: {},
      liStyles: {},
      aStyles: {},
    };
  },
  created() {
    // set default styles unless disabled
    if (!this.disableDefaultStyles) {
      this.ulStyles = defaultStyles.ul;
      this.liStyles = defaultStyles.li;
      this.aStyles = defaultStyles.a;
    }

    // merge custom styles with default styles
    if (this.styles) {
      this.ulStyles = { ...this.ulStyles, ...this.styles.ul };
      this.liStyles = { ...this.liStyles, ...this.styles.li };
      this.aStyles = { ...this.aStyles, ...this.styles.a };
    }

    // set page if currentItems array isn't empty
    if (this.currentItems && this.currentItems.length) {
      this.setPage(this.initialPage);
    }
  },
  mounted() {},
  methods: {
    setPage(page) {
      this.$store.commit("setInitialPage", page);
      const { currentItems, pageSize, maxPages } = this;

      // get new pager object for specified page
      const pager = paginate(currentItems.length, page, pageSize, maxPages);
      const currentPage = pager.currentPage;

      // get new page of currentItems from currentItems array
      const pageOfItems = currentItems.slice(
        pager.startIndex,
        pager.endIndex + 1,
      );

      // update pager
      this.pager = pager;

      // emit change page event to parent component
      this.$emit("change-page", pageOfItems, currentPage);
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
