<template>
  <div class="layout-item">
    <div class="c-works__image" @click="navigateToDetails">
      <div v-if="item.fields.Image">
        <span v-if="typeof item.fields.Image === 'object'">
          <img
            v-if="item.fields.Image[item.fields.Image.length - 1].thumbnails"
            :src="
              item.fields.Image[item.fields.Image.length - 1].thumbnails.large
                .url
            "
            alt="Thumnail Image"
          />
        </span>
        <span v-if="typeof item.fields.Image === 'string'">
          <img
            v-if="item.fields.Image.thumbnails"
            :src="item.fields.Image.thumbnails.large.url"
            alt="Thumnail Image"
            class="c-works__photo"
          />
        </span>
      </div>
      <div v-else class="c-works__image">
        <img alt="No Image" src="/assets/images/no-image.jpg" />
      </div>
    </div>
    <div class="layout-item-details">
      <artist-title :item="item" />
      <meta-bar :item="item" />
      <item-buttons :item="item" />
    </div>
  </div>
</template>
<script>
import ArtistTitle from "@/components/ui/layouts/ArtistTitle";
import ItemButtons from "@/components/ui/layouts/ItemButtons";
import MetaBar from "@/components/ui/layouts/MetaBar";
import router from "../../../router";
import { mapState } from "vuex";

export default {
  name: "CardMasonryItem",
  components: {
    ArtistTitle,
    ItemButtons,
    MetaBar,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isItemForSale: false,
      itemAskingPrice: null,
    };
  },
  computed: {
    ...mapState(["authData", "userRoles", "curUserPerms"]),
  },
  created() {
    this.itemAskingPrice = this.item.fields.AskingPrice;
    this.isItemForSale = this.item.fields.ForSale || false;
  },
  methods: {
    navigateToDetails() {
      this.$store.commit("setCurrentRecordId", this.item.id);
      router.push({
        path: `/item/${this.item.id}`,
        query: {
          hasNav: true,
        },
      });
    },
  },
};
</script>
