export default {
  toggleHtmlClass(className) {
    const el = document.getElementsByTagName("html")[0];
    el.classList.contains(className)
      ? el.classList.remove(className)
      : el.classList.add(className);
  },
  addHtmlClass(className) {
    const el = document.getElementsByTagName("html")[0];
    if (!el.classList.contains(className)) el.classList.add(className);
  },
  removeHtmlClass(className) {
    const el = document.getElementsByTagName("html")[0];
    if (el.classList.contains(className)) el.classList.remove(className);
  },
  getFirstElement(selector) {
    return document.querySelector(selector);
  },
};
