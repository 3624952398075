<template>
  <main id="notFoundPage">
    <div id="main" aria-label="content">
      <section class="o-section c-section--notfounds js-content">
        <div class="o-section__wrapper">
          <div class="message-box">
            <h1 class="message-box__subhead">404</h1>
            <h2 class="message-box__title">Something is wrong!</h2>
            <p class="message-box__text">
              Back to home or browse in locations and categories.
            </p>
            <router-link class="c-btn c-btn--notfound" to="/"
              >Back to home</router-link
            >
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
};
</script>
