<template>
  <div class="item-note">
    <label class="item-detail-wrap__title">Notes:</label>
    <input
      v-model="itemNote"
      type="text"
      @keyup="$emit('update-item-note', itemNote, itemId)"
    />
  </div>
</template>
<script>
export default {
  name: "ItemTransferNotes",
  props: {
    itemId: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      itemNote: "",
    };
  },
};
</script>
