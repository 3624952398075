<template>
  <div class="grid-item">
    <div class="c-works__image" @click="navigateToDetails">
      <div v-if="item.fields.Image">
        <span v-if="typeof item.fields.Image === 'object'">
          <img
            v-if="item.fields.Image[item.fields.Image.length - 1].thumbnails"
            :src="
              item.fields.Image[item.fields.Image.length - 1].thumbnails.large
                .url
            "
            alt="Thumnail Image"
          />
        </span>
        <span v-if="typeof item.fields.Image === 'string'">
          <img
            v-if="item.fields.Image.thumbnails"
            :src="item.fields.Image.thumbnails.large.url"
            class="c-works__photo"
            alt="Thumnail Image"
          />
        </span>
      </div>
      <div v-else class="c-works__image">
        <img src="/assets/images/no-image.jpg" alt="No Image" />
      </div>
    </div>
    <div class="layout-item-details">
      <div class="c-works__details__wrap">
        <div v-if="item.fields.TitleClean" class="c-works__detail__info">
          <div class="c-link" @click="navigateToDetails">
            <h3 class="c-works__title">
              {{ item.fields.TitleClean | sanitize }}
            </h3>
          </div>
        </div>
        <div class="item-detail-row">
          <!--      location link-->
          <a
            v-if="item.fields['Current Location']"
            class="c-works__location"
            @click.stop="setCurrentLocation(item.fields['Current Location'])"
            @load="log(item)"
          >
            <i class="material-icons material-icons-outlined"> room </i>
            <span class="location">{{
              item.fields["Current Location"] | titlecase
            }}</span>
          </a>
          <div
            v-if="item.fields['Inventory No.']"
            class="item-inventory-number"
          >
            <i class="material-icons material-icons-outlined"> list_alt </i>
            <span>#{{ item.fields["Inventory No."] }}</span>
          </div>
        </div>
      </div>

      <div class="item-detail-row cta">
        <router-link :to="`/item/${item.id}`" class="c-btn btn-view-details"
          >view details</router-link
        >
        <BtnLike :record="item" />
      </div>
      <div v-if="showAskingPrice" class="item-detail-row cta asking-price">
        <span class="label">Asking Price</span>
        <span class="label">{{ itemAskingPrice | currency }}</span>
      </div>
      <div class="item-detail-row cta">
        <BtnTransferCart
          v-if="curUserPerms.canTransfer"
          :item="item"
          text="Transfer"
        />
        <BtnShoppingCart v-if="showBuyButton" :item="item" text="Buy" />
      </div>
      <div v-if="curUserPerms.canSetAskingPrice" class="item-detail-row cta">
        <asking-price
          :item="item"
          @on-set-for-sale="handleSetForSale"
          @on-set-asking-price="handleSetAskingPrice"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AskingPrice from "@/components/ui/AskingPrice";
import BtnShoppingCart from "@/components/ui/buttons/BtnShoppingCart";
import BtnTransferCart from "@/components/ui/buttons/BtnTransferCart";
import BtnLike from "@/components/ui/buttons/BtnFavorite";
import router from "../../../router";
import { mapState } from "vuex";

export default {
  name: "CardGridInvoiceItem",
  components: {
    AskingPrice,
    BtnLike,
    BtnTransferCart,
    BtnShoppingCart,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isItemForSale: false,
      itemAskingPrice: null,
    };
  },
  computed: {
    ...mapState(["authData", "userRoles", "curUserPerms"]),
    hasAskingPrice() {
      return (
        this.itemAskingPrice !== 0 &&
        this.itemAskingPrice !== undefined &&
        this.itemAskingPrice !== null &&
        this.itemAskingPrice !== ""
      );
    },
    showAskingPrice() {
      return (
        this.curUserPerms.canBuy && this.isItemForSale && this.hasAskingPrice
      );
    },
    showBuyButton() {
      return (
        this.curUserPerms.canBuy && this.isItemForSale && this.hasAskingPrice
      );
    },
  },
  created() {
    this.itemAskingPrice = this.item.fields.AskingPrice;
    this.isItemForSale = this.item.fields.ForSale || false;
  },

  methods: {
    handleSetForSale(payload) {
      this.isItemForSale = payload;
    },
    handleSetAskingPrice(payload) {
      this.itemAskingPrice = payload;
      if (this.showAskingPrice || payload === "") {
        this.$emit("reload-isotope");
      }
    },
    setCurrentLocation() {
      this.$root.$emit("reset-results-count");
      this.$store.dispatch("resetFilters");
      this.$store.commit("setCurrentRecordId", this.item.id);
      this.$store.commit("setFetchingData", true);
      this.$store.commit(
        "setFilterLocationFromMenu",
        this.item.fields.LocationRecordId[0],
      );
      this.$store.commit("setItems", []);
      this.$store.commit("setTotalResults", 0);
      router.push({
        path: "/art-inventory",
      });
    },
    navigateToDetails() {
      this.$store.commit("setCurrentRecordId", this.item.id);
      router.push(`/item/${this.item.id}`);
    },
  },
};
</script>
