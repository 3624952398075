<template>
  <ul class="c-filter__list">
    <li
      v-for="(category, index) in filteredCategories"
      :key="index"
      class="c-filter__list__items"
      @click.stop="setCurrentCategory(category)"
    >
      <div class="li-inner">
        <a
          :class="{
            'is-selected': filters.categories.includes(
              category.fields.Category,
            ),
          }"
          class="c-link c-link--filter"
        >
          <span class="label">{{ category.fields.Category }}</span>
          <span v-if="curUserPerms.canViewCollectionCount">
            ({{ category.fields.CollectionCount }})
          </span>
        </a>
      </div>
    </li>
  </ul>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Categories",
  data() {
    return {
      filteredCategories: [],
    };
  },
  computed: {
    ...mapState(["filters", "categories", "curUserPerms"]),
  },
  async created() {
    await this.$store.dispatch("getCategories");
    if (this.curUserPerms.permittedCategories[0] === "All") {
      this.filteredCategories = [...this.categories];
    } else {
      this.categories.forEach((cat) => {
        this.curUserPerms.permittedCategories.forEach((permCat) => {
          if (cat.fields.Category === permCat) {
            this.filteredCategories.push(cat);
          }
        });
      });
    }
  },
  methods: {
    setCurrentCategory(category) {
      if (this.filters) {
        this.filters.categories.includes(category.fields.Category)
          ? this.$store.commit("delFilterCategory", category.fields.Category)
          : this.$store.commit("setFilterCategory", category.fields.Category);
      }
    },
  },
};
</script>
