<template>
  <ul class="c-filter__list sort-by-list">
    <li
      v-if="showSortOption(['All', 'TitleClean'])"
      class="c-filter__list__items"
    >
      <div class="li-inner">
        <a
          :class="{
            'is-selected': sorts.find((el) => el.field === 'TitleClean'),
          }"
          class="c-link c-link--filter"
          @click="handleCheckbox('TitleClean')"
        >
          <span class="label">Title</span>
        </a>
        <span
          class="direction-arrow"
          :class="getDirection('TitleClean')"
          @click="setDirection('TitleClean')"
        />
      </div>
    </li>
    <li
      v-if="showSortOption(['All', 'AskingPrice'])"
      class="c-filter__list__items"
    >
      <div class="li-inner">
        <a
          :class="{
            'is-selected': sorts.find((el) => el.field === 'AskingPrice'),
          }"
          class="c-link c-link--filter"
          @click="handleCheckbox('AskingPrice')"
        >
          <span class="label">Asking Price</span>
        </a>
        <span
          class="direction-arrow"
          :class="getDirection('AskingPrice')"
          @click="setDirection('AskingPrice')"
        />
      </div>
    </li>
    <li v-if="showSortOption(['All', 'Artist'])" class="c-filter__list__items">
      <div class="li-inner">
        <a
          :class="{
            'is-selected': sorts.find((el) => el.field === 'Artist'),
          }"
          class="c-link c-link--filter"
          @click="handleCheckbox('Artist')"
        >
          <span class="label">Artist</span>
        </a>
        <span
          class="direction-arrow"
          :class="getDirection('Artist')"
          @click="setDirection('Artist')"
        />
      </div>
    </li>

    <li
      v-if="showSortOption(['All', 'Category'])"
      class="c-filter__list__items"
    >
      <div class="li-inner">
        <a
          :class="{
            'is-selected': sorts.find((el) => el.field === 'Category'),
          }"
          class="c-link c-link--filter"
          @click="handleCheckbox('Category')"
        >
          <span class="label">Category</span>
        </a>
        <span
          class="direction-arrow"
          :class="getDirection('Category')"
          @click="setDirection('Category')"
        />
      </div>
    </li>
    <li
      v-if="showSortOption(['All', 'Current Location'])"
      class="c-filter__list__items"
    >
      <div class="li-inner">
        <a
          :class="{
            'is-selected': sorts.find((el) => el.field === 'Current Location'),
          }"
          class="c-link c-link--filter"
          @click="handleCheckbox('Current Location')"
        >
          <span class="label">Location</span>
        </a>
        <span
          class="direction-arrow"
          :class="getDirection('Current Location')"
          @click="setDirection('Current Location')"
        />
      </div>
    </li>
    <li
      v-if="showSortOption(['All', 'SubLocationName'])"
      class="c-filter__list__items"
    >
      <div class="li-inner">
        <a
          :class="{
            'is-selected': sorts.find((el) => el.field === 'SubLocationName'),
          }"
          class="c-link c-link--filter"
          @click="handleCheckbox('SubLocationName')"
        >
          <span class="label">Sublocation</span>
        </a>
        <span
          class="direction-arrow"
          :class="getDirection('SubLocationName')"
          @click="setDirection('SubLocationName')"
        />
      </div>
    </li>
    <li
      v-if="showSortOption(['All', 'Length IN'])"
      class="c-filter__list__items"
    >
      <div class="li-inner">
        <a
          :class="{
            'is-selected': sorts.find((el) => el.field === 'Length IN'),
          }"
          class="c-link c-link--filter"
          @click="handleCheckbox('Length IN')"
        >
          <span class="label">Length (inches)</span>
        </a>
        <span
          class="direction-arrow"
          :class="getDirection('Length IN')"
          @click="setDirection('Length IN')"
        />
      </div>
    </li>
    <li
      v-if="showSortOption(['All', 'Width IN'])"
      class="c-filter__list__items"
    >
      <div class="li-inner">
        <a
          :class="{
            'is-selected': sorts.find((el) => el.field === 'Width IN'),
          }"
          class="c-link c-link--filter"
          @click="handleCheckbox('Width IN')"
        >
          <span class="label">Width (inches)</span>
        </a>
        <span
          class="direction-arrow"
          :class="getDirection('Width IN')"
          @click="setDirection('Width IN')"
        />
      </div>
    </li>
    <li v-if="showSortOption(['All', 'Lot No.'])" class="c-filter__list__items">
      <div class="li-inner">
        <a
          :class="{
            'is-selected': sorts.find((el) => el.field === 'Lot No.'),
          }"
          class="c-link c-link--filter"
          @click="handleCheckbox('Lot No.')"
        >
          <span class="label">Lot No.</span>
        </a>
        <span
          class="direction-arrow"
          :class="getDirection('Lot No.')"
          @click="setDirection('Lot No.')"
        />
      </div>
    </li>
    <li
      v-if="showSortOption(['All', 'Inventory No.'])"
      class="c-filter__list__items"
    >
      <div class="li-inner">
        <a
          :class="{
            'is-selected': sorts.find((el) => el.field === 'Inventory No.'),
          }"
          class="c-link c-link--filter"
          @click="handleCheckbox('Inventory No.')"
        >
          <span class="label">Inventory No.</span>
        </a>
        <span
          class="direction-arrow"
          :class="getDirection('Inventory No.')"
          @click="setDirection('Inventory No.')"
        />
      </div>
    </li>
    <li
      v-if="showSortOption(['All', 'Sanjay No.'])"
      class="c-filter__list__items"
    >
      <div class="li-inner">
        <a
          :class="{
            'is-selected': sorts.find((el) => el.field === 'Sanjay No.'),
          }"
          class="c-link c-link--filter"
          @click="handleCheckbox('Sanjay No.')"
        >
          <span class="label">Sanjay No.</span>
        </a>
        <span
          class="direction-arrow"
          :class="getDirection('Sanjay No.')"
          @click="setDirection('Sanjay No.')"
        />
      </div>
    </li>
    <li
      v-if="showSortOption(['All', 'Carpet Tag No.'])"
      class="c-filter__list__items"
    >
      <div class="li-inner">
        <a
          :class="{
            'is-selected': sorts.find((el) => el.field === 'Carpet Tag No.'),
          }"
          class="c-link c-link--filter"
          @click="handleCheckbox('Carpet Tag No.')"
        >
          <span class="label">Carpet Tag No.</span>
        </a>
        <span
          class="direction-arrow"
          :class="getDirection('Carpet Tag No.')"
          @click="setDirection('Carpet Tag No.')"
        />
      </div>
    </li>
    <li
      v-if="showSortOption(['All', 'Merchant'])"
      class="c-filter__list__items"
    >
      <div class="li-inner">
        <a
          :class="{
            'is-selected': sorts.find((el) => el.field === 'Merchant'),
          }"
          class="c-link c-link--filter"
          @click="handleCheckbox('Merchant')"
        >
          <span class="label">Merchant</span>
        </a>
        <span
          class="direction-arrow"
          :class="getDirection('Merchant')"
          @click="setDirection('Merchant')"
        />
      </div>
    </li>
    <li v-if="showSortOption(['All', 'Quality'])" class="c-filter__list__items">
      <div class="li-inner">
        <a
          :class="{
            'is-selected': sorts.find((el) => el.field === 'Quality'),
          }"
          class="c-link c-link--filter"
          @click="handleCheckbox('Quality')"
        >
          <span class="label">Quality</span>
        </a>
        <span
          class="direction-arrow"
          :class="getDirection('Quality')"
          @click="setDirection('Quality')"
        />
      </div>
    </li>
    <li
      v-if="showSortOption(['All', 'Sale Date'])"
      class="c-filter__list__items"
    >
      <div class="li-inner">
        <a
          :class="{
            'is-selected': sorts.find((el) => el.field === 'Sale Date'),
          }"
          class="c-link c-link--filter"
          @click="handleCheckbox('Sale Date')"
        >
          <span class="label">Sale Date</span>
        </a>
        <span
          class="direction-arrow"
          :class="getDirection('Sale Date')"
          @click="setDirection('Sale Date')"
        />
      </div>
    </li>
    <li v-if="showSortOption(['All', 'Value'])" class="c-filter__list__items">
      <div class="li-inner">
        <a
          :class="{
            'is-selected': sorts.find((el) => el.field === 'Value'),
          }"
          class="c-link c-link--filter"
          @click="handleCheckbox('Value')"
        >
          <span class="label">Value</span>
        </a>
        <span
          class="direction-arrow"
          :class="getDirection('Value')"
          @click="setDirection('Value')"
        />
      </div>
    </li>
    <li class="c-filter__list__items d-none">
      <button class="c-btn btn-sort" @click="handleSortCurrentResults">
        Sort Current Results
      </button>
    </li>
  </ul>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "SortBy",
  data() {
    return {
      sorts: [],
    };
  },
  computed: {
    ...mapState(["sortByArr", "authData", "userRoles", "curUserPerms"]),
  },
  watch: {
    sortByArr: {
      handler(newVal) {
        if (newVal) {
          this.sorts = [...newVal];
        }
      },
      deep: true,
    },
  },
  created() {
    this.sorts = [...this.sortByArr];
  },
  methods: {
    handleSortCurrentResults() {
      this.$root.$emit("sort-current-results");
    },
    showSortOption(arr) {
      return arr.some((v) => this.curUserPerms.permittedSorting.includes(v));
    },
    getDirection(field) {
      const index = this.sorts.findIndex((item) => item.field === field);
      if (index === -1) {
        return false;
      } else {
        return this.sorts[index].direction;
      }
    },
    handleCheckbox(field) {
      const index = this.sorts.findIndex((item) => item.field === field);
      if (index === -1) {
        this.sorts.push({ field: field, direction: "asc" });
      } else {
        this.sorts.splice(index, 1);
      }
      this.$store.commit("setSortByArr", this.sorts);
    },
    setDirection(field) {
      const index = this.sorts.findIndex((item) => item.field === field);
      if (index === -1) {
        return false;
      } else {
        if (this.sorts[index].direction === "asc") {
          this.sorts[index].direction = "desc";
        } else {
          this.sorts[index].direction = "asc";
        }
        this.$store.commit("setSortByArr", this.sorts);
      }
    },
  },
};
</script>
