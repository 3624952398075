<template>
  <main id="loginPage" aria-label="content">
    <video
      id="bgvid"
      playsinline
      poster="/assets/images/bg_login.jpg"
      autoplay
      muted
      loop
      @click="handleClick()"
    >
      <source src="/assets/video/giovanni_480p.webm" type="video/webm" />
      <source src="/assets/video/giovanni_tiny.mp4" type="video/mp4" />
      <source src="/assets/video/giovanni_720p.mp4" type="video/mp4" />
    </video>
    <section class="o-section c-section--login">
      <div class="c-login">
        <div class="loading-tables">
          <div
            v-if="loadingBlockCount.length && networkBusyStatus"
            class="block-wrap"
          >
            <div
              v-for="(item, index) in loadingBlockCount"
              :key="index"
              class="loading-block"
            >
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
        <div class="c-login__logo">
          <a class="c-link c-link--logo" aria-label="Logo">
            <Logo class="icon" />
          </a>
        </div>
        <h1 class="c-login__title">Login to your account</h1>
        <div v-if="isError" class="login-error">
          <svg
            width="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 14.5a7 7 0 100-14 7 7 0 000 14z"
              stroke="#fff"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M7.5 12a1 1 0 100-2 1 1 0 000 2z" fill="#fff" />
            <path
              d="M7.5 3.5V8"
              stroke="#fff"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span v-html="loginError"></span>
        </div>
        <form action="" class="c-form c-form--login">
          <div class="c-form__items">
            <label for="c-form__user">Username</label>
            <input
              id="c-form__user"
              v-model="username"
              type="text"
              name="c-form__user"
              placeholder="Username"
              class="c-form__input"
            />
          </div>
          <div class="c-form__items">
            <label for="c-form__password">Password</label>
            <input
              id="c-form__password"
              v-model="password"
              type="password"
              name="c-form__password"
              placeholder="Password"
              class="c-form__input"
            />
          </div>
          <div class="c-form__items">
            <button
              :disabled="!username || !password"
              :class="{ 'is-active': isLoggingIn }"
              class="c-btn btn-login"
              @click.stop.prevent="handleLogin"
            >
              <span>Login</span>
              <span class="is-loading">
                <Progress fill-color="#FFFFFF" />
              </span>
            </button>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>
<script>
import { mapState } from "vuex";
import Logo from "../ui/Logo";
import Progress from "../ui/Progress";

export default {
  name: "AppLogin",
  components: {
    Logo,
    Progress,
  },
  data() {
    return {
      username: "",
      password: "",
      loadingBlockCount: [],
    };
  },
  computed: {
    ...mapState(["loggingIn", "loginError", "networkBusyStatus"]),
    isError() {
      return this.loginError !== "" && this.loginError !== null && true;
    },
    isLoggingIn() {
      return this.loggingIn;
    },
  },
  methods: {
    handleLogin() {
      this.$store.dispatch("doLogin", {
        username: this.username,
        password: this.password,
      });
      this.startBlocks();
    },
    startBlocks() {
      let r = 1;
      const timer = setInterval(() => {
        this.loadingBlockCount.push("empty");
        if (++r === 12) {
          window.clearInterval(timer);
        }
      }, 2200);
    },
    handleClick() {
      const vid = document.getElementById("bgvid");
      if (vid.paused) {
        vid.play();
      } else {
        vid.pause();
      }
    },
  },
};
</script>
