<template>
  <main id="updateAirtable" aria-label="content">
    <section class="update-content">
      <button
        class="c-btn"
        :disabled="!subLocs.length"
        :class="{ disabled: !subLocs.length }"
        @click="updateRecords"
      >
        UPDATE
      </button>
    </section>
  </main>
</template>
<script>
/* eslint-disable */
import BtnTransferCart from "@/components/ui/buttons/BtnTransferCart";
import BtnFavorite from "@/components/ui/buttons/BtnFavorite";
import Progress from "@/components/ui/Progress";
import QualityDropdown from "@/components/ui/DropdownQuality";
import Spinner from "@/components/ui/Spinner";
import Truncate from "vue-truncate-collapsed";
import { VueAgile } from "vue-agile";
import { mapState } from "vuex";
import airtableQuery from "../services/airtableQuery.service";

export default {
  name: "ItemDetails",
  components: {
    BtnTransferCart,
    BtnFavorite,
    Progress,
    QualityDropdown,
    Spinner,
    Truncate,
    agile: VueAgile,
  },
  data() {
    return {
      payload: [],
      subLocs: [],
      table: "",
      sublocationUrlBase:
        "https://cycad.art/art-inventory?categories=&collections=&dateEnd=&dateStart=&favorites=&itemIds=&keyword=&layout=masonry-small&locations=&maxLength=500&maxPrice=5000000&maxWidth=500&merchants=&minLength=0&minPrice=0&minWidth=0&quality=&units=IN&sublocations=",
    };
  },
  computed: {
    ...mapState(["sublocations"]),
  },
  watch: {
    sublocations: {
      handler(newVal) {
        this.subLocs = [...newVal];
      },
    },
  },
  created() {
    this.table = "SubLocations";
  },
  mounted() {},
  updated() {},
  methods: {
    updateRecords() {
      this.subLocs.forEach((loc) => {
        let location = encodeURIComponent(loc.fields.SubLocationName.trim());
        this.payload.push({
          id: loc.id,
          fields: {
            SubLocationURL: this.sublocationUrlBase + location,
          },
        });
        if (this.payload.length === 10) {
          airtableQuery.updateRecordsAsync(this.table, this.payload);
          this.payload = [];
        }
      });
    },
  },
};
</script>
