<template>
  <div class="c-lists__col sidebar-nav js-nav">
    <div class="c-filter">
      <div class="c-filter__header">
        <div class="c-filter__header__row">
          <h3 class="c-filter__title">Filters</h3>
          <button
            class="c-btn btn-clear-filters"
            aria-label="Clear Filters"
            @click="handleClearFilters"
          >
            Clear Filters
          </button>
          <button
            class="c-link c-link--close js-close"
            aria-label="close"
            @click="toggleHtmlClass('is-fixed')"
          >
            <svg
              width="11"
              height="11"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 .5l-11 11M1 .5l11 11"
                stroke="#000000"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <!--        SELECTED FILTERS SELECTED FILTERS SELECTED FILTERS -->
        <div class="c-filter__selected">
          <div class="c-filter__selected__title">Selected Filters:</div>
          <selected-filters
            :is-loading="fetchingData"
            @set-no-filters="handleNoFilters"
          />
        </div>
      </div>
      <div
        ref="filtersList"
        class="c-filter__wrap"
        :class="{ disabled: fetchingData }"
      >
        <!--      KEYWORD KEYWORD KEYWORD KEYWORD KEYWORD KEYWORD -->
        <div
          class="c-filter__items js-filters"
          :class="{ 'is-closed': isClosedKeyword }"
        >
          <div class="c-filter__items__title" @click.prevent="openKeyword">
            Keyword
          </div>
          <SearchForm
            v-if="!isClosedKeyword"
            ref="searchCompSidebar"
            :keyword="filters.keyword"
            :is-visible="isInputVisible"
            @update-keyword="updateKeyword"
            @submit-keyword="submitFilters"
          />
        </div>
        <!--      CATEGORIES CATEGORIES CATEGORIES CATEGORIES CATEGORIES -->
        <div
          v-if="showFilter(['Categories', 'All'])"
          class="c-filter__items js-filters"
          :class="{ 'is-closed': isClosedCategories }"
        >
          <div
            class="c-filter__items__title"
            @click="isClosedCategories = !isClosedCategories"
          >
            Categories
          </div>
          <categories />
        </div>
        <!--      LOCATIONS LOCATIONS LOCATIONS LOCATIONS LOCATIONS -->
        <div
          v-if="showFilter(['Locations', 'All'])"
          class="c-filter__items js-filters"
          :class="{ 'is-closed': isClosedLocations }"
        >
          <div
            class="c-filter__items__title"
            @click="isClosedLocations = !isClosedLocations"
          >
            Locations
          </div>
          <locations />
        </div>
        <!--     MERCHANTS MERCHANTS MERCHANTS MERCHANTS MERCHANTS  -->
        <div
          v-if="showFilter(['Merchants', 'All'])"
          class="c-filter__items js-filters"
          :class="{ 'is-closed': isClosedMerchants }"
        >
          <div
            class="c-filter__items__title"
            @click="isClosedMerchants = !isClosedMerchants"
          >
            Merchants
          </div>
          <merchants />
        </div>
        <!--     QUALITY QUALITY QUALITY QUALITY QUALITY QUALITY-->
        <div
          v-if="showFilter(['Qualities', 'All'])"
          class="c-filter__items js-filters"
          :class="{ 'is-closed': isClosedQualities }"
        >
          <div
            class="c-filter__items__title"
            @click="isClosedQualities = !isClosedQualities"
          >
            Quality
          </div>
          <qualities />
        </div>
        <!--     FOR SALE FOR SALE FOR SALE FOR SALE FOR SALE FOR SALE-->
        <div
          v-if="showFilter(['ForSale', 'All'])"
          class="c-filter__items js-filters"
          :class="{ 'is-closed': isClosedForSale }"
        >
          <div
            class="c-filter__items__title"
            @click="isClosedForSale = !isClosedForSale"
          >
            For Sale
          </div>
          <for-sale />
        </div>
        <!--      COLLECTIONS  COLLECTIONS COLLECTIONS -->
        <div
          v-if="showFilter(['Collections', 'All'])"
          ref="collections"
          class="c-filter__items js-filters"
          :class="{ 'is-closed': isClosedCollections }"
        >
          <div
            class="c-filter__items__title"
            @click="isClosedCollections = !isClosedCollections"
          >
            Collections
          </div>
          <collections />
        </div>
        <!--     PRICE RANGE PRICE  -->
        <div
          v-if="showFilter(['Price Range', 'All'])"
          class="c-filter__items js-filters range-wrap"
          :class="{ 'is-closed': isClosedPriceRange }"
        >
          <div
            class="c-filter__items__title"
            @click="isClosedPriceRange = !isClosedPriceRange"
          >
            Price Range
          </div>
          <price-range />
        </div>
        <!--     LENGTH RANGE LENGTH RANGE -->
        <div
          v-if="showFilter(['Dimensions', 'All'])"
          class="c-filter__items js-filters range-wrap"
          :class="{ 'is-closed': isClosedLengthRange }"
        >
          <div
            class="c-filter__items__title"
            @click="isClosedLengthRange = !isClosedLengthRange"
          >
            Length Range <span class="units-label">({{ filters.units }})</span>
          </div>
          <length-range :units="filters.units" />
          <radio-units key="lengthUnits" dim="length" />
        </div>
        <!--     WIDTH RANGE WIDTH RANGE WIDTH RANGE WIDTH RANGE -->
        <div
          v-if="showFilter(['Dimensions', 'All'])"
          class="c-filter__items js-filters range-wrap"
          :class="{ 'is-closed': isClosedWidthRange }"
        >
          <div
            class="c-filter__items__title"
            @click="isClosedWidthRange = !isClosedWidthRange"
          >
            Width Range <span class="units-label">({{ filters.units }})</span>
          </div>
          <width-range :units="filters.units" />
          <radio-units key="widthUnits" dim="width" />
        </div>
        <!--     DATE RANGE DATE RANGE DATE RANGE DATE RANGE -->
        <div
          v-if="showFilter(['Date Range', 'All'])"
          class="c-filter__items js-filters date-range"
          :class="{ 'is-closed': isClosedDateRange }"
        >
          <div
            class="c-filter__items__title"
            @click="isClosedDateRange = !isClosedDateRange"
          >
            Date Range
          </div>
          <date-range />
        </div>
        <!--     SORT BY SORT BY SORT BY SORT BY SORT BY SORT BY-->
        <div
          v-if="showFilter(['Sort By', 'All'])"
          class="c-filter__items js-filters"
          :class="{ 'is-closed': isClosedSorts }"
        >
          <div
            class="c-filter__items__title"
            @click="isClosedSorts = !isClosedSorts"
          >
            Sort By
          </div>
          <sort-by />
        </div>
      </div>
      <div class="fixed-bottom-button">
        <button
          v-if="!fetchingData"
          class="c-btn btn-submit-filters"
          :class="{ 'no-filters': noFilters }"
          @click="submitFilters"
        >
          <span v-if="noFilters">SELECT FILTERS OR SEARCH ALL</span>
          <span v-else-if="networkBusyStatus">
            <Progress fill-color="#FFFFFF" width="30" />
          </span>
          <span v-else>FILTER BY SELECTED</span>
        </button>
        <button v-else class="c-btn btn-cancel" @click="handleClearFilters">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ForSale from "./ForSale";
import Progress from "@/components/ui/Progress";
import RadioUnits from "@/components/ui/RadioUnits";
import SearchForm from "@/components/ui/SearchForm";
import Categories from "./Categories";
import Collections from "./Collections";
import DateRange from "./RangeDate";
import LengthRange from "@/components/sidebar/RangeLength";
import Locations from "./Locations";
import Merchants from "./Merchants";
import PriceRange from "@/components/sidebar/RangePrice";
import Qualities from "./Qualities";
import SelectedFilters from "./SelectedFilters";
import WidthRange from "@/components/sidebar/RangeWidth";
import SortBy from "@/components/sidebar/SortBy";
import dom from "../../services/dom.service";
import { mapState } from "vuex";

export default {
  name: "Sidebar",
  components: {
    Categories,
    Collections,
    DateRange,
    ForSale,
    LengthRange,
    Locations,
    Merchants,
    PriceRange,
    Progress,
    Qualities,
    RadioUnits,
    SearchForm,
    SelectedFilters,
    SortBy,
    WidthRange,
  },
  data() {
    return {
      isClosedCategories: true,
      isClosedCollections: true,
      isClosedDateRange: true,
      isClosedForSale: true,
      isClosedKeyword: true,
      isClosedLengthRange: true,
      isClosedLocations: true,
      isClosedMerchants: true,
      isClosedPriceRange: true,
      isClosedQualities: true,
      isClosedSorts: true,
      isClosedWidthRange: true,
      isInputVisible: true,
      noFilters: true,
      keyword: "",
    };
  },
  computed: {
    ...mapState([
      "filters",
      "fetchingData",
      "networkBusyStatus",
      "curUserPerms",
    ]),
  },
  created() {
    console.log(
      `%c SIDEBAR`,
      "font-size: 13px; background-color:  #2B3D2D; color: white; padding: 3px 9px 3px 4px;",
    );
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
  },
  destroyed() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    handleNoFilters(val) {
      this.noFilters = val;
    },
    showFilter(arr) {
      return arr.some((v) => this.curUserPerms.permittedFilters.includes(v));
    },
    handleClearFilters() {
      this.isInputVisible = false;
      this.isClosedCategories = true;
      this.isClosedCollections = true;
      this.isClosedDateRange = true;
      this.isClosedKeyword = true;
      this.isClosedLengthRange = true;
      this.isClosedLocations = true;
      this.isClosedMerchants = true;
      this.isClosedPriceRange = true;
      this.isClosedQualities = true;
      this.isClosedSorts = true;
      this.isClosedWidthRange = true;
      this.$emit("reset-filters");
      this.$store.commit("setHasAskingPrice", false);
    },
    updateKeyword(val) {
      this.keyword = val;
    },
    openKeyword() {
      this.isClosedKeyword = !this.isClosedKeyword;
      if (!this.isClosedKeyword) {
        this.isInputVisible = true;
      }
    },
    submitFilters() {
      this.toggleHtmlClass("is-fixed");
      this.$root.$emit("submit-filters");
    },
    toggleHtmlClass(className) {
      dom.toggleHtmlClass(className);
    },
    handleKeyDown(event) {
      if (event.keyCode === 27) {
        dom.removeHtmlClass("is-fixed");
      }
    },
  },
};
</script>
