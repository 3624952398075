<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" :style="{ zIndex: zIndex }">
      <div
        class="c-modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header id="modalTitle" class="c-modal__header">
          <slot name="header">
            <button
              type="button"
              class="c-btn--close"
              aria-label="Close modal"
              @click="close"
            >
              <svg
                width="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 1L1 17M1 1l16 16"
                  stroke="#73706E"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </slot>
        </header>
        <section id="modalDescription" class="modal-body">
          <slot name="body">
            <div class="c-modal__col">
              <agile
                ref="main"
                class="main"
                :options="options1"
                :as-nav-for="asNavFor1"
                :class="{
                  'has-one-slide': detailsModalData.fields.Image.length <= 1,
                }"
              >
                <div
                  v-for="(slide, index) in detailsModalData.fields.Image"
                  :key="'A' + index"
                  class="slide"
                  :class="`slide--${index}`"
                >
                  <img :src="slide.thumbnails.large.url" />
                </div>
                <template
                  v-if="detailsModalData.fields.Image.length > 1"
                  slot="prevButton"
                >
                  <svg
                    width="11"
                    viewBox="0 0 11 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 1L1 10L10 19" stroke="#1A120F" />
                  </svg>
                </template>
                <template
                  v-if="detailsModalData.fields.Image.length > 1"
                  slot="nextButton"
                >
                  <svg
                    width="11"
                    viewBox="0 0 11 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 1L10 10L1 19" stroke="#1A120F" />
                  </svg>
                </template>
              </agile>
              <agile
                v-if="detailsModalData.fields.Image.length > 1"
                ref="thumbnails"
                class="thumbnails thumbnails__wrap"
                :options="options2"
                :as-nav-for="asNavFor2"
              >
                <div
                  v-for="(slide, index) in detailsModalData.fields.Image"
                  :key="'B' + index"
                  class="slide slide--thumbnail"
                  :class="`slide--${index}`"
                  @click="$refs.thumbnails.goTo(index)"
                >
                  <img :src="slide.thumbnails.large.url" />
                </div>
                <template slot="prevButton">
                  <svg
                    width="8"
                    viewBox="0 0 11 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 1L1 10L10 19" stroke="#1A120F" />
                  </svg>
                </template>
                <template slot="nextButton">
                  <svg
                    width="8"
                    viewBox="0 0 11 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 1L10 10L1 19" stroke="#1A120F" />
                  </svg>
                </template>
              </agile>
            </div>
            <div class="c-modal__col">
              <div class="c-modal__details">
                <div class="c-tag">
                  <div
                    v-if="detailsModalData.fields.Category"
                    class="c-tag__items c-tag--category"
                  >
                    Category: {{ itemCategory }}
                  </div>
                  <div
                    v-if="detailsModalData.fields['Inventory number']"
                    class="c-tag__items c-tag--symbol"
                  >
                    Inventory number:
                    {{ detailsModalData.fields["Inventory number"] }}
                  </div>
                </div>
                <div class="c-modal__head">
                  <truncate
                    v-if="detailsModalData.fields"
                    action-class="c-btn c-btn--readmore"
                    class="modal-title"
                    clamp="See More"
                    :length="200"
                    less="See Less"
                    type="html"
                    :text="detailsModalData.fields.TitleClean | sanitize"
                  />
                  <truncate
                    v-if="detailsModalData.fields.DescriptionClean"
                    action-class="c-btn c-btn--readmore"
                    class="c-modal__subtitle"
                    clamp="See More"
                    :length="500"
                    less="See Less"
                    type="html"
                    :text="
                      detailsModalData.fields.DescriptionClean
                        | sanitizeDescription
                    "
                  />
                  <div
                    v-if="detailsModalData.fields.Artist"
                    class="c-modal__text"
                  >
                    {{ detailsModalData.fields.Artist | sanitize }}
                  </div>
                </div>
              </div>
              <div class="c-modal__buttons">
                <BtnLike :record="detailsModalData" />
                <BtnTransferCart :item="detailsModalData" />
              </div>
              <div
                v-if="detailsModalData.fields['Invoice No.']"
                class="item-detail-wrap"
              >
                <div class="item-detail-wrap__title">invoice no.:</div>
                <div
                  v-for="(element, index) in detailsModalData.fields[
                    'Invoice Title'
                  ]"
                  :key="'C' + index"
                  class="c-modal__item__text"
                >
                  <button
                    :disabled="isBusy"
                    class="c-btn c-btn--invoice"
                    @click="
                      delegateInvoiceId(
                        detailsModalData.fields['Invoice No.'],
                        detailsModalData,
                      )
                    "
                  >
                    <span>{{ element }}</span>
                    <span v-if="isBusy" class="is-loading">
                      <Progress />
                    </span>
                  </button>
                </div>
              </div>
              <div
                v-if="detailsModalData.fields.Status"
                class="item-detail-wrap"
              >
                <div class="item-detail-wrap__title">Status:</div>
                <div class="c-modal__item__text">
                  <div class="c-modal__status">
                    <span
                      class="c-status"
                      :class="`c-status--${detailsModalData.fields.Status}`"
                    ></span
                    >{{ detailsModalData.fields.Status }}
                  </div>
                </div>
              </div>
              <div
                v-if="detailsModalData.fields['Location']"
                class="item-detail-wrap"
              >
                <div class="item-detail-wrap__title">Location:</div>
                <div class="c-modal__item__text">
                  {{ getLocation(detailsModalData.fields["Location"]) }}
                </div>
              </div>
              <div
                v-if="detailsModalData.fields.Dimensions"
                class="item-detail-wrap item-detail-wrap--vertical"
              >
                <div class="item-detail-wrap__title">dimenssion:</div>
                <nl2br
                  tag="div"
                  :text="detailsModalData.fields.Dimensions"
                  class-name="c-modal__item__text"
                />
              </div>
              <div
                v-if="detailsModalData.fields.Quality"
                class="item-detail-wrap"
              >
                <div class="item-detail-wrap__title">quality:</div>
                <div class="c-modal__item__text">
                  {{ detailsModalData.fields.Quality | sanitize }}
                </div>
              </div>
              <div
                v-if="detailsModalData.fields.Value"
                class="item-detail-wrap"
              >
                <div class="item-detail-wrap__title">value:</div>
                <div class="c-modal__item__text">
                  {{ detailsModalData.fields.Value | currency }}
                </div>
              </div>
              <div
                v-if="detailsModalData.fields['Sale Date']"
                class="item-detail-wrap"
              >
                <div class="item-detail-wrap__title">sale date:</div>
                <div class="c-modal__item__text">
                  {{ detailsModalData.fields["Sale Date"] | date("date") }}
                </div>
              </div>
              <div
                v-if="detailsModalData.fields.Sale"
                class="item-detail-wrap item-detail-wrap--vertical"
              >
                <div class="item-detail-wrap__title">sale:</div>
                <!-- Plaese Fix 'sanitize' Mode -->
                <nl2br
                  tag="div"
                  :text="detailsModalData.fields.Sale | titlecase"
                  class-name="c-modal__item__text"
                />
              </div>
            </div>
          </slot>
        </section>
      </div>
    </div>
  </transition>
</template>
<script>
import BtnTransferCart from "../ui/buttons/BtnTransferCart";
import BtnLike from "../ui/buttons/BtnFavorite";
import Progress from "../ui/Progress";

import Truncate from "vue-truncate-collapsed";
import { mapState } from "vuex";
import { VueAgile } from "vue-agile";

export default {
  name: "DetailsModal",
  components: {
    BtnTransferCart,
    agile: VueAgile,
    BtnLike,
    Progress,
    Truncate,
  },
  props: {
    detailsModalData: {
      type: Object,
      required: true,
      default: () => {},
    },
    isBusy: {
      type: Boolean,
      required: true,
      default: false,
    },
    zIndex: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  data() {
    return {
      asNavFor1: [],
      asNavFor2: [],
      options1: {
        dots: false,
        fade: false,
        navButtons: true,
        infinite: false,
      },
      options2: {
        autoplay: false,
        centerMode: true,
        dots: false,
        navButtons: false,
        slidesToShow: -1,
        infinite: false,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              navButtons: true,
            },
          },
        ],
      },
      itemCategory: null,
    };
  },
  computed: mapState(["locations"]),
  mounted() {
    this.asNavFor1.push(this.$refs.thumbnails);
    this.asNavFor2.push(this.$refs.main);
    this.itemCategory = this.$store.getters.getCategories
      .filter(
        (category) =>
          category.category_id === this.detailsModalData.fields.Category[0],
      )
      .map((item) => item.category_name)
      .join("");
  },
  methods: {
    getLocation(location) {
      let loc = this.locations.filter((e) => e.location_id === location[0]);
      return loc.shift()["location_name"];
    },
    close() {
      this.$emit("close");
    },
    delegateInvoiceId(id, detailsModalData) {
      this.$emit("delegate-invoice-id", id, detailsModalData);
    },
  },
};
</script>
