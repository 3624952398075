<template>
  <div id="room" class="o-section__wrapper">
    <main id="roomScanPage" aria-label="content">
      <div v-if="isLoading">
        <Spinner />
      </div>
      <QRScanner v-else @on-scan="onScan" />
      <header
        v-if="curRoomName && isCurScanRoomItems && !isLoading"
        class="section-header room"
      >
        <span class="small">Now Scanning:</span><br /><span class="room-name">
          {{ curRoomName }}
        </span>
      </header>
      <div v-if="showPagination" class="pagination-wrap">
        <paginate
          :key="`paginateTop_${randomKey}`"
          :current-items="curScanRoomItems"
          :initial-page="initialPage"
          :current-page="currentPage"
          @change-page="onChangePage"
          @click.native="generateRandomKey"
        />
      </div>
      <section v-if="isCurScanRoomItems && !isLoading" class="c-room">
        <header>
          <span class="item">Item</span>
          <span class="destination">Status</span>
        </header>
        <!--        item list ******************************************** -->
        <div class="room-items-list">
          <RoomItem
            v-for="(item, index) in pageOfItems"
            :key="index"
            :item="item"
            :scanned-ids="scannedItemIds"
          />
        </div>
        <div v-if="showPagination" class="pagination-wrap">
          <paginate
            :key="`paginateBottom_${randomKey}`"
            :current-items="curScanRoomItems"
            :initial-page="initialPage"
            :current-page="currentPage"
            @change-page="onChangePage"
            @click.native="generateRandomKey"
          />
        </div>
      </section>
      <div v-else class="message-box message-box--room">
        <div v-if="isLoading">
          <h4 class="message-box__title mb-0">Loading rooms...</h4>
        </div>
        <div v-else-if="hasPreviousRooms">
          <h4 class="message-box__title mb-0">Scan a room QR code</h4>
          <p class="message-box__text">or</p>
          <h4 class="message-box__title mb-0">
            Select a room from the list below
          </h4>
        </div>
        <div v-else>
          <h4 class="message-box__title mb-0">
            You have not scanned any rooms yet.
          </h4>
          <h4 class="message-box__title mb-0">Scan a room QR code to begin.</h4>
        </div>
      </div>
      <!-- previous scans previous scans previous scans -->
      <!-- previous scans previous scans previous scans -->
      <!-- previous scans previous scans previous scans -->
      <section v-if="hasPreviousRooms && !isLoading" class="previous-scans">
        <header class="section-header">
          Room Scans for {{ curUserDisplayName }}
        </header>
        <div class="previous-room-header">
          <div class="room">Room</div>
          <div class="created">Created</div>
          <div class="updated">Updated</div>
          <div class="status">Status</div>
        </div>
        <PreviousRoom
          v-for="(room, index) in prevRooms"
          :key="index"
          :room="room"
          @click.native="fetchPreviousRoom(room)"
        />
      </section>
      <RoomScanConfirmModal
        v-if="isConfirmModalShowing"
        :is-complete="isComplete"
        @on-cancel="isConfirmModalShowing = false"
        @on-continue="fetchPreviousRoom(curScanRoom)"
        @on-new="createNewRoomScanRecord"
      />
      <RoomScanCompleteModal
        v-if="isCompleteModalShowing"
        @on-close="handleRoomComplete"
      />
    </main>
  </div>
</template>
<script>
import Paginate from "@/components/ui/Paginate";
import PreviousRoom from "./PreviousRoom";
import QRScanner from "@/components/ui/QRScanner";
import RoomItem from "./RoomItem";
import RoomScanCompleteModal from "@/components/modals/RoomScanCompleteModal";
import RoomScanConfirmModal from "@/components/modals/RoomScanConfirmModal";
import Spinner from "@/components/ui/Spinner";
import airtableQuery from "../../../services/airtableQuery.service";
import { mapState } from "vuex";
import _ from "lodash";

import { useSound } from "@vueuse/sound";
import failSFX from "@/sounds/fail_2.mp3";
import successSFX from "@/sounds/success.mp3";
import newRoomSFX from "@/sounds/newroom.mp3";
import completeSFX from "@/sounds/complete.mp3";

export default {
  name: "RoomScanPage",
  components: {
    Paginate,
    PreviousRoom,
    QRScanner,
    RoomItem,
    RoomScanCompleteModal,
    RoomScanConfirmModal,
    Spinner,
  },
  data() {
    return {
      curRoomItemQRIds: [],
      curItemQRId: "",
      curRoomId: "",
      curRoomItemIds: [],
      curRoomQRCodes: [],
      roomName: "",
      curUserDisplayName: "",
      currentPage: 1,
      hasPreviousRooms: false,
      isCompleteModalShowing: false,
      isConfirmModalShowing: false,
      isLoading: false,
      pageOfItems: [],
      prevRooms: [],
      previousRoomSublocIds: [],
      randomKey: 42,
      roomStatus: ["INCOMPLETE"],
      scannedItemIds: [],
      sublocationId: "",
      unscannedItemIds: [],
      userEmail: "",
    };
  },
  computed: {
    ...mapState([
      "authData",
      "curRoomName",
      "curScanRoom",
      "curScanRoomItems",
      "curScanRoomScannedItemIds",
      "curScanRoomUnscannedItemIds",
      "curUserData",
      "initialPage",
      "locations",
      "previousRoomRecordIds",
      "previousRoomScans",
      "sublocations",
    ]),
    showPagination() {
      return (
        !this.isLoading &&
        this.curScanRoomItems &&
        this.curScanRoomItems.length &&
        this.curScanRoomItems.length > 100
      );
    },
    isCurScanRoomItems() {
      return !_.isEmpty(this.curScanRoomItems);
    },
    isCurScanRoom() {
      return !_.isEmpty(this.curScanRoom);
    },
    isComplete() {
      return _.isEmpty(this.unscannedItemIds);
    },
    isPreviousRoomScans() {
      return !_.isEmpty(this.previousRoomScans);
    },
  },
  watch: {
    previousRoomScans: {
      handler(newVal) {
        this.prevRooms = newVal;
      },
      deep: true,
    },
  },
  setup() {
    const success = useSound(successSFX);
    const fail = useSound(failSFX);
    const newroom = useSound(newRoomSFX);
    const complete = useSound(completeSFX);

    const playSound = (sound) => {
      if (sound === "success") {
        success.play();
      } else if (sound === "newroom") {
        newroom.play();
      } else if (sound === "complete") {
        complete.play();
      } else {
        fail.play();
      }
    };

    return {
      playSound,
    };
  },

  /**

   CREATED


   */
  async created() {
    this.isLoading = true;
    await this.$store.dispatch("getSubLocations");
    await this.$store.dispatch("getPreviousRoomScans");
    this.isLoading = false;
    if (this.isPreviousRoomScans) {
      this.prevRooms = _.clone(this.previousRoomScans);
      this.hasPreviousRooms = true;
      this.previousRoomScans.forEach((room) => {
        this.previousRoomSublocIds.push(room.fields.SublocationRecordId[0]);
      });
    }
    this.roomName = this.curRoomName;
    if (this.isCurScanRoom) {
      this.scannedItemIds = this.curScanRoom.fields.ScannedItemIds
        ? this.curScanRoom.fields.ScannedItemIds
        : [];
      this.unscannedItemIds = this.curScanRoom.fields.UnscannedItemIds
        ? this.curScanRoom.fields.UnscannedItemIds
        : [];
    }
    if (this.isCurScanRoomItems) {
      this.curRoomItemQRIds = [];
      this.curScanRoomItems.forEach((item) => {
        let qrCodeId = item.fields["Item URL Old"].split("item/")[1];
        this.curRoomQRCodes.push(qrCodeId);
        this.curRoomItemIds.push(item.id);
        this.curRoomItemQRIds.push({
          itemId: item.id,
          qrCodeId: qrCodeId,
        });
      });
    }
    if (!this.showPagination) {
      this.pageOfItems = _.clone(this.curScanRoomItems);
    }
    this.curUserDisplayName = this.authData.user_display_name;
    this.userEmail = this.authData.user_email;
  },
  methods: {
    handleRoomComplete() {
      this.isCompleteModalShowing = false;
      this.playSound("complete");
    },
    /**

     SCAN


     */
    onScan(decodedString) {
      // is item ********************************************************************************
      if (decodedString.includes("item/")) {
        this.curItemQRId = /[^/]*$/.exec(decodedString)[0];
        if (!this.isCurScanRoomItems) {
          this.$toasted.error("Scan a room QR code to begin.");
        }
        if (!this.curRoomQRCodes.includes(this.curItemQRId)) {
          this.$toasted.error(
            `This item is not in&nbsp;<span class="bold">${this.curRoomName}</span>`,
            {
              duration: 3000,
            },
          );
        } else {
          this.curRoomItemQRIds.forEach((obj) => {
            if (obj.qrCodeId === this.curItemQRId) {
              if (!this.scannedItemIds.includes(obj.itemId)) {
                this.scannedItemIds.push(obj.itemId);
                this.unscannedItemIds = this.unscannedItemIds.filter(
                  (id) => id !== obj.itemId,
                );
                this.$store.commit(
                  "setCurScanRoomScannedItemIds",
                  this.scannedItemIds,
                );
                this.$store.commit(
                  "setCurScanRoomUnscannedItemIds",
                  this.unscannedItemIds,
                );
                this.playSound("success");
                this.$toasted.success(
                  `Item ID: ${obj.itemId} in&nbsp;<span class="bold">${this.curScanRoom.fields.Room}</span>`,
                  {
                    duration: 3000,
                  },
                );
                this.updateRoomScanRecord();
              } else {
                this.playSound("fail");
                this.$toasted.error("This item has already been scanned.", {
                  duration: 3000,
                });
              }
            }
          });
        }
        /**

         IS ROOM


         */
      } else if (decodedString.includes("sublocations")) {
        this.sublocationId = /[^/]*$/.exec(decodedString)[0];
        if (
          this.previousRoomSublocIds &&
          this.previousRoomSublocIds.includes(this.sublocationId)
        ) {
          // is existing room
          this.playSound("success");
          if (this.isPreviousRoomScans) {
            this.previousRoomScans.forEach((room) => {
              if (room.fields.SublocationRecordId[0] === this.sublocationId) {
                this.curRoomId = room.id;
                this.$store.commit("setCurRoomName", room.fields.Room);
                this.isConfirmModalShowing = true;
              }
            });
          }
        } else {
          // create new room
          this.playSound("newroom");
          this.createNewRoomScanRecord();
        }
      } else {
        this.playSound("fail");
        this.$toasted.error("That wasn't a room or item QR code.", {
          duration: 3000,
        });
      }
    },

    /**

     FETCH PREVIOUS ROOM RECORD


     */

    async fetchPreviousRoom(room) {
      this.curRoomItemQRIds = [];
      this.isConfirmModalShowing = false;
      this.$store.commit("setCurScanRoom", room);
      this.$store.commit("setCurRoomName", room.fields.Room);
      this.curRoomItemIds = _.clone(room.fields.Collection);

      // fetch room items **************************************************************
      let filterByFormulaIdQuery = "OR(";
      room.fields.Collection.forEach((itemId) => {
        filterByFormulaIdQuery += `{Item ID}="${itemId}",`;
      });
      filterByFormulaIdQuery = filterByFormulaIdQuery.slice(0, -1);
      filterByFormulaIdQuery += ")";
      this.fetchRoomItems(filterByFormulaIdQuery, room);
    },

    /**

     CREATE NEW ROOM


     */

    async createNewRoomScanRecord() {
      this.isConfirmModalShowing = false;
      let collection = [];
      let goOn = true;
      this.sublocations.forEach((subloc) => {
        if (subloc.id === this.sublocationId && goOn) {
          collection = [...subloc.fields.Collection];
          this.$store.commit("setCurRoomName", subloc.fields.SubLocationName);
          goOn = false;
        }
      });
      let payload = [
        {
          fields: {
            Status: "INCOMPLETE",
            Sublocation: [this.sublocationId],
            UnscannedItemIds: collection,
            User: [this.curUserData.id],
          },
        },
      ];
      this.curRoomItemIds = [];
      this.isLoading = true;
      // create new room record & fetch items *************************
      await airtableQuery
        .createRecordAsync("Room Scans", payload)
        .then((data) => {
          this.isLoading = false;
          this.curRoomItemIds = _.clone(data[0].fields.Collection);
          this.prevRooms.push(data[0]);
          this.$store.commit("setPreviousRoomScans", this.prevRooms);
          this.$store.commit("setCurScanRoom", { ...data[0] });
          this.curRoomItemIds = [...data[0].fields.Collection];

          let filterByFormulaIdQuery = "OR(";
          this.curRoomItemIds.forEach((id) => {
            filterByFormulaIdQuery += `{Item ID}="${id}",`;
          });
          filterByFormulaIdQuery = filterByFormulaIdQuery.slice(0, -1);
          filterByFormulaIdQuery += ")";
          this.fetchRoomItems(filterByFormulaIdQuery, data[0]);
        });
    },
    /**

     FETCH ROOM ITEMS


     */
    async fetchRoomItems(fbf, room) {
      this.isLoading = true;
      const records = await airtableQuery.getTableAsync(
        "Art Inventory",
        500,
        "administrator",
        fbf,
        ["Image", "TitleClean", "Current Location", "Item URL Old"],
      );
      this.$store.commit("setCurScanRoomItems", records);
      records.forEach((item) => {
        let qrCodeId = /[^/]*$/.exec(item.fields["Item URL Old"])[0];
        this.curRoomQRCodes.push(qrCodeId);
        this.curRoomItemQRIds.push({
          itemId: item.id,
          qrCodeId: qrCodeId,
        });
      });
      this.isLoading = false;
      if (!this.showPagination) {
        this.pageOfItems = _.clone(this.curScanRoomItems);
      }
      this.scannedItemIds = [];
      this.unscannedItemIds = [];
      this.scannedItemIds = room.fields.ScannedItemIds
        ? room.fields.ScannedItemIds
        : [];
      this.unscannedItemIds = room.fields.UnscannedItemIds
        ? room.fields.UnscannedItemIds
        : [];
      this.$store.commit("setCurScanRoomScannedItemIds", this.scannedItemIds);
      this.$store.commit(
        "setCurScanRoomUnscannedItemIds",
        this.unscannedItemIds,
      );
    },
    /**

     UPDATE ROOM


     */

    async updateRoomScanRecord() {
      if (this.isComplete) {
        this.roomStatus = "COMPLETE";
        for (let i in this.prevRooms) {
          if (this.prevRooms.hasOwnProperty(i)) {
            if (this.prevRooms[i].id === this.curScanRoom.id) {
              this.prevRooms[i].fields.ScannedItemIds = this.scannedItemIds;
              this.prevRooms[i].fields.UnscannedItemIds = this.unscannedItemIds;
              this.prevRooms[i].fields.Status = this.roomStatus;
              this.$store.commit("setPreviousRoomScans", this.prevRooms);
              break;
            }
          }
        }
      } else {
        this.roomStatus = "INCOMPLETE";
      }

      let payload = [
        {
          id: this.curScanRoom.id,
          fields: {
            ScannedItemIds: this.scannedItemIds,
            UnscannedItemIds: this.unscannedItemIds,
            Status: this.roomStatus,
          },
        },
      ];
      await airtableQuery
        .updateRecordsAsync("Room Scans", payload)
        .then((data) => {
          this.$store.commit("setCurScanRoom", data[0]);
          if (this.isComplete) {
            this.isCompleteModalShowing = true;
            this.$store.commit("setCurScanRoom", {});
            this.$store.commit("setCurScanRoomItems", []);
          }
        });
    },
    generateRandomKey() {
      this.randomKey = Math.floor(Math.random() * 1000000000);
    },
    onChangePage(pageOfItems, currentPage) {
      this.currentPage = currentPage;
      this.pageOfItems = pageOfItems;
    },
  },
};
</script>
