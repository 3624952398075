<template>
  <button
    :class="{
      'is-active': isInShoppingCart,
    }"
    class="c-btn btn-add-cart buy"
    aria-label="add to buy cart"
    title="Buy this item"
    @click.stop.prevent="addToShoppingCart"
  >
    <span class="icon">
      <svg
        v-if="!isInShoppingCart"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
      >
        <path
          d="M11 9h2V6h3V4h-3V1h-2v3H8v2h3M7 18a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2m10 0a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2m-9.83-3.25l.03-.12l.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.86-7.01L19.42 4h-.01l-1.1 2l-2.76 5H8.53l-.13-.27L6.16 6l-.95-2l-.94-2H1v2h2l3.6 7.59l-1.35 2.45c-.16.28-.25.61-.25.96a2 2 0 0 0 2 2h12v-2H7.42c-.13 0-.25-.11-.25-.25z"
          fill="#626262"
        />
        <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
      >
        <path
          d="M14.12 8.53L12 6.41L9.88 8.54L8.46 7.12L10.59 5L8.47 2.88l1.41-1.41L12 3.59l2.12-2.13l1.42 1.42L13.41 5l2.12 2.12l-1.41 1.41M7 18a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m10 0a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m-9.83-3.25a.25.25 0 0 0 .25.25H19v2H7a2 2 0 0 1-2-2c0-.35.09-.68.25-.96l1.35-2.45L3 4H1V2h3.27l.94 2l.95 2l2.24 4.73l.13.27h7.02l2.76-5l1.1-2h.01l1.74.96l-3.86 7.01c-.34.62-1 1.03-1.75 1.03H8.1l-.9 1.63l-.03.12z"
          fill="#626262"
        />
        <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
      </svg>
    </span>
    <span v-if="!isInShoppingCart" class="btn-text">BUY</span>
    <span v-else class="btn-text">REMOVE</span>
  </button>
</template>
<script>
import { mapState } from "vuex";
import airtableQuery from "@/services/airtableQuery.service";
import _ from "lodash";

export default {
  name: "BtnShoppingCart",
  props: {
    text: {
      type: String,
      required: false,
      default: "Buy",
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      curItemId: "",
      curShoppingCartItemIds: [],
      orderTotal: 0,
    };
  },
  computed: {
    ...mapState(["shoppingCartItemIds", "curUserData", "shoppingCartTotal"]),
    isInShoppingCart() {
      return this.curShoppingCartItemIds.includes(this.curItemId);
    },
  },
  watch: {
    shoppingCartItemIds(newVal) {
      this.curShoppingCartItemIds = [...newVal];
    },
  },
  created() {
    this.curItemId = this.item && this.item.id;
    this.curShoppingCartItemIds = _.clone(this.shoppingCartItemIds);
    this.orderTotal = this.shoppingCartTotal;
  },
  methods: {
    addToShoppingCart() {
      if (this.isInShoppingCart) {
        this.orderTotal -= this.item.fields.AskingPrice;
        this.$store.commit("setShoppingCartTotal", this.orderTotal);

        this.curShoppingCartItemIds = [...this.curShoppingCartItemIds].filter(
          (itemId) => itemId !== this.curItemId,
        );
        this.$store.commit("removeShoppingCartItemId", this.curItemId);
        this.$store.commit("removeShoppingCartItem", this.curItemId);
      } else {
        this.orderTotal += this.item.fields.AskingPrice;
        this.$store.commit("setShoppingCartTotal", this.orderTotal);

        this.curShoppingCartItemIds.push(this.curItemId);
        this.$store.commit("addShoppingCartItemId", this.curItemId);
        this.$store.commit("addShoppingCartItem", this.curItemId);
      }
      airtableQuery.updateRecordsAsync("Users", [
        {
          id: this.curUserData.id,
          fields: { ShoppingCart: this.curShoppingCartItemIds },
        },
      ]);
    },
  },
};
</script>
