<template>
  <div class="c-filter__selected__list" :class="{ disabled: isLoading }">
    <!--    location-->
    <button
      v-for="(item, index) in filters.locations"
      :key="'location_index_' + index"
      class="c-btn btn-remove"
      aria-label="Remove Filter"
      title="Remove this filter"
      @click="removeFilter('delFilterLocation', item)"
    >
      <span v-if="locationDisplayName(item)" class="text">
        {{ locationDisplayName(item) }}
      </span>
      <Progress v-else fill-color="#99855c" width="17" />
      <Close />
    </button>
    <!--    sublocation-->
    <button
      v-for="(item, index) in filters.sublocations"
      :key="'sublocation_index_' + index"
      class="c-btn btn-remove"
      aria-label="Remove Filter"
      title="Remove this filter"
      @click="removeFilter('delFilterSubLocation', item)"
    >
      <span v-if="sublocationDisplayName(item)" class="text">
        {{ sublocationDisplayName(item) }}
      </span>
      <Progress v-else fill-color="#99855c" width="17" />
      <Close />
    </button>
    <!--    category-->
    <button
      v-for="(item, index) in filters.categories"
      :key="'category_index_' + index"
      class="c-btn btn-remove"
      aria-label="Remove Filter"
      title="Remove this filter"
      @click="removeFilter('delFilterCategory', item)"
    >
      <span class="text">{{ item }}</span>
      <Close />
    </button>
    <!--    merchant -->
    <button
      v-for="(item, index) in filters.merchants"
      :key="'merchant_index_' + index"
      class="c-btn btn-remove"
      aria-label="Remove Filter"
      title="Remove this filter"
      @click="removeFilter('delFilterMerchant', item)"
    >
      <span class="text">{{ item }}</span>
      <Close />
    </button>
    <!--    collections  -->
    <button
      v-for="(item, index) in filters.collections"
      :key="'collection_index_' + index"
      class="c-btn btn-remove"
      aria-label="Remove Filter"
      title="Remove this filter"
      @click="removeFilter('delFilterCollection', item)"
    >
      <span class="text">{{ item }}</span>
      <Close />
    </button>
    <!--    quality-->
    <button
      v-for="(item, index) in filters.quality"
      :key="'quality_index_' + index"
      class="c-btn btn-remove"
      aria-label="Remove Filter"
      title="Remove this filter"
      @click="removeFilter('delFilterQuality', item)"
    >
      <span class="text">{{ item }}</span>
      <Close />
    </button>
    <!--    for sale-->
    <button
      v-if="filters.forSale"
      key="forSale"
      class="c-btn btn-remove"
      aria-label="Remove Filter"
      title="Remove this filter"
      @click="removeFilter('setFilterForSale', false)"
    >
      <span class="text">For Sale</span>
      <Close />
    </button>
    <!--    keyword-->
    <button
      v-if="filters.keyword"
      class="c-btn btn-remove"
      aria-label="Remove Filter"
      title="Remove this filter"
      @click="removeFilter('delFilterKeyword')"
    >
      <span class="text">{{ filters.keyword }}</span>
      <Close />
    </button>
    <!--    price range-->
    <button
      v-if="hasPriceRange"
      class="c-btn btn-remove"
      aria-label="Remove Filter"
      title="Remove this filter"
      @click="removeFilter('delFilterPriceRange')"
    >
      <span class="text">Price</span>
      <Close />
    </button>
    <!--    no Value-->
    <button
      v-if="hasNoValue"
      class="c-btn btn-remove"
      aria-label="Remove Filter"
      title="Remove this filter"
      @click="removeFilter('setFilterHasNoValue', null)"
    >
      <span class="text">No Value</span>
      <Close />
    </button>
    <!--    length range-->
    <button
      v-if="hasLengthRange"
      class="c-btn btn-remove"
      aria-label="Remove Filter"
      title="Remove this filter"
      @click="removeFilter('delFilterLengthRange')"
    >
      <span class="text">Length</span>
      <Close />
    </button>
    <!--    width range-->
    <button
      v-if="hasWidthRange"
      class="c-btn btn-remove"
      aria-label="Remove Filter"
      title="Remove this filter"
      @click="removeFilter('delFilterWidthRange')"
    >
      <span class="text">Width</span>
      <Close />
    </button>
    <!--    date range-->
    <button
      v-if="hasDateRange"
      class="c-btn btn-remove"
      aria-label="Remove Filter"
      title="Remove this filter"
      @click="removefilterDateRange"
    >
      <span class="text">Date Range</span>
      <Close />
    </button>
    <!--    favorites-->
    <button
      v-if="filters.favorites === 'true'"
      class="c-btn btn-remove"
      aria-label="Remove Favorites"
      @click="removeFilter('setFavorites', false)"
    >
      <span class="text">Favorites</span>
      <Close />
    </button>
    <div ref="filters" class="c-filter__selected__item"></div>
    <div v-if="hasNoFilters" class="no-filters-selected">
      No filters selected
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Close from "vue-material-design-icons/Close";
import { baseFilters, baseFiltersCM } from "@/constants";
import Progress from "@/components/ui/Progress";
import _ from "lodash";

export default {
  name: "SelectedFilters",
  components: {
    Close,
    Progress,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState(["filters", "sublocations", "locations"]),
    hasPriceRange() {
      let minP = parseInt(this.filters.minPrice);
      let maxP = parseInt(this.filters.maxPrice);
      return minP !== 0 || maxP !== 5000000;
    },
    maxUnit() {
      return this.filters.units === "IN" ? 500 : 1250;
    },
    hasLengthRange() {
      let minL = parseInt(this.filters.minLength);
      let maxL = parseInt(this.filters.maxLength);
      return minL !== 0 || maxL !== this.maxUnit;
    },
    hasWidthRange() {
      let minW = parseInt(this.filters.minWidth);
      let maxW = parseInt(this.filters.maxWidth);
      return minW !== 0 || maxW !== this.maxUnit;
    },
    hasDateRange() {
      return this.filters.dateStart || this.filters.dateEnd;
    },
    hasNoFilters() {
      return (
        _.isEqual(this.filters, baseFilters) ||
        _.isEqual(this.filters, baseFiltersCM)
      );
    },
    hasNoValue() {
      return (
        this.filters.hasNoValue === "true" || this.filters.hasNoValue === true
      );
    },
  },
  updated() {
    this.$emit("set-no-filters", this.hasNoFilters);
  },
  methods: {
    locationDisplayName(item) {
      let displayName;
      this.locations.forEach((subloc) => {
        if (subloc.id === item) {
          displayName = subloc.fields.Location;
        }
      });
      return displayName;
    },
    sublocationDisplayName(item) {
      let displayName;
      let found = false;
      this.sublocations.forEach((subloc) => {
        if (subloc.id === item && !found) {
          displayName = subloc.fields.SubLocationName;
          found = true;
        }
      });
      return displayName;
    },
    removeFilter(mutation, payload) {
      this.$store.commit(mutation, payload);
    },
    removefilterDateRange() {
      this.$root.$emit("reset-dates");
      this.$store.commit("delFilterDateStart");
      this.$store.commit("delFilterDateEnd");
    },
  },
};
</script>
