<template>
  <div v-if="room" class="c-room__list__items room-wrap">
    <div class="room-details">
      <span>Room:</span>
      <div class="room">{{ room.fields.Room }}</div>
      <span>Created:</span>
      <div class="created">
        {{ getDate(room.fields.Created) }}
      </div>
      <span>Updated:</span>
      <div class="updated">
        {{ getDate(room.fields.Modified) }}
      </div>
      <span>Status:</span>
      <div class="scan-status" :class="{ scanned: complete }">
        <span v-if="complete"> COMPLETE </span>
        <span v-else> INCOMPLETE </span>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
export default {
  name: "PreviousRoom",
  props: {
    room: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    complete() {
      return _.isEmpty(this.room.fields.UnscannedItemIds);
    },
  },
  methods: {
    getDate(dt) {
      let date = new Date(dt);
      const mmddyyyy =
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear();

      let time = date.toLocaleTimeString().replace(/:\d+ /, " ");
      return `${mmddyyyy}, ${time}`;
    },
  },
};
</script>
