import axios from "axios";
import environment from "@/environments/environment";
import store from "../store/index";
import router from "../router";

const instance = axios.create({
  baseURL: environment.CUSTOM_BASE_URL,
});

instance.interceptors.request.use((config) => {
  store.commit("setNetworkBusyStatus", true);
  return config;
});

instance.interceptors.response.use(
  (response) => {
    store.commit("setNetworkBusyStatus", false);
    return response;
  },
  (error) => {
    if ([401, 403].includes(error.response.status)) {
      store.commit("delUserData");
      router.push("/login");
    }
    return Promise.reject(error);
  },
);

export default {
  get(path) {
    return instance.get(instance.defaults.baseURL + path);
  },
  post(path, params) {
    return instance.post(instance.defaults.baseURL + path, params);
  },
  put(path, params) {
    return instance.put(instance.defaults.baseURL + path, params);
  },
  delete(path, params) {
    return instance.delete(instance.defaults.baseURL + path, params);
  },
};
