<template>
  <div class="c-filter__range">
    <div class="range-slider length">
      <div class="range-display">
        <input
          v-model="minLengthInput"
          class="text-input min"
          type="text"
          oninput="this.value=this.value.replace(/[^0-9]/g,'')"
          maxlength="4"
          min="0"
          :max="max"
          step="10"
          @blur="setFilterLengthRange(minLengthInput, maxLengthInput)"
        />
        <input
          v-model="maxLengthInput"
          class="text-input max"
          type="text"
          oninput="this.value=this.value.replace(/[^0-9]/g,'')"
          maxlength="4"
          min="0"
          :max="max"
          step="10"
          @blur="setFilterLengthRange(minLengthInput, maxLengthInput)"
        />
      </div>
      <input
        v-model.number="minLengthInput"
        min="0"
        :max="max"
        step="10"
        type="range"
        class="min-range"
        @input="handleSlide($event)"
        @change="setFilterLengthRange(minLengthInput, maxLengthInput)"
      />
      <input
        v-model.number="maxLengthInput"
        min="0"
        :max="max"
        step="10"
        type="range"
        class="max-range"
        @input="handleSlide($event)"
        @change="setFilterLengthRange(minLengthInput, maxLengthInput)"
      />
      <svg width="100%" height="28">
        <line
          x1="4"
          y1="0"
          x2="400"
          y2="0"
          stroke="#444"
          stroke-width="18"
          stroke-dasharray="1 30"
        />
      </svg>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "LengthRange",
  props: {
    units: {
      type: String,
      required: true,
      default: "IN",
    },
  },
  data() {
    return {
      minLengthInput: 0,
      maxLengthInput: 0,
    };
  },
  computed: {
    ...mapState(["filters"]),
    max() {
      let u = this.units;
      return u === "IN" ? 500 : 1250;
    },
    offset() {
      let u = this.units;
      return u === "IN" ? 10 : 25;
    },
  },
  watch: {
    units(newVal) {
      if (newVal === "CM") {
        this.maxLengthInput = parseInt(this.maxLengthInput, 10) * 2.5;
      } else {
        this.maxLengthInput = parseInt(this.maxLengthInput, 10);
      }
    },
    filters: {
      handler(newVal) {
        this.maxLengthInput = newVal.maxLength;
        this.minLengthInput = newVal.minLength;
      },
      deep: true,
    },
  },
  created() {
    this.minLengthInput = parseInt(this.filters.minLength, 10);
    this.maxLengthInput = parseInt(this.filters.maxLength, 10);
  },
  methods: {
    handleSlide(event) {
      let handle = event.target.classList[0];
      if (handle === "max-range") {
        if (this.maxLengthInput < this.minLengthInput + this.offset) {
          this.maxLengthInput = this.minLengthInput + this.offset;
        }
        if (this.maxLengthInput >= this.max) {
          this.maxLengthInput = this.max;
        }
      }
      if (handle === "min-range") {
        if (this.minLengthInput >= this.maxLengthInput - this.offset) {
          this.minLengthInput = this.maxLengthInput - this.offset;
        }
      }
      if (this.minLengthInput < 1) {
        this.minLengthInput = 0;
      }
    },
    setFilterLengthRange(minLength, maxLength) {
      let min = parseInt(minLength, 10);
      let max = parseInt(maxLength, 10);
      if (max < min) {
        max = min + this.offset;
        this.maxLengthInput = min + this.offset;
      }
      this.$store.commit("setFilterLengthRange", [min, max]);
    },
  },
};
</script>
