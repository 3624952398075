<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal" role="dialog">
        <header class="modal-header">
          <h2 class="modal-title">Room Scan Complete</h2>
          <button
            type="button"
            role="button"
            class="btn-close-modal"
            aria-label="Close modal"
            @click="$emit('on-cancel')"
          >
            <svg
              data-name="CloseX"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <path
                d="M31.06,32a.94.94,0,0,1-.67-.28L16,17.33,1.61,31.72A.94.94,0,0,1,.28,30.39L14.67,16,.28,1.61A.94.94,0,0,1,1.61.28L16,14.67,30.39.28a.94.94,0,0,1,1.33,1.33L17.33,16,31.72,30.39a.94.94,0,0,1,0,1.33A.9.9,0,0,1,31.06,32Z"
                transform="translate(0 0)"
              />
            </svg>
          </button>
        </header>
        <div class="modal-body">
          <p>All items in this room have been scanned.</p>
        </div>
        <footer class="modal-footer">
          <button
            class="c-btn c-btn--primary ml-0"
            aria-label="Continue Room Scan"
            @click="$emit('on-close')"
          >
            Close
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "RoomScanCompleteModal",
};
</script>
