<template>
  <div v-if="!noResults || !isEmpty" class="slick-slider-wrap">
    <header class="slider-header">
      <h3 class="c-section__title">{{ category.fields.Category }}</h3>
      <a class="btn-white" @click="handleSeeAll(category.fields.Category)"
        >See All</a
      >
    </header>
    <div v-if="isLoading" class="progress-wrap">
      <Progress fill-color="#000000" />
    </div>
    <VueSlickCarousel v-if="currentItems.length" v-bind="imageOptions">
      <div v-for="item in currentItems" :id="item.id" :key="item.id">
        <SlickItem v-if="currentItems.length" :key="item.id" :item="item" />
      </div>
      <template slot="prevArrow">
        <button type="button" class="agile__nav-button agile__nav-button--prev">
          <svg
            width="11"
            viewBox="0 0 11 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10 1L1 10L10 19" stroke="#1A120F"></path>
          </svg>
        </button>
      </template>
      <template slot="nextArrow">
        <button type="button" class="agile__nav-button agile__nav-button--next">
          <svg
            width="11"
            viewBox="0 0 11 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L10 10L1 19" stroke="#1A120F"></path>
          </svg>
        </button>
      </template>
    </VueSlickCarousel>
    <div v-if="noResults && isEmpty" class="no-results">
      <h3>No results</h3>
    </div>
  </div>
</template>
<script>
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import Progress from "@/components/ui/Progress";
import SlickItem from "./SlickItem";
import VueSlickCarousel from "vue-slick-carousel";
import router from "@/router";
import { agileOptions2, base } from "@/constants";
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "LatestPurchase",
  components: {
    Progress,
    SlickItem,
    VueSlickCarousel,
  },
  props: {
    category: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      currentItems: [],
      fields: [],
      isLoading: true,
      isEmpty: false,
      imageOptions: agileOptions2,
    };
  },
  computed: {
    ...mapState(["authData", "curUserPerms"]),
    noResults() {
      return this.currentItems.length === 0;
    },
  },
  created() {
    let self = this;
    if (this.curUserPerms.permittedArtInventoryFields.includes("All")) {
      this.fields = [];
    } else {
      this.fields = _.clone(this.curUserPerms.permittedArtInventoryFields);
    }
    base("Art Inventory")
      .select({
        maxRecords: 100,
        view: this.authData.user_role,
        fields: this.fields,
        filterByFormula: `({Category}="${self.category.fields.Category}")`,
        sort: [{ field: "Sale Date", direction: "desc" }],
      })
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            self.currentItems.push(record);
          });
          fetchNextPage();
        },
        function done(err) {
          self.isLoading = false;
          if (!self.currentItems.length) {
            self.isLoading = false;
            self.isEmpty = true;
          }
          if (err) {
            console.error(err);
          }
        },
      );
  },
  methods: {
    handleSeeAll(category) {
      this.$store.commit("setFilterCategoryFromMenu", category);
      this.$store.commit("setTotalResults", 0);
      router.push({
        path: "/art-inventory",
        query: { reload: true, sortBy: "Sale Date" },
      });
    },
  },
};
</script>
