<template>
  <div v-if="merchants && merchants.length" class="grid-wrap">
    <div
      v-for="(merchant, index) in merchants"
      :key="index"
      class="grid-item merchant"
      @click.stop="setCurrentMerchant(merchant)"
    >
      <img
        v-if="merchant.fields.Logo"
        :src="merchant.fields.Logo[0].url"
        alt="Merchant Logo"
      />
      <span class="text">{{ merchant.fields.Merchant }}</span>
    </div>
  </div>
  <div v-else>
    <div class="message-box">
      <h2 class="message-box__title">CYCAD SECURITY</h2>
      <p class="message-box__text">
        No merchants are available to this user role
      </p>
    </div>
  </div>
</template>
<script>
import router from "../../router";
import { mapState } from "vuex";

export default {
  name: "Merchants",
  computed: {
    ...mapState(["merchants"]),
  },
  methods: {
    setCurrentMerchant(merchant) {
      this.$store.dispatch("resetFilters");
      this.$store.commit("setFilterMerchantFromMenu", merchant.fields.Merchant);
      this.$store.commit("setTotalResults", 0);
      this.$store.commit("setFetchingData", true);
      router.push({
        path: "/art-inventory",
        query: { reload: true },
      });
    },
  },
};
</script>
