import Airtable from "airtable";
import environment from "@/environments/environment";

export const baseFilters = {
  categories: [],
  collections: [],
  dateEnd: "",
  dateStart: "",
  excludeItemsWithNoValue: false,
  favorites: "",
  forSale: false,
  hasNoValue: false,
  hasPriceRange: false,
  itemIds: "",
  keyword: "",
  locations: [],
  maxLength: 500,
  maxPrice: 5000000,
  maxWidth: 500,
  merchants: [],
  minLength: 0,
  minPrice: 0,
  minWidth: 0,
  quality: [],
  sublocations: [],
  units: "IN",
};

export const baseFiltersCM = {
  categories: [],
  collections: [],
  dateEnd: "",
  dateStart: "",
  excludeItemsWithNoValue: false,
  favorites: "",
  forSale: false,
  hasNoValue: false,
  hasPriceRange: false,
  itemIds: "",
  keyword: "",
  locations: [],
  maxLength: 1250,
  maxPrice: 5000000,
  maxWidth: 1250,
  merchants: [],
  minLength: 0,
  minPrice: 0,
  minWidth: 0,
  quality: [],
  sublocations: [],
  units: "CM",
};

export const emptyUser = {
  token: "",
  user_display_name: "",
  user_email: "",
  user_nicename: "",
  user_role: "",
};

export const baseRouteQuery = `{"categories":"","collections":"","dateEnd":"","dateStart":"","favorites":"","itemIds":"","keyword":"","layout":"masonry-small","locations":"","lengthMin":"0","lengthMax":"500","priceMax":"5000000","merchants":"","priceMin":"0","quality":"","sublocations":""}`;

export const baseQueryUrl =
  "http://localhost:8080/art-inventory?categories=&collections=&dateEnd=&dateStart=&excludeItemsWithNoValue=false&favorites=&forSale=false&hasNoValue=false&hasPriceRange=false&itemIds=&keyword=&locations=&maxLength=500&maxPrice=5000000&maxWidth=500&merchants=&minLength=0&minPrice=0&minWidth=0&quality=&sublocations=&units=IN&reload=true";

export const base = new Airtable({ apiKey: environment.API_KEY }).base(
  environment.AIR_TABLE_BASE_ID,
);

export const agileOptions = {
  imageOptions: {
    dots: false,
    fade: false,
    navButtons: true,
    infinite: true,
  },
  thumbOptions: {
    autoplay: false,
    dots: false,
    navButtons: true,
    slidesToShow: 4,
    infinite: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          navButtons: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          navButtons: true,
          slidesToShow: 7,
        },
      },
    ],
  },
};

export const agileOptions2 = {
  dots: false,
  infinite: true,
  speed: 200,
  slidesToShow: 6,
  lazyLoad: "ondemand",
  slidesToScroll: 6,
  progressive: "progressive",
  responsive: [
    {
      breakpoint: 1999,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      },
    },
    {
      breakpoint: 1799,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 1599,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1399,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 599,
      settings: {
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
export const baseUserPerms = {
  canBuy: false,
  canSetAskingPrice: false,
  canSetQuality: false,
  canTransfer: false,
  canViewCollectionCount: false,
  permittedCategories: [],
  permittedCollections: [],
  permittedFilters: [],
  permittedLocationFields: [],
  permittedLocations: [],
  permittedMerchants: [],
  permittedPages: [],
  permittedSorting: [],
};

export const layouts = ["masonry-small", "masonry-large", "list", "grid"];

export const initialState = {
  airTableResTime: "",
  authData: Object.assign(emptyUser),
  categories: [],
  collections: [],
  compareList: [],
  curItemDetailRecord: {},
  curRoomName: "",
  curScanRoom: {},
  curScanRoomItems: [],
  curScanRoomScannedItemIds: [],
  curScanRoomUnscannedItemIds: [],
  curUserData: {},
  curUserPerms: Object.assign(baseUserPerms),
  currentLayout: "",
  currentQueryURL: "",
  currentRecordId: "",
  currentRecordIndex: "",
  fetchingData: false,
  filterByFormula: "",
  filterByFormulaIdQuery: "",
  filteredItemIds: [],
  filters: Object.assign(baseFilters),
  hasAskingPrice: false,
  initialPage: 1,
  items: [],
  layouts: layouts,
  loadingFavorites: "",
  locations: [],
  loggingIn: false,
  loginError: null,
  merchants: [],
  networkBusyStatus: false,
  offset: null,
  permittedArtInventoryFields: [],
  previousRoomRecordIds: [],
  previousRoomScans: [],
  qualities: [],
  scrollOffset: 0,
  selectedLocs: [],
  setPreviousScanRecordIds: [],
  shoppingCart: [],
  shoppingCartItemIds: [],
  shoppingCartTotal: 0,
  showItemNav: false,
  sortByArr: [],
  sortDirection: "desc",
  sublocations: [],
  totalResults: 0,
  transferCart: [],
  transferCartItemIds: [],
  transferOrder: [],
  userFavoriteItemIds: [],
  userFavorites: [],
  userRoles: [],
};

export const allFields = [
  "ARTISAN B / BYA",
  "Additional Location Comments",
  "Additional Pricing Info",
  "Admin Tag",
  "Artist",
  "Artists 2",
  "Artists",
  "AskingPrice",
  "AskingPriceSetByUser",
  "Autonumber",
  "CERTIFICATES / RESTORATIONS",
  "Carpet Tag No.",
  "Category Symbol",
  "Category",
  "CategoryName",
  "Certificate",
  "CollectionNameTemp",
  "CollectionNames",
  "Collections",
  "CollectionsFormula",
  "Crate",
  "Created by",
  "CreatedDate",
  "Current Location",
  "Current TransferId",
  "Date Added",
  "Date Modified",
  "Delivery Location",
  "Description",
  "DescriptionClean",
  "DescriptionRaw",
  "Dimensions CM",
  "Dimensions",
  "ForSale",
  "ForSaleSetByUser",
  "Image Size",
  "Image",
  "ImagesOptimized",
  "Insurance Assumption",
  "Insurance Locations Plain",
  "Insurance Locations",
  "Internal (Full) Description",
  "Internal Images",
  "Internal Title",
  "Internal Value",
  "Inventory No.",
  "Invoice No.",
  "Invoice Title",
  "Invoices",
  "Item ID",
  "Item URL New",
  "Item URL Old",
  "ItemQ",
  "Kuwait Tag",
  "LOG",
  "Latest Purchases",
  "Length CM",
  "Length FT IN",
  "Length IN",
  "Limited Description",
  "Location (Destination)(from Locations Table)",
  "Location",
  "LocationName",
  "LocationRecordId",
  "Lot No.",
  "MERGE REF NO.",
  "Media",
  "Merchant Image",
  "Merchant",
  "MerchantName",
  "Merchants",
  "ModelGLB",
  "NOTES KIRK",
  "Non-English Info",
  "Note that comes from transfers",
  "Notes",
  "OLD URL",
  "ORIGINAL TEXT",
  "Old Insurance Location",
  "Old Item ID",
  "Old Location",
  "Original Currency 1",
  "Original Currency 2",
  "Original Currency 3",
  "PAST NOTES",
  "ParentLocation",
  "Past Inventory No.",
  "Print Value",
  "Purchase Location (TEMPORARY)",
  "PurchaseID",
  "Purchases",
  "QR Code Current",
  "QR Code",
  "Quality Line",
  "Quality",
  "QualityName",
  "Quantity (Qty)",
  "Related Documents",
  "Room Scans",
  "Rooms 2",
  "Rooms 3",
  "Rooms 4 copy",
  "Rooms 4",
  "Rooms",
  "SALE YEAR LOOKUP",
  "SOTHEBY'S APR 2014 DISCREPANCIES",
  "Sale Date",
  "Sale Documents",
  "Sale No.",
  "Sale Price",
  "Sale",
  "SaleDateMonth",
  "SaleDateYear",
  "SaleStatus",
  "Sanjay No.",
  "Schedule A - Art Contribution",
  "Schedule A Duplicate",
  "Schedule A Note",
  "Seller",
  "SellingWith",
  "Send This To",
  "Sent to Val",
  "SetForSaleDate",
  "SetPriceTime",
  "Shipping Documents",
  "Shipping Reference (Tracking Details, Box Nu",
  "Shipping Status",
  "Sold On",
  "Sold",
  "SoldDate",
  "SoldOnYear",
  "Status",
  "SubLocURL",
  "SubLocation Plain",
  "SubLocation",
  "SubLocationID",
  "SubLocationName",
  "SubLocations",
  "SublocationRecordId",
  "TEMP LOC PLAIN",
  "TEMP LOC",
  "TRAVEL DOCS",
  "Tags",
  "Temp - Packed",
  "Tennis house request",
  "Tier 1M",
  "Tier",
  "Title",
  "TitleClean",
  "TransferId (from Locations)",
  "User (from Locations Table)",
  "Users 2",
  "Users 3",
  "Users",
  "Value",
  "Weight",
  "Width CM",
  "Width FT IN",
  "Width IN",
  "Workfield",
];
