<template>
  <main id="artInventoryPage" aria-label="content">
    <secondary-header
      :current-layout="currentLayout"
      class="js-content"
      @update-masonry="generateRandomKey"
    />
    <div class="c-filter__selected has-print-mode">
      <div class="c-filter__selected__title">Selected Filters:</div>
      <selected-filters-sidebar :key="`filters_sidebar_${randomKey}`" />
    </div>
    <section :class="`t-${currentLayout}`" class="layout-wrap">
      <sidebar
        ref="sidebarComp"
        @reset-total-results="resultsCount = 0"
        @reset-filters="resetFilters"
        @submit-filters="submitFilters"
      />
      <div class="content-area">
        <div v-if="showGrid">
          <card-masonry
            v-if="currentLayout === 'masonry-small'"
            :key="`masonrySmall_${randomKey}`"
            :current-items="currentItems"
          />
          <card-masonry
            v-if="currentLayout === 'masonry-large'"
            :key="`masonryLarge_${randomKey}`"
            :current-items="currentItems"
          />
          <card-list
            v-if="currentLayout === 'list'"
            :key="`list_${randomKey}`"
            :current-items="currentItems"
          />
          <card-grid
            v-if="currentLayout === 'grid'"
            :key="`grid_${randomKey}`"
            :current-items="currentItems"
          />
        </div>
        <div v-if="fetchingData" class="spinner-wrap">
          <Spinner />
        </div>
        <div v-if="!fetchingData && showNoResultsAlert">
          <div class="message-box">
            <h2 class="message-box__title">No result found.</h2>
            <p class="message-box__text">
              Please change filter parameters or search again.
            </p>
          </div>
        </div>
        <div v-if="!fetchingData && showEmptyInventoryAlert">
          <div class="message-box">
            <h2 class="message-box__title">CYCAD INVENTORY</h2>
            <p class="message-box__text">
              Please set filter parameters or search to begin.
            </p>
          </div>
        </div>
      </div>
    </section>
    <details-modal
      v-if="isDetailsModalVisible"
      v-show="isDetailsModalVisible"
      :details-modal-data="detailsModalData"
      :is-busy="loadingInvoiceModalData"
      :z-index="detailsModalZIndex"
      @close="closeModal('isDetailsModalVisible')"
      @delegate-invoice-id="getInvoiceData"
    />
    <invoice-modal
      v-if="isInvoiceModalVisible"
      v-show="isInvoiceModalVisible"
      :details-data="invoiceModalDetailsData"
      :invoice-data="invoiceModalData"
      :z-index="invoiceModalZIndex"
      @close="closeModal('isInvoiceModalVisible')"
      @close-details-modal="closeModal('isDetailsModalVisible')"
      @prepare-invoice-modal="prepareInvoiceModal(data)"
      @prepare-details-modal="prepareDetailsModal"
    />
  </main>
</template>
<script>
import CardGrid from "../ui/layouts/CardGrid";
import CardList from "../ui/layouts/CardList";
import CardMasonry from "../ui/layouts/CardMasonry";
import DetailsModal from "../modals/DetailsModal";
import InvoiceModal from "../modals/InvoiceModal";
import SecondaryHeader from "../ui/SecondaryHeader";
import SelectedFiltersSidebar from "../sidebar/SelectedFilters";
import Sidebar from "../sidebar/Sidebar";
import Spinner from "../ui/Spinner";
import _ from "lodash";
import airtableClient from "../../services/airtableClient.service";
import airtableQuery from "../../services/airtableQuery.service";
import dom from "../../services/dom.service";
import { baseFilters } from "@/constants";
import { mapState } from "vuex";

export default {
  name: "ArtInventory",
  components: {
    CardGrid,
    CardList,
    CardMasonry,
    DetailsModal,
    InvoiceModal,
    SecondaryHeader,
    SelectedFiltersSidebar,
    Sidebar,
    Spinner,
  },
  data() {
    return {
      curQuery: "",
      currentFilters: {},
      currentItems: [],
      curSortByArr: [],
      detailsModalData: {},
      detailsModalZIndex: 3,
      forSale: [],
      fullPath: "",
      invoiceModalData: {},
      invoiceModalDetailsData: {},
      invoiceModalZIndex: 3,
      isDetailsModalVisible: false,
      isInvoiceModalVisible: false,
      itemIds: [],
      loadingInvoiceModalData: false,
      offsetData: "",
      qualities: ["Museum", "Home", "Rental"],
      randomKey: 42,
      rangeStatus: false,
      resultsCount: 0,
      showSpinner: false,
      storeSubscription: "",
      showNoResultsAlert: false,
      showEmptyInventoryAlert: true,
    };
  },
  computed: {
    ...mapState([
      "authData",
      "curUserData",
      "curUserPerms",
      "currentLayout",
      "fetchingData",
      "filters",
      "items",
      "hasAskingPrice",
      "loadingTables",
      "networkBusyStatus",
      "offset",
      "sortByArr",
      "totalResults",
      "userRoles",
    ]),
    totalResults: {
      get() {
        return this.$store.state.totalResults;
      },
      set() {
        this.$store.commit("setHasAskingPrice", this.currentItems.length);
      },
    },
    hasFilters() {
      return !_.isEqual(this.currentFilters, baseFilters);
    },
    showGrid() {
      return (
        !this.fetchingData && this.currentItems && this.currentItems.length
      );
    },
  },
  watch: {
    sortByArr: {
      handler(newVal) {
        if (newVal) {
          this.curSortByArr = [...newVal];
        }
      },
      deep: true,
    },
    items: {
      handler(newItems) {
        if (newItems.length) {
          this.showEmptyInventoryAlert = false;
        }
        if (newItems) {
          this.currentItems = _.clone(newItems);
        }
      },
      deep: true,
    },
  },
  created() {
    dom.removeHtmlClass("is-fixed");
    console.log(
      `%c ART INVENTORY`,
      "font-size: 13px; background-color:  #1283DA; color: white; padding: 3px 9px 3px 4px;",
      this.curUserPerms,
    );
    this.curSortByArr = _.clone(this.sortByArr);
    this.currentFilters = _.clone(this.filters);
    this.currentItems = _.clone(this.items) || [];
    this.$root.$on("reset-filters", this.resetFilters);
    this.$root.$on("submit-filters", this.submitFilters);
    this.$root.$on("submit-keyword", this.submitKeyword);
    this.$root.$on("update-masonry", this.generateRandomKey);
    this.$root.$on("reset-results-count", this.resetResultsCount);
    this.$root.$on("sort-current-results", this.sortCurrentResults);
    if (this.currentItems.length) {
      this.showNoResultsAlert = false;
      this.showEmptyInventoryAlert = false;
    }
    this.initComponent();
  },
  mounted() {
    this.$store.commit("setTotalResults", this.resultsCount);
    this.offsetData = "";
    dom.removeHtmlClass("has-modal");
    let isRedirect = this.$route.redirectedFrom !== undefined;
    if (isRedirect && this.$route.redirectedFrom.includes("sublocations")) {
      let sublocId = this.$route.redirectedFrom.split("sublocations/")[1];
      this.$store.commit("setFilterSubLocation", sublocId);
      this.$store.commit("setFetchingData", true);
    }
    // PURPLE PURPLE PURPLE PURPLE PURPLE PURPLE PURPLE PURPLE PURPLE PURPLE
    if (this.$route.query.reload === "true") {
      if (this.$route.query.sortBy) {
        this.$store.commit("setSortByArr", [
          { field: "Sale Date", direction: "desc" },
        ]);
      }
      console.log(
        `%c REOAD=TRUE`,
        "font-size: 13px; background-color: purple; color: white; padding: 3px 5px;",
      );
      if (this.$route.query.reset === "true") {
        this.$store.dispatch("resetFilters");
        this.$store.commit("setItems", []);
        this.$store.commit("setTotalResults", 0);
      }
      let url = window.location.href.replace("&reload=true", "");
      window.history.replaceState(null, null, url);
      this.$store.commit("setFetchingData", true);
      // BLUE BLUE BLUE BLUE BLUE BLUE BLUE BLUE BLUE BLUE BLUE BLUE BLUE BLUE BLUE
    } else if (this.$route.query.reload === "false") {
      this.$store.commit("setFetchingData", false);
      console.log(
        `%c REOAD=FALSE`,
        "font-size: 13px; background-color: blue; color: white; padding: 3px 5px;",
      );
      if (this.$route.query.reset === "true") {
        this.$store.dispatch("resetFilters");
        this.$store.commit("setItems", []);
        this.$store.commit("setTotalResults", 0);
      }
      let url = window.location.href.replace("&reload=false", "");
      window.history.replaceState(null, null, url);
      // PINK PINK PINK PINK PINK PINK PINK PINK PINK PINK PINK PINK PINK
    } else if (this.hasFilters && this.currentItems.length) {
      console.log(
        `%c Not initial filter && no currentItems`,
        "font-size: 13px; background-color: pink; color: white; padding: 3px 5px;",
      );
      this.$store.commit("setFetchingData", false);
      this.$store.commit("setFilters", this.currentFilters);

      //  ELSE HAS FILTERS
    } else if (this.hasFilters) {
      console.log(
        `%c ELSE `,
        "font-size: 16px; background-color: #555555; color: white; padding: 3px 5px;",
      );
      this.$store.commit("setFetchingData", true);
    }
    return false;
  },
  updated() {
    let ref = window.location.href;
    this.curQuery = ref.substring(ref.indexOf("?") + 1);
  },
  beforeDestroy() {
    this.$root.$off("reset-filters", this.resetFilters);
    this.$root.$off("update-masonry", this.generateRandomKey);
    this.$root.$off("reset-results-count", this.resetResultsCount);
    this.$root.$off("sort-current-results", this.sortCurrentResults);
  },
  methods: {
    initComponent() {
      this.storeSubscription = this.$store.subscribe((mutation, curState) => {
        if (
          [
            "delFilterCategory",
            "delFilterKeyword",
            "delFilterLocation",
            "delFilterMerchant",
            "delFilterPriceRange",
            "delFilterSubLocation",
            "delQuality",
            "delSort",
            "resetFilters",
            "setFavorites",
            "setFetchingData",
            "setFilterCategory",
            "setFilterCategoryFromMenu",
            "setFilterDateEnd",
            "setFilterLocation",
            "setFilterLocationFromMenu",
            "setFilterMerchant",
            "setFilterPriceRange",
            "setFilterDateStart",
            "setFilterSubLocations",
            "setItemIds",
            "setKeyword",
            "setKeywordFromNavbar",
            "setCurrentLayout",
            "setLike",
            "setOffset",
            "setQuality",
            "setSortByArr",
            "setTotalResults",
          ].includes(mutation.type)
        ) {
          if (mutation.type !== "setOffset") {
            this.$store.commit("delOffset");
          }
          if (this.fetchingData === true) {
            if (!curState.offset.length && mutation.type !== "setLike") {
            }
            this.itemIds = [];
            this.fetchData(mutation, curState);
          }
        }
      });
    },
    fetchData(mutation, curState) {
      airtableQuery
        .createQuery(
          curState.filters,
          curState.offset,
          curState.authData,
          curState.sortByArr,
          this.curUserPerms.permittedArtInventoryFields,
        )
        .then((data) => {
          dom.removeHtmlClass("is-fixed");
          if (mutation.type === "setLike") {
            this.$store.commit("loadingFavorites", "");
          }
          this.prepareData(data, curState);
        })
        .catch((error) => {
          this.$store.commit("setFetchingData", false);
          console.log(
            `%c ${error}`,
            "font-size: 16px; background-color: red; color: white; padding: 3px 5px;",
          );
          this.$toasted.error(
            `There was a problem with the search query: (${error})`,
            {
              duration: 6000,
            },
          );
        });
    },
    prepareData(data, curState) {
      if (!this.fetchingData) {
        return false;
      }
      this.offsetData = data.data.offset;
      if (data) {
        let hasFilt = !_.isEqual(curState.filters, baseFilters);
        if (hasFilt) {
          let queryURL =
            window.location.pathname +
            "?" +
            this.serialize(curState.filters) +
            "&reload=true";
          window.history.replaceState(null, null, queryURL);
          this.$store.commit("setCurrentQueryURL", queryURL);
        }
        if (curState.offset === "") {
          this.currentItems = _.clone(data.data.records);
        } else {
          this.currentItems = [...this.currentItems, ...data.data.records];
        }
        if (this.isDetailsModalVisible) {
          this.detailsModalData = this.currentItems.filter(
            (e) => e.id === this.detailsModalData.id,
          )[0];
        }
      }
      this.resultsCount += data.data.records.length;
      if (this.offsetData) {
        this.$store.commit("setOffset", this.offsetData);
      } else {
        this.$store.commit("setFetchingData", false);
        this.currentItems.forEach((item) => {
          this.itemIds.push(item.id);
        });
        this.$store.commit("setOffset", "");
        this.$store.commit("setFilteredItemIds", this.itemIds);
        this.$store.commit("setTotalResults", this.resultsCount);
        this.showNoResultsAlert = !this.currentItems.length;
        this.showEmptyInventoryAlert = false;
        if (this.hasAskingPrice) {
          this.currentItems = this.currentItems.filter(
            (item) =>
              item.fields.AskingPrice !== "" &&
              item.fields.AskingPrice !== undefined &&
              item.fields.AskingPrice !== "0" &&
              item.fields.AskingPrice !== 0,
          );
          this.resultsCount = this.currentItems.length;
          this.$store.commit("setTotalResults", this.currentItems.length);
        }
        this.$store.commit("setItems", this.currentItems);
        return false;
      }
      return false;
    },
    submitFilters() {
      this.resetResultsCount();
      this.currentItems = [];
      this.$store.commit("setItems", []);
      this.$store.commit("setInitialPage", 1);
      this.$store.commit("setFetchingData", true);
    },
    submitKeyword() {
      this.resetResultsCount();
      this.currentItems = [];
      this.$store.commit("setItems", []);
      this.$store.commit("setInitialPage", 1);
      this.$store.commit("setFetchingData", true);
    },
    sortCurrentResults() {
      let fields = [];
      let directions = [];
      this.curSortByArr.forEach((obj) => {
        fields.push(obj.field);
        directions.push(obj.direction);
      });

      this.currentItems = _.orderBy(this.currentItems, fields, directions);

      this.itemIds = [];
      this.currentItems.forEach((item) => {
        this.itemIds.push(item.id);
      });
      this.$store.commit("setFilteredItemIds", this.itemIds);
      this.$store.commit("setInitialPage", 1);
      this.generateRandomKey();
    },
    updateItems(updatedItem) {
      this.currentItems.forEach((item) => {
        if (updatedItem.id === item.id) {
        }
      });
      this.$store.commit("setItems", this.currentItems);
    },
    resetResultsCount() {
      this.$store.commit("setTotalResults", 0);
      this.resultsCount = 0;
    },
    getInvoiceData(invoiceId, detailsModalData) {
      this.loadingInvoiceModalData = true;
      airtableClient
        .get(`Invoice%20No./${invoiceId}`)
        .then((invoiceData) => {
          this.prepareInvoiceModal(invoiceData, detailsModalData);
          this.loadingInvoiceModalData = false;
        })
        .catch((error) => {
          this.loadingInvoiceModalData = false;
          this.$toasted.error(error);
        });
    },
    serialize(obj) {
      let str = [];
      for (let p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    },
    closeModal(modalName) {
      this[modalName] = false;
      if (!this.isInvoiceModalVisible && !this.isInvoiceModalVisible) {
        dom.removeHtmlClass("has-modal");
        this.detailsModalZIndex = 3;
        this.invoiceModalZIndex = 3;
      }
    },
    prepareDetailsModal(data) {
      data.fields.Image = data.fields.Image.reverse();
      this.detailsModalData = data;
      this.isDetailsModalVisible = true;
      dom.addHtmlClass("has-modal");
      if (this.invoiceModalZIndex === this.detailsModalZIndex) {
        this.detailsModalZIndex += 1;
      } else if (this.invoiceModalZIndex > this.detailsModalZIndex) {
        let temp = this.detailsModalZIndex;
        this.detailsModalZIndex = this.invoiceModalZIndex;
        this.invoiceModalZIndex = temp;
      }
    },
    prepareInvoiceModal(invoiceData, detailsModalData) {
      this.invoiceModalData = invoiceData.data;
      this.invoiceModalDetailsData = detailsModalData;
      this.isInvoiceModalVisible = true;
      if (this.invoiceModalZIndex === this.detailsModalZIndex) {
        this.invoiceModalZIndex += 1;
      } else if (this.invoiceModalZIndex < this.detailsModalZIndex) {
        let temp = this.detailsModalZIndex;
        this.detailsModalZIndex = this.invoiceModalZIndex;
        this.invoiceModalZIndex = temp;
      }
    },
    generateRandomKey() {
      this.randomKey = Math.floor(Math.random() * 1000000000);
    },
    resetFilters() {
      console.log(
        `%c Reset Filters`,
        "font-size: 16px; background-color: teal; color: white; padding: 3px 5px;",
      );
      window.history.replaceState(null, null, window.location.pathname);
      this.showEmptyInventoryAlert = true;
      this.showNoResultsAlert = false;
      this.$store.commit("setFetchingData", false);
      this.$store.commit("setSortByArr", []);
      this.currentItems = {};
      this.resetResultsCount();
      this.$store.dispatch("resetFilters");
    },
  },
};
</script>
