<template>
  <dropdown-quality
    :has-checkbox="true"
    :quality-options="qualityOptions"
    @set-quality="handleSetQuality"
  />
</template>
<script>
import { mapState } from "vuex";
import DropdownQuality from "@/components/ui/DropdownQuality";

export default {
  name: "Qualities",
  components: {
    DropdownQuality,
  },
  data() {
    return {
      qualityOptions: [],
    };
  },
  computed: mapState(["filters", "qualities"]),
  async created() {
    await this.$store.dispatch("getQualities");
    this.qualities.forEach((item) => {
      this.qualityOptions.push(item.fields.Quality);
    });
  },
  methods: {
    handleSetQuality(quality) {
      if (this.filters) {
        this.filters.quality.includes(quality)
          ? this.$store.commit("delFilterQuality", quality)
          : this.$store.commit("setFilterQuality", quality);
      }
    },
  },
};
</script>
