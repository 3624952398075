<template>
  <div id="qrScannerWrap" class="scan-item">
    <qrcode-stream @decode="onDecode"></qrcode-stream>
    <div class="form-group text-center d-none">
      <qrcode-capture class="capture form-control-file"></qrcode-capture>
    </div>
    <qrcode-drop-zone class="dropzone d-none"></qrcode-drop-zone>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";

export default {
  name: "QRScanner",
  components: {
    QrcodeCapture,
    QrcodeDropZone,
    QrcodeStream,
  },
  computed: mapState(["networkBusyStatus"]),
  created() {
    this.onInit();
  },
  methods: {
    onDecode(decodedString) {
      this.$emit("on-scan", decodedString);
    },
    async onInit() {
      try {
        // successfully initialized
      } catch (error) {
        if (error.name === "NotAllowedError") {
          console.log("// user denied camera access permisson");
        } else if (error.name === "NotFoundError") {
          console.log("// no suitable camera device installed");
        } else if (error.name === "NotSupportedError") {
          console.log("// page is not served over HTTPS (or localhost)");
        } else if (error.name === "NotReadableError") {
          console.log("// maybe camera is already in use");
        } else if (error.name === "OverconstrainedError") {
          console.log(
            "// did you requested the front camera although there is none?",
          );
        } else if (error.name === "StreamApiNotSupportedError") {
          console.log("// browser seems to be lacking features");
        }
      } finally {
        // hide loading indicator
      }
    },
  },
};
</script>
