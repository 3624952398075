<template>
  <div class="controls">
    <a
      id="arrowLeft"
      class="slide-arrow disabled"
      @click="handleArrowClick('left')"
    >
      <ArrowLeft />
    </a>
    <a id="arrowRight" class="slide-arrow" @click="handleArrowClick('right')">
      <ArrowRight />
    </a>
  </div>
</template>
<script>
import ArrowLeft from "vue-material-design-icons/ArrowLeft.vue";
import ArrowRight from "vue-material-design-icons/ArrowRight.vue";

export default {
  name: "SliderControls",
  components: {
    ArrowLeft,
    ArrowRight,
  },
  methods: {
    handleArrowClick(dir) {
      this.$emit("move-slide", dir);
    },
  },
};
</script>
