<template>
  <main id="collectionsPage" aria-label="content">
    <section class="o-section__wrapper">
      <header class="page-header">
        <h2 class="c-section__title">Collections</h2>
      </header>
      <div v-if="collections.length" class="slick-container">
        <Collection
          v-for="collection in collections"
          :key="`collection_${collection.id}`"
          :collection="collection"
        />
      </div>
      <div v-else class="spinner-wrap">
        <Spinner />
      </div>
    </section>
  </main>
</template>
<script>
import Spinner from "@/components/ui/Spinner";
import Collection from "@/components/pages/Collection";
import { mapState } from "vuex";

export default {
  name: "CollectionsPage",
  components: {
    Collection,
    Spinner,
  },
  computed: {
    ...mapState(["networkBusyStatus", "collections"]),
  },
  async created() {
    await this.$store.dispatch("getCollections");
  },
};
</script>
