<template>
  <div>
    <div
      v-if="item.fields.Artist"
      class="layout-item-details-artist"
      @click="navigateToDetails"
    >
      <div class="c-link">
        {{ item.fields.Artist | sanitize | titlecase }}
      </div>
    </div>
    <div
      v-if="item.fields.TitleClean"
      class="c-works__detail__info"
      @click="navigateToDetails"
    >
      <div class="c-link">
        <h3 class="c-works__title">
          {{ item.fields.TitleClean | sanitize }}
        </h3>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../../router";
import { mapState } from "vuex";

export default {
  name: "ArtistTitle",
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    ...mapState(["authData", "userRoles", "curUserPerms"]),
  },
  methods: {
    navigateToDetails() {
      this.$store.commit("setCurrentRecordId", this.item.id);
      router.push({
        path: `/item/${this.item.id}`,
        query: {
          hasNav: true,
        },
      });
    },
  },
};
</script>
