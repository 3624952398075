<template>
  <div class="cleave">
    <div class="checkbox-wrap">
      <input
        :id="item.id"
        v-model="isItemForSale"
        type="checkbox"
        class="squarecheck"
        @change="handleIsForSale"
      />
      <label :for="item.id">For Sale</label>
    </div>
    <div v-if="isItemForSale" class="d-flex">
      <Cleave
        ref="askingInput"
        v-model.number="itemAskingPrice"
        :options="options"
        type="text"
        class="text-input asking-price"
      />
      <button
        v-if="!showRemovePriceButton"
        class="c-btn set-asking-price"
        @click="handleSetAskingPrice"
      >
        <span>Set</span>
      </button>
      <button
        v-if="showRemovePriceButton"
        class="c-btn set-asking-price remove"
        @click="handleRemoveAskingPrice"
      >
        <span>X</span>
      </button>
    </div>
  </div>
</template>
<script>
import Cleave from "vue-cleave-component";
import { mapState } from "vuex";
import airtableQuery from "@/services/airtableQuery.service";

export default {
  name: "AskingPrice",
  components: {
    Cleave,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isFavorite: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      oldPrice: undefined,
      newPrice: undefined,
      userEmail: "",
      itemAskingPrice: 0,
      isItemForSale: false,
      options: {
        numeral: true,
        numeralPositiveOnly: true,
        prefix: "$",
        rawValueTrimPrefix: true,
      },
    };
  },
  computed: {
    ...mapState(["authData"]),
    priceRemoved() {
      return (
        this.newPrice === undefined ||
        this.newPrice === null ||
        this.newPrice === 0 ||
        this.newPrice === ""
      );
    },
    showRemovePriceButton() {
      return (
        this.itemAskingPrice === this.newPrice &&
        this.newPrice === this.oldPrice
      );
    },
    setAskingPrice() {
      return (
        this.itemAskingPrice === undefined ||
        this.itemAskingPrice === null ||
        this.itemAskingPrice === "" ||
        this.itemAskingPrice === 0
      );
    },
  },
  created() {
    this.userEmail = this.authData.user_email;
    this.isItemForSale = this.item.fields.ForSale;
    this.itemAskingPrice = this.item.fields.AskingPrice;
    this.newPrice = this.item.fields.AskingPrice;
    this.oldPrice = this.item.fields.AskingPrice;
  },
  methods: {
    handleRemoveAskingPrice() {
      this.itemAskingPrice = null;
      this.handleSetAskingPrice();
    },
    // SET FOR SALE
    handleIsForSale() {
      this.$emit("on-set-for-sale", this.isItemForSale);
      if (!this.isFavorite) {
        this.$store.dispatch("updateItems", {
          id: this.item.id,
          fields: {
            ForSale: this.isItemForSale,
          },
        });
      }

      let payload = [
        {
          id: this.item.id,
          fields: {
            ForSale: this.isItemForSale,
            ForSaleSetByUser: this.userEmail,
          },
        },
      ];
      airtableQuery
        .updateRecordsAsync("Art Inventory", payload)
        .then((data, err) => {
          if (err) {
            this.$toasted.error(err, {
              className: "bg-danger",
              duration: 5000,
              position: "bottom-left",
            });
            return;
          }
          if (this.isItemForSale) {
            if (this.setAskingPrice) {
              this.$toasted.show("Item marked up for sale.", {
                className: "bg-success",
                icon: "check",
                duration: 5000,
                position: "bottom-left",
              });
              setTimeout(() => {
                this.$toasted.show("Set asking price!", {
                  className: "bg-danger",
                  icon: "add_alert",
                  duration: 5000,
                  position: "bottom-left",
                });
              }, 1000);
            } else {
              this.$toasted.show("Item marked up for sale.", {
                className: "bg-success",
                icon: "check",
                duration: 5000,
                position: "bottom-left",
              });
            }
          } else {
            this.$toasted.show("Item not for sale.", {
              className: "bg-dark",
              icon: "close",
              duration: 5000,
              position: "bottom-left",
            });
          }
        });
    },
    // SET ASKING PRICE
    handleSetAskingPrice() {
      this.$emit("on-set-asking-price", this.itemAskingPrice);
      if (!this.isFavorite) {
        this.$store.dispatch("updateItems", {
          id: this.item.id,
          fields: {
            AskingPrice: this.itemAskingPrice,
          },
        });
      }
      this.newPrice = this.itemAskingPrice;
      if (this.newPrice !== this.oldPrice) {
        let submittedPrice;
        this.oldPrice = this.newPrice;
        if (this.priceRemoved) {
          submittedPrice = null;
          this.itemAskingPrice = null;
        } else {
          submittedPrice = this.itemAskingPrice;
        }
        this.$emit("on-set-for-sale", this.isItemForSale, this.itemAskingPrice);
        let payload = [
          {
            id: this.item.id,
            fields: {
              AskingPrice: submittedPrice,
              AskingPriceSetByUser: this.userEmail,
            },
          },
        ];
        airtableQuery
          .updateRecordsAsync("Art Inventory", payload)
          .then((data, err) => {
            if (err) {
              this.$toasted.error(err, {
                className: "bg-danger",
                duration: 5000,
                position: "bottom-left",
              });
              return;
            }
            if (this.itemAskingPrice) {
              this.$toasted.show("Asking price set", {
                className: "bg-success",
                icon: "paid",
                duration: 5000,
                position: "bottom-left",
              });
            } else {
              this.$toasted.show("Asking price removed", {
                className: "",
                icon: "remove_circle_outline",
                duration: 5000,
                position: "bottom-left",
              });
            }
          });
      }
    },
  },
};
</script>
