<template>
  <!-- add a loading state by loadingFavorites -->
  <button
    class="c-btn btn-favorite"
    :class="{ active: isFavorited }"
    :disabled="isLoading"
    aria-label="Like"
    title="Like"
    @click.stop.prevent="handleToggleFavorite()"
  >
    <span class="icon">
      <svg width="16" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.682 1.318a4.5 4.5 0 00-6.364 0c-.121.12-.214.259-.318.389-.104-.13-.197-.268-.318-.39a4.5 4.5 0 00-6.364 6.365L8 14l6.682-6.318a4.5 4.5 0 000-6.364z"
        />
      </svg>
    </span>
    <span v-if="!isFavorited" class="btn-text">FAVORITE</span>
    <span v-else class="btn-text">FAVORITED</span>
  </button>
</template>
<script>
import airtableQuery from "../../../services/airtableQuery.service";
import { mapState } from "vuex";

export default {
  name: "BtnLike",
  props: {
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      curUserFavorites: [],
      curUserFavoriteItemIds: [],
    };
  },
  computed: {
    ...mapState([
      "currentLayout",
      "curUserData",
      "filters",
      "loadingFavorites",
      "userFavoriteItemIds",
      "userFavorites",
    ]),
    isFavorited() {
      return this.curUserFavoriteItemIds.includes(this.record.id);
    },
  },
  watch: {
    userFavoriteItemIds: {
      handler(newVal) {
        this.curUserFavoriteItemIds = [...newVal];
      },
    },
  },
  created() {
    this.curUserFavorites = [...this.userFavorites];
    this.curUserFavoriteItemIds = [...this.userFavoriteItemIds];
  },
  methods: {
    handleToggleFavorite() {
      if (this.curUserFavoriteItemIds.includes(this.record.id)) {
        const deletedIndex = this.curUserFavorites.findIndex(
          (e) => e.id === this.record.id,
        );
        this.curUserFavorites.splice(deletedIndex, 1);
        this.curUserFavoriteItemIds = [...this.curUserFavoriteItemIds].filter(
          (id) => id !== this.record.id,
        );
        this.$store.commit("setUserFavorites", this.curUserFavorites);
        this.$store.commit("removeUserFavoriteItemId", this.record.id);
      } else {
        this.curUserFavorites.push(this.record);
        this.curUserFavoriteItemIds.push(this.record.id);
        this.$store.commit("setUserFavorites", this.curUserFavorites);
        this.$store.commit("addUserFavoriteItemId", this.record.id);
      }
      airtableQuery.updateRecordsAsync("Users", [
        {
          id: this.curUserData.id,
          fields: { Favorites: this.curUserFavoriteItemIds },
        },
      ]);
    },
  },
};
</script>
