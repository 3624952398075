import { baseFilters, initialState } from "@/constants";

import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import airtableQuery from "../services/airtableQuery.service";
import axiosClient from "../services/axiosClient.service";
import createPersistedState from "vuex-persistedstate";
import router from "../router/index";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "CycadVuex",
      paths: [
        "authData",
        "compareList",
        "curRoomName",
        "curScanRoom",
        "curScanRoomItems",
        "curScanRoomScannedItemIds",
        "curScanRoomUnscannedItemIds",
        "curUserData",
        "curUserPerms",
        "currentLayout",
        "currentQueryURL",
        "currentRecordId",
        "currentRecordIndex",
        "filteredItemIds",
        "filters",
        "hasAskingPrice",
        "qualities",
        "previousRoomRecordIds",
        "shoppingCart",
        "shoppingCartItemIds",
        "sortByArr",
        "transferCartItems",
        "transferCartItemIds",
        "transferOrder",
        "userFavoriteItemIds",
        "userRoles",
      ],
    }),
  ],
  state: initialState,
  mutations: {
    // reset state
    resetState(state) {
      localStorage.clear();
      Object.assign(state, initialState);
    },
    // login
    loginStart(state) {
      state.loggingIn = true;
      state.loginError = "";
    },
    loginStop(state, errorMessage) {
      state.loggingIn = false;
      state.loginError = errorMessage;
    },
    setLoggingIn(state, payload) {
      state.loggingIn = payload;
    },
    setAuthData(state, payload) {
      state.authData = payload;
    },
    setcurUserData(state, payload) {
      console.log(
        `%c SET curUserData`,
        "font-size: 13px; background-color: purple; color: white; padding: 3px 12px 3px 7px;",
        payload,
      );
      state.curUserData = { ...payload };
    },
    setShoppingCartItemIds(state, payload) {
      state.shoppingCartItemIds = [...payload];
    },
    setTransferCartItemIds(state, payload) {
      state.transferCartItemIds = [...payload];
    },
    setUserFavoriteItemIds(state, payload) {
      state.userFavoriteItemIds = [...payload];
    },
    // set roles
    setCurUserPerms(state, role) {
      console.log(
        `%c SET PERMS`,
        "font-size: 13px; background-color: blue; color: white; padding: 3px 12px 3px 7px;",
      );
      state.curUserPerms.canBuy = role.fields.CanBuy;
      state.curUserPerms.canEditNotes = role.fields.CanAddEditNotes;
      state.curUserPerms.canSetAskingPrice = role.fields.CanSetAskingPrice;
      state.curUserPerms.canSetQuality = role.fields.CanSetQuality;
      state.curUserPerms.canTransfer = role.fields.CanTransfer;
      state.curUserPerms.canViewCollectionCount =
        role.fields.CanViewCollectionCount;
      state.curUserPerms.permittedArtInventoryFields =
        role.fields.PermittedArtInventoryFields || [];
      state.curUserPerms.permittedCategories =
        role.fields.PermittedCategories || [];
      state.curUserPerms.permittedCollections =
        role.fields.PermittedCollections || [];
      state.curUserPerms.permittedFilters = role.fields.PermittedFilters || [];
      state.curUserPerms.permittedLocationFields =
        role.fields.PermittedLocationFields || [];
      state.curUserPerms.permittedLocations =
        role.fields.PermittedLocations || [];
      state.curUserPerms.permittedMerchants =
        role.fields.PermittedMerchants || [];
      state.curUserPerms.permittedPages = role.fields.PermittedPages || [];
      state.curUserPerms.permittedSorting = role.fields.PermittedSorting || [];
    },
    clearLoginError(state) {
      state.loginError = "";
    },
    // role related
    setPermittedArtInventoryFields(state, fields) {
      state.permittedArtInventoryFields = fields;
    },
    setPermittedLocationFields(state, fields) {
      state.permittedLocationFields = fields;
    },
    setPermittedCategories(state, payload) {
      state.permittedCategories = payload;
    },
    setPermittedCollections(state, payload) {
      state.permittedCollections = payload;
    },
    setPermittedLocations(state, payload) {
      state.permittedLocations = payload;
    },
    setPermittedMerchants(state, payload) {
      state.permittedMerchants = payload;
    },
    setPermittedFilters(state, payload) {
      state.permittedFilters = payload;
    },
    setPermittedPages(state, payload) {
      state.permittedPages = payload;
    },
    setPermittedSorting(state, payload) {
      state.permittedSorting = payload;
    },
    setCurItemDetailRecord(state, payload) {
      state.curItemDetailRecord = payload;
    },
    // transfer cart ids
    addTransferCartItemId: (state, payload) => {
      state.transferCartItemIds = [
        ...new Set([...state.transferCartItemIds, payload]),
      ];
    },
    removeTransferCartItemId: (state, payload) => {
      state.transferCartItemIds = [
        ...state.transferCartItemIds.filter((id) => id !== payload),
      ];
    },
    // transfer cart
    addTransferCartItem: (state, payload) => {
      state.transferCartItems = [
        ...new Set([...state.transferCartItems, payload]),
      ];
    },
    removeTransferCartItem: (state, deleteId) => {
      state.transferCartItems = [
        ...state.transferCartItems.filter((item) => item.id !== deleteId),
      ];
    },
    setTransferCartItems(state, payload) {
      state.transferCartItems = payload;
    },
    // transfer order
    setTransferOrder(state, payload) {
      state.transferOrder = [...payload];
    },
    // transfer order
    setCompareList(state, payload) {
      state.compareList = [...payload];
    },
    // shopping cart ids
    addShoppingCartItemId: (state, payload) => {
      state.shoppingCartItemIds = [
        ...new Set([...state.shoppingCartItemIds, payload]),
      ];
    },
    removeShoppingCartItemId: (state, deleteId) => {
      state.shoppingCartItemIds = [
        ...state.shoppingCartItemIds.filter((id) => id !== deleteId),
      ];
    },
    // shopping cart
    addShoppingCartItem: (state, payload) => {
      state.shoppingCart = [...new Set([...state.shoppingCart, payload])];
    },
    removeShoppingCartItem: (state, deleteId) => {
      state.shoppingCart = [
        ...state.shoppingCart.filter((item) => item.id !== deleteId),
      ];
    },
    setShoppingCart(state, payload) {
      state.shoppingCart = payload;
    },
    setShoppingCartTotal(state, payload) {
      state.shoppingCartTotal = payload;
    },
    // favoriteIds
    addUserFavoriteItemId: (state, payload) => {
      state.userFavoriteItemIds.push(payload);
    },
    removeUserFavoriteItemId: (state, payload) => {
      state.userFavoriteItemIds = state.userFavoriteItemIds.filter(
        (id) => id !== payload,
      );
    },
    // favorites array
    addUserFavoriteItem: (state, payload) => {
      state.userFavorites = [...new Set([...state.userFavorites, payload])];
    },
    removeUserFavoriteItem: (state, deleteId) => {
      state.userFavorites = [
        ...state.userFavorites.filter((item) => item.id !== deleteId),
      ];
    },
    setUserFavorites: (state, payload) => {
      state.userFavorites = payload;
    },
    // tables
    setCategories(state, categories) {
      state.categories = categories;
    },
    setLocations(state, locations) {
      state.locations = locations;
    },
    setMerchants(state, merchants) {
      state.merchants = merchants;
    },
    setCollections(state, collections) {
      state.collections = collections;
    },
    setQualities(state, qualities) {
      state.qualities = qualities;
    },
    setSubLocations(state, sublocations) {
      state.sublocations = sublocations;
    },
    setPreviousRoomScans(state, payload) {
      state.previousRoomScans = payload;
    },
    setPreviousScanRecordIds(state, payload) {
      state.previousRoomRecordIds = payload;
    },
    updatePreviousRoomScans(state, payload) {
      state.previousRoomScans = state.previousRoomScans.map(
        (room) => [payload].find((o) => o.id === room.id) || room,
      );
    },
    // single item page
    setCurrentRecordId(state, payload) {
      state.currentRecordId = payload;
    },
    setCurrentQueryURL(state, payload) {
      state.currentQueryURL = payload;
    },
    setFavorites(state, favorite) {
      state.filters.favorites = favorite;
      state.filters.locations = [];
      state.filters.categories = [];
      state.filters.quality = [];
      state.filters.keyword = "";
    },
    // airtable
    setFetchingData(state, value) {
      state.fetchingData = value;
    },
    setFilterByFormula(state, payload) {
      state.setFilterByFormula = payload;
    },
    setHasAskingPrice(state, payload) {
      state.hasAskingPrice = payload;
    },
    // filter category
    setFilterCategory(state, category) {
      state.filters.categories = [
        ...new Set([...state.filters.categories, category]),
      ];
    },
    delFilterCategory(state, category) {
      state.filters.categories = [
        ...state.filters.categories.filter((item) => item !== category),
      ];
    },
    // filter location
    setFilterLocation(state, loc) {
      state.filters.locations = [...new Set([...state.filters.locations, loc])];
      state.filterByFormula += `SEARCH("${loc
        .toLowerCase()
        .trim()}", LOWER({LocationName}))`;
    },
    delFilterLocation(state, loc) {
      state.filters.locations = [
        ...state.filters.locations.filter((item) => item !== loc),
      ];
      state.filterByFormula = state.filterByFormula.replace(
        `SEARCH({ LocationName }, "${loc}"),`,
        "",
      );
    },
    // filter sublocation
    setFilterSubLocation(state, subLoc) {
      state.filters.sublocations = [
        ...new Set([...state.filters.sublocations, subLoc]),
      ];
      state.filterByFormula += `SEARCH({SubLocationName}, "${subLoc}"),`;
    },
    delFilterSubLocation(state, subLoc) {
      state.filters.sublocations = [
        ...state.filters.sublocations.filter((item) => item !== subLoc),
      ];
      state.filterByFormula = state.filterByFormula.replace(
        `SEARCH({SubLocationName}, "${subLoc}"),`,
        "",
      );
    },
    // filter collection
    setFilterCollection(state, col) {
      state.filters.collections = [
        ...new Set([...state.filters.collections, col]),
      ];
    },
    delFilterCollection(state, col) {
      state.filters.collections = [
        ...state.filters.collections.filter((item) => item !== col),
      ];
    },
    // filter date start
    setFilterDateStart(state, date) {
      state.filters.dateStart = date;
    },
    delFilterDateStart(state) {
      state.filters.dateStart = "";
    },
    // filter date end
    setFilterDateEnd(state, date) {
      state.filters.dateEnd = date;
    },
    delFilterDateEnd(state) {
      state.filters.dateEnd = "";
    },
    // filter keyword
    setFilterKeyword(state, keyword) {
      state.filters.keyword = keyword;
    },
    delFilterKeyword(state) {
      state.filters.keyword = "";
    },
    setFilterMerchant(state, merch) {
      state.filters.merchants = [
        ...new Set([...state.filters.merchants, merch]),
      ];
    },
    delFilterMerchant(state, merch) {
      state.filters.merchants = state.filters.merchants.filter(
        (item) => item !== merch,
      );
    },
    setFilterForSale(state, payload) {
      state.filters.forSale = payload;
    },
    // filter length range
    setFilterLengthRange(state, lengthRange) {
      state.filters.minLength = lengthRange[0];
      state.filters.maxLength = lengthRange[1];
    },
    delFilterLengthRange(state) {
      state.filters.minLength = baseFilters.minLength;
      state.filters.maxLength = baseFilters.maxLength;
    },
    // filter width range
    setFilterWidthRange(state, widthRange) {
      state.filters.minWidth = widthRange[0];
      state.filters.maxWidth = widthRange[1];
    },
    delFilterWidthRange(state) {
      state.filters.minWidth = baseFilters.minWidth;
      state.filters.maxWidth = baseFilters.maxWidth;
    },
    // filter price range
    setFilterPriceRange(state, price) {
      state.filters.minPrice = price[0];
      state.filters.maxPrice = price[1];
    },
    delFilterPriceRange(state) {
      state.filters.minPrice = baseFilters.minPrice;
      state.filters.maxPrice = baseFilters.maxPrice;
    },
    // filter has price range
    setFilterHasPriceRange(state, payload) {
      state.filters.hasPriceRange = payload;
    },
    // filter no value
    setFilterHasNoValue(state, payload) {
      state.filters.hasNoValue = payload;
      if (payload === true || payload === "true") {
        state.filters.minPrice = 0;
        state.filters.maxPrice = 5000000;
      }
    },
    // filter no value
    setExcludeItemsWithNoValue(state, payload) {
      state.filters.excludeItemsWithNoValue = payload;
      if (payload === true || payload === "true") {
        state.filters.minPrice = 0;
        state.filters.maxPrice = 5000000;
      }
    },
    // set filter units
    setFilterUnits(state, units) {
      state.filters.units = units;
    },
    // set filters from menu
    setFilterKeywordFromNavbar(state, keyword) {
      state.filters = _.clone(baseFilters);
      state.filters.keyword = keyword;
    },
    setFilterCategoryFromMenu(state, category) {
      state.filters = _.clone(baseFilters);
      state.filters.categories = [category];
    },
    setFilterCollectionFromMenu(state, collection) {
      state.filters = _.clone(baseFilters);
      state.filters.collections = [collection];
    },
    setFilterLocationFromMenu(state, location) {
      state.filters = _.clone(baseFilters);
      state.filters.locations = [location];
    },
    setFilterMerchantFromMenu(state, merchant) {
      state.filters = _.clone(baseFilters);
      state.filters.merchants = [merchant];
    },

    // filter quality
    setFilterQuality(state, quality) {
      state.filters.quality = [...new Set([...state.filters.quality, quality])];
    },
    delFilterQuality(state, quality) {
      state.filters.quality = state.filters.quality.filter(
        (item) => item !== quality,
      );
    },
    // all sublocations
    setAllSublocations(state, sublocations) {
      let all = state.filters.sublocations;
      state.filters.sublocations = all.concat(sublocations);
    },
    delAllSublocations(state, subLocations) {
      state.filters.sublocations = [
        ...state.filters.sublocations.filter(function (el) {
          return subLocations.indexOf(el) < 0;
        }),
      ];
    },
    // filtered item ids
    setFilteredItemIds(state, itemIds) {
      state.filteredItemIds = itemIds;
    },
    // filters
    setFilters(state, filters) {
      filters.categories = filters.categories
        ? filters.categories.includes(",")
          ? filters.categories.split(",")
          : filters.categories.length
          ? Array.isArray(filters.categories)
            ? filters.categories
            : new Array(filters.categories)
          : []
        : [];
      filters.collections = filters.collections
        ? filters.collections.includes(",")
          ? filters.collections.split(",")
          : filters.collections.length
          ? Array.isArray(filters.collections)
            ? filters.collections
            : new Array(filters.collections)
          : []
        : [];
      filters.locations = filters.locations
        ? filters.locations.includes(",")
          ? filters.locations.split(",")
          : filters.locations.length
          ? Array.isArray(filters.locations)
            ? filters.locations
            : new Array(filters.locations)
          : []
        : [];
      filters.sublocations = filters.sublocations
        ? filters.sublocations.includes(",")
          ? filters.sublocations.split(",")
          : filters.sublocations.length
          ? Array.isArray(filters.sublocations)
            ? filters.sublocations
            : new Array(filters.sublocations)
          : []
        : [];
      filters.merchants = filters.merchants
        ? filters.merchants.includes(",")
          ? filters.merchants.split(",")
          : filters.merchants.length
          ? Array.isArray(filters.merchants)
            ? filters.merchants
            : new Array(filters.merchants)
          : []
        : [];
      filters.quality = filters.quality
        ? filters.quality.includes(",")
          ? filters.quality.split(",")
            ? Array.isArray(filters.quality)
            : filters.quality.length
            ? filters.quality
            : new Array(filters.quality)
          : []
        : [];
      filters.minPrice = filters.minPrice ? filters.minPrice : 0;
      filters.maxPrice = filters.maxPrice ? filters.maxPrice : 5000000;
      filters.minLength = filters.minLength ? filters.minLength : 0;
      filters.maxLength = filters.maxLength ? filters.maxLength : 500;
      filters.minWidth = filters.minWidth ? filters.minWidth : 0;
      filters.maxWidth = filters.maxWidth ? filters.maxWidth : 500;
      filters.itemIds = filters.itemIds ? filters.itemIds : "";
      state.filters = filters;
    },
    setInitialPage(state, page) {
      state.initialPage = page;
    },
    setItemIds(state, itemIds) {
      state.filters.itemIds = itemIds;
    },
    setItems(state, items) {
      state.items = _.clone(items);
    },
    setCurrentLayout(state, layout) {
      state.currentLayout = layout;
    },
    setLike(state, layout) {
      state.currentLayout = layout;
    },

    setNetworkBusyStatus(state, networkStatus) {
      state.networkBusyStatus = networkStatus;
    },
    setOffset(state, offset) {
      state.offset = offset;
    },
    setScrollOffset(state, offset) {
      state.scrollOffset = offset;
    },
    setSortDirection(state, direction) {
      state.sortDirection = direction;
    },
    setSortByArr(state, payload) {
      state.sortByArr = [...payload];
    },
    setTotalResults(state, payload) {
      state.totalResults = payload;
    },
    clearTransferCart(state) {
      state.transferCartItemIds = [];
    },
    loadingFavorites(state, payload) {
      state.loadingFavorites = payload;
    },
    clearFilters(state) {
      state.filters = {
        categories: [],
        collections: [],
        dateEnd: "",
        dateStart: "",
        excludeItemsWithNoValue: false,
        favorites: "",
        forSale: false,
        hasNoValue: false,
        hasPriceRange: false,
        itemIds: "",
        keyword: "",
        locations: [],
        maxLength: 500,
        maxPrice: 5000000,
        maxWidth: 500,
        merchants: [],
        minLength: 0,
        minPrice: 0,
        minWidth: 0,
        quality: [],
        sublocations: [],
        units: "IN",
      };
    },
    setAirTableResTime(state, airTableResTime) {
      state.airTableResTime = airTableResTime;
    },
    setCurScanRoom(state, payload) {
      // {obj}
      state.curScanRoom = { ...payload };
    },
    setCurRoomName(state, payload) {
      state.curRoomName = payload;
    },
    setCurScanRoomItems(state, payload) {
      state.curScanRoomItems = payload;
    },
    setCurScanRoomScannedItemIds(state, payload) {
      state.curScanRoomScannedItemIds = payload;
    },
    setCurScanRoomUnscannedItemIds(state, payload) {
      state.curScanRoomUnscannedItemIds = payload;
    },
    delOffset(state) {
      state.offset = "";
    },
    delLoggedInUserData(state) {
      state.authData = {};
      localStorage.removeItem("authData");
    },
  },
  getters: {
    getTransferCartItemIds: (state) => {
      return state.transferCartItemIds;
    },
    getTransferOrder: (state) => {
      return state.transferOrder;
    },
    getCategories: (state) => {
      return state.categories;
    },
    getPermittedCategories: (state) => {
      return state.permittedCategories;
    },
    getPermittedFilters: (state) => {
      return state.permittedFilters;
    },
    getCategoriesName: (state) => {
      return state.categories.category_name;
    },
    getCurrentLayout: (state) => {
      return state.currentLayout;
    },
    getFilters: (state) => {
      return state.filters;
    },
    getItems: (state) => {
      return state.items;
    },
    getUnits: (state) => {
      return state.filters.units;
    },
    getLengthRange: (state) => {
      return [state.filters.minLength, state.filters.maxLength];
    },
    getWidthRange: (state) => {
      return [state.filters.minWidth, state.filters.maxWidth];
    },
    getLocations: (state) => {
      return state.locations.length;
    },
    getMerchants: (state) => {
      return state.merchants.length;
    },
    getPriceRange: (state) => {
      return [state.filters.minPrice, state.filters.maxPrice];
    },
    getHasNoValue: (state) => {
      return state.filters.hasNoValue;
    },
    getQualities: (state) => {
      return state.qualities.length;
    },
    getSortDirection: (state) => {
      return state.sortDirection;
    },
    getSorts: (state) => {
      return state.sortByArr;
    },
    getSubLocations: (state) => {
      return state.sublocations.length;
    },
    getLoggedInUserData: (state) => {
      return state.authData;
    },
    getUserEmail: (state) => {
      return state.authData.user_email;
    },
  },
  /**
   
   ACTIONS
   
   */
  actions: {
    // LOG IN ***********************************************************************
    async doLogin({ commit, dispatch }, payload) {
      commit("loginStart");
      await axiosClient
        .post("/jwt-auth/v1/token", {
          username: payload.username,
          password: payload.password,
        })
        .then((response) => {
          commit("setLoggingIn", false);
          commit("setFetchingData", false);
          commit("setAuthData", response.data);
          commit("loginStop", null);
          dispatch("initializeApp");
        })
        .catch((error) => {
          console.error(error.response.data.message);
          commit("setFetchingData", false);
          commit("setNetworkBusyStatus", false);
          commit("loginStop", error.response.data.message);
          commit("delLoggedInUserData");
        });
    },
    // LOG OUT ***********************************************************************
    logout({ commit }) {
      commit("resetState");
      router.push({ name: "AppLogin" });
      localStorage.removeItem("CycadVuex");
    },
    // init **************************************************************************
    // init **************************************************************************
    // init **************************************************************************
    // init **************************************************************************
    async initializeApp({ commit, dispatch }) {
      await dispatch("getUserRoles");
      await dispatch("getUsers");
      console.log(
        `%c INITIALIZE APP`,
        "font-size: 16px; background-color: #0c0; color: black; padding: 3px 12px 3px 7px;",
      );

      commit("setTotalResults", 0);
      commit("setCurrentLayout", "masonry-small");
      dispatch("resetFilters");
      if (router.history.current.name !== "ArtInventoryPage") {
        router.push({ name: "ArtInventoryPage" });
      }
    },
    resetStore({ commit, dispatch }) {
      commit("clearLoginError");
      commit("delLoggedInUserData");
      commit("clearTransferCart");
      commit("setItems", []);
      commit("setShoppingCart", []);
      commit("setTransferCart", []);
      commit("setFavorites", []);
      commit("setItems", []);
      commit("setFilters", {});
      dispatch("resetFilters");
    },
    // get roles
    async getUserRoles({ commit, state }) {
      console.log(
        `%c GET USER ROLES`,
        "font-size: 13px; background-color: blue; color: white; padding: 3px 12px 3px 7px;",
      );
      const payload = await airtableQuery.getTableAsync(
        "User Roles",
        500,
        "administrator",
      );
      let stop = false;
      payload.forEach((role) => {
        if (stop) {
          return;
        }
        if (role.fields.UserRoleName === state.authData.user_role) {
          console.log("role", role);
          commit("setCurUserPerms", role);
          stop = true;
        }
      });
    },
    // get users
    async getUsers({ commit, state, dispatch }) {
      console.log(
        `%c GET USERS`,
        "font-size: 13px; background-color: purple; color: white; padding: 3px 12px 3px 7px;",
      );
      const payload = await airtableQuery.getTableAsync(
        "Users",
        500,
        "administrator",
        "",
        [
          "Avatar",
          "Email",
          "FavoriteItemIds",
          "FirstName",
          "FullName",
          "LastName",
          "MobilePhone",
          "ShoppingCartItemIds",
          "TransferCartItemIds",
          "username",
        ],
      );
      let stop = false;
      payload.forEach((user) => {
        if (stop) {
          return;
        }
        if (user.fields.Email === state.authData.user_email) {
          commit("setcurUserData", user);
          commit(
            "setShoppingCartItemIds",
            user.fields.ShoppingCartItemIds || [],
          );
          commit(
            "setTransferCartItemIds",
            user.fields.TransferCartItemIds || [],
          );
          commit("setUserFavoriteItemIds", user.fields.FavoriteItemIds || []);
          dispatch("getShoppingCart");
          dispatch("getTransferCart");
          dispatch("getUserFavorites");
          dispatch("getPreviousRoomScans");
          stop = true;
        }
      });
    },
    async getcurUserData({ state, commit }) {
      const curUserData = await airtableQuery.getRecordAsync(
        "Users",
        state.curUserData.id,
      );
      commit("setcurUserData", curUserData);
      commit(
        "setTransferCartItemIds",
        curUserData.fields.TransferCartItemIds || [],
      );
      commit(
        "setShoppingCartItemIds",
        curUserData.fields.ShoppingCartItemIds || [],
      );
      commit(
        "setUserFavoriteItemIds",
        curUserData.fields.FavoriteItemIds || [],
      );
    },

    // get shopping cart
    async getShoppingCart({ state, commit }) {
      if (state.shoppingCartItemIds.length) {
        let filterByFormulaIdQuery = "OR(";
        state.shoppingCartItemIds.forEach((id) => {
          filterByFormulaIdQuery += `{Item ID}="${id}",`;
        });
        filterByFormulaIdQuery = filterByFormulaIdQuery.slice(0, -1);
        filterByFormulaIdQuery += ")";
        const payload = await airtableQuery.getTableAsync(
          "Art Inventory",
          500,
          "administrator",
          filterByFormulaIdQuery,
          ["TitleClean", "Image", "AskingPrice"],
        );
        commit("setShoppingCart", payload);
        let total = 0;
        payload.forEach((item) => {
          total += item.fields.AskingPrice;
        });
        commit("setShoppingCartTotal", total);
      } else {
        commit("setShoppingCart", []);
      }
    },
    // get transfer cart
    async getTransferCart({ state, commit }) {
      if (state.transferCartItemIds.length) {
        let filterByFormulaIdQuery = "OR(";
        state.transferCartItemIds.forEach((id) => {
          filterByFormulaIdQuery += `{Item ID}="${id}",`;
        });
        filterByFormulaIdQuery = filterByFormulaIdQuery.slice(0, -1);
        filterByFormulaIdQuery += ")";
        const payload = await airtableQuery.getTableAsync(
          "Art Inventory",
          500,
          "administrator",
          filterByFormulaIdQuery,
          ["TitleClean", "Image", "LocationName", "Location", "Item ID"],
        );
        commit("setTransferCartItems", [...payload]);
      } else {
        commit("setTransferCartItems", []);
      }
    },
    // user favorites
    async getUserFavorites({ state, commit }) {
      if (state.userFavoriteItemIds.length) {
        let filterByFormulaIdQuery = "OR(";
        state.userFavoriteItemIds.forEach((id) => {
          filterByFormulaIdQuery += `{Item ID}="${id}",`;
        });
        filterByFormulaIdQuery = filterByFormulaIdQuery.slice(0, -1);
        filterByFormulaIdQuery += ")";
        const payload = await airtableQuery.getTableAsync(
          "Art Inventory",
          500,
          "administrator",
          filterByFormulaIdQuery,
          ["TitleClean", "Image", "LocationName", "Location", "Item ID"],
        );
        commit("setUserFavorites", [...payload]);
      } else {
        commit("setUserFavorites", []);
      }
    },
    // get previous room scans
    async getPreviousRoomScans({ state, commit }) {
      const payload = await airtableQuery.getTableAsync(
        "Room Scans",
        500,
        "administrator",
      );
      let curUserRooms = payload.filter((roomScan) => {
        return roomScan.fields.UserEmail[0] === state.authData.user_email;
      });
      let previousRoomRecordIds = [];
      curUserRooms.forEach((room) => {
        previousRoomRecordIds.push(room.id);
      });
      commit("setPreviousRoomScans", curUserRooms);
      commit("setPreviousScanRecordIds", previousRoomRecordIds);
    },

    // post init *******************************************************************************************

    async getQualities({ commit }) {
      const payload = await airtableQuery.getTableAsync(
        "Qualities",
        100,
        "administrator",
        "",
        ["Quality", "CollectionCount"],
      );
      let sortedQualities = payload.sort((a, b) => {
        return a.fields.Quality > b.fields.Quality ? 1 : -1;
      });
      commit("setQualities", sortedQualities);
    },
    async getLocations({ commit }) {
      const payload = await airtableQuery.getTableAsync(
        "Locations",
        500,
        "administrator",
        "",
        ["Location", "CollectionCount", "Attachments", "RecordId"],
      );
      let sortedLocations = payload.sort((a, b) => {
        return a.fields.Location > b.fields.Location ? 1 : -1;
      });
      commit("setLocations", sortedLocations);
    },
    async getSubLocations({ commit }) {
      const payload = await airtableQuery.getTableAsync(
        "SubLocations",
        800,
        "administrator",
        "",
        [
          "Collection",
          "CollectionCount",
          "ParentLocation",
          "ParentLocationId",
          "RecordId",
          "SubLocationName",
          "SubLocationURL",
        ],
      );
      let sortedSubLocations = payload.sort((a, b) => {
        return a.fields.SubLocationName > b.fields.SubLocationName ? 1 : -1;
      });
      commit("setSubLocations", sortedSubLocations);
    },
    async getCategories({ commit }) {
      const payload = await airtableQuery.getTableAsync(
        "Categories",
        500,
        "administrator",
        "",
        ["Category", "CollectionCount", "Attachments"],
      );
      let sortedCategories = payload.sort((a, b) => {
        return a.fields.Category > b.fields.Category ? 1 : -1;
      });
      commit("setCategories", sortedCategories);
    },
    async getCollections({ commit }) {
      const payload = await airtableQuery.getTableAsync(
        "Collections",
        500,
        "administrator",
        "",
        ["CollectionName", "CollectionCount"],
      );
      let sortedCollections = payload.sort((a, b) => {
        return a.fields.CollectionName > b.fields.CollectionName ? 1 : -1;
      });
      commit("setCollections", sortedCollections);
    },
    async getMerchants({ commit }) {
      const payload = await airtableQuery.getTableAsync(
        "Merchants",
        500,
        "administrator",
        "",
        ["Merchant", "CollectionCount", "Logo"],
      );
      let sortedMerchants = payload.sort((a, b) => {
        return a.fields.Merchant > b.fields.Merchant ? 1 : -1;
      });
      commit("setMerchants", sortedMerchants);
    },
    setOffset({ commit }, offset) {
      commit("setOffset", offset);
    },
    updateItems({ state, commit }, payload) {
      if (state.items.length) {
        let updatedArray = _.clone(state.items);
        const index = updatedArray.findIndex((item) => item.id === payload.id);
        Object.assign(updatedArray[index].fields, payload.fields);
        commit("setItems", updatedArray);
      }
    },
    deleteItemById({ state, commit }, payload) {
      let updatedArray = _.clone(state.items);
      for (let i = 0; i < updatedArray.length; i += 1) {
        payload.forEach((itemId) => {
          if (updatedArray[i].id === itemId) {
            updatedArray.splice(i, 1);
          }
        });
      }
      commit("setItems", updatedArray);
    },
    async getCurrentItemDetailRecord({ state, commit }, recordId) {
      let filterByFormulaIdQuery = `OR({Item ID}="${recordId}", {Old Item ID}="${recordId}")`;
      let role = state.authData.user_role;
      let fields = state.curUserPerms.permittedArtInventoryFields.includes(
        "All",
      )
        ? []
        : state.curUserPerms.permittedArtInventoryFields;
      const results = await airtableQuery.getTableAsync(
        "Art Inventory",
        1,
        role,
        filterByFormulaIdQuery,
        fields,
      );
      commit("setCurItemDetailRecord", results[0]);
    },

    resetFilters({ commit }) {
      commit("clearFilters");
    },
  },
});
