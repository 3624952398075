import Vue from "vue";
import AddItem from "../views/AddItem";
import AirtableUtilities from "../views/AirtableUtilities";
import AppLogin from "../components/pages/AppLogin";
import ArtInventoryPage from "../components/pages/ArtInventoryPage";
import CategoriesPage from "../components/pages/CategoriesPage";
import CompareList from "../components/pages/CompareList";
import FavoritesPage from "../components/pages/FavoritesPage";
import CollectionsPage from "../components/pages/CollectionsPage";
import InvoiceDetails from "../views/InvoiceDetails";
import ItemDetails from "../views/ItemDetails";
import LatestPurchasesPage from "../components/pages/LatestPurchasesPage";
import ListMerchants from "../components/pages/MerchantsPage";
import Location from "../components/pages/LocationDetailPage";
import LocationsPage from "../components/pages/LocationsPage";
import NotFound from "../views/NotFound";
import QRScanner from "../components/ui/QRScanner";
import RoomScan from "../components/pages/room_scans/RoomScanPage";
import CartTransferList from "../components/pages/CartTransferList.vue";
import CartShopping from "../components/pages/CartShopping";
import UpdateAirtable from "../views/UpdateAirtable";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "AppLogin",
    component: AppLogin,
    meta: { requiresAuth: false },
  },
  {
    path: "/art-inventory",
    name: "ArtInventoryPage",
    component: ArtInventoryPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/location",
    name: "Location",
    component: Location,
    meta: { requiresAuth: true },
  },
  {
    path: "/locations",
    name: "LocationsPage",
    component: LocationsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/categories",
    name: "Categories",
    component: CategoriesPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/compare",
    name: "Compare",
    component: CompareList,
    meta: { requiresAuth: true },
  },
  {
    path: "/Favorites",
    name: "Favorites",
    component: FavoritesPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/latest-purchases",
    name: "LatestPurchasesPage",
    component: LatestPurchasesPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/airtable-utilities",
    name: "AirtableUtilities",
    component: AirtableUtilities,
    meta: { requiresAuth: true },
  },
  {
    path: "/collections",
    name: "CollectionsPage",
    component: CollectionsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/add-item",
    name: "AddItem",
    component: AddItem,
    meta: { requiresAuth: true },
  },
  {
    path: "/scan-item",
    name: "QRScanner",
    component: QRScanner,
    meta: { requiresAuth: true },
  },
  {
    path: "/merchants",
    name: "ListMerchants",
    component: ListMerchants,
    meta: { requiresAuth: true },
  },
  {
    path: "/item/:itemId",
    name: "ItemDetails",
    component: ItemDetails,
    meta: { requiresAuth: true },
  },
  {
    path: "/sublocations/:sublocId",
    redirect: "/art-inventory",
    query: {
      categories: "",
      collections: "",
      dateEnd: "",
      dateStart: "",
      favorites: "",
      itemIds: "",
      keyword: "",
      layout: "masonry-small",
      lengthMax: "",
      lengthMin: "0",
      locations: "",
      merchants: "",
      priceMax: "",
      priceMin: "",
      quality: "",
      sublocations: ":sublocId",
    },
    name: "Sublocation",
    component: ArtInventoryPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/invoice/:invoiceId",
    name: "invoice",
    component: InvoiceDetails,
    meta: { requiresAuth: true },
  },
  {
    path: "/transfer-cart",
    name: "CartTransferList",
    component: CartTransferList,
    meta: { requiresAuth: true },
  },
  {
    path: "/shopping-cart",
    name: "CartShopping",
    component: CartShopping,
    meta: { requiresAuth: true },
  },
  {
    path: "/room-scan",
    name: "RoomScanPage",
    component: RoomScan,
    meta: { requiresAuth: true },
  },
  {
    path: "/update-airtable",
    name: "UpdateAirtable",
    component: UpdateAirtable,
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    redirect: { name: "ArtInventoryPage" },
    meta: { requiresAuth: true },
  },
  {
    path: "*",
    redirect: "/404",
    component: NotFound,
    meta: { requiresAuth: false },
  },
  {
    path: "/404",
    name: "404",
    component: NotFound,
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

let entryUrl = null;
router.beforeEach((to, from, next) => {
  let localStorageUserData = JSON.parse(
    localStorage.getItem(["CycadVuex"].curUserData),
  );
  let storeUserData = store.getters.getLoggedInUserData || "";
  let curUserData = localStorageUserData || storeUserData;

  let isAuthenticated =
    curUserData.token !== "" && curUserData.token !== undefined;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      if (to.name !== "AppLogin" && to.name !== "ArtInventoryPage") {
        entryUrl = to.fullPath;
        console.log("entryUrl=", entryUrl);
      }
      if (to.name !== "AppLogin") {
        next({ name: "AppLogin" });
      }
    } else if (entryUrl) {
      console.log("is authed, has entry");
      let url = entryUrl;
      entryUrl = null;
      router.push({ path: url });
    } else {
      if (to.name === from.name) {
        return;
      }
      next();
    }
  } else {
    next();
  }
});

export default router;
