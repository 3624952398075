<template>
  <transition name="modal-fade">
    <div class="modal-backdrop transfer-modal">
      <div
        class="confirm-transfer-modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header">
          <h2 class="modal-title">Transfer Request</h2>
          <button
            type="button"
            role="button"
            class="btn-close-modal"
            aria-label="Close modal"
            @click="$emit('close-location-modal')"
          >
            <svg
              data-name="CloseX"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <path
                d="M31.06,32a.94.94,0,0,1-.67-.28L16,17.33,1.61,31.72A.94.94,0,0,1,.28,30.39L14.67,16,.28,1.61A.94.94,0,0,1,1.61.28L16,14.67,30.39.28a.94.94,0,0,1,1.33,1.33L17.33,16,31.72,30.39a.94.94,0,0,1,0,1.33A.9.9,0,0,1,31.06,32Z"
                transform="translate(0 0)"
              />
            </svg>
          </button>
        </header>
        <section id="modalDescription" class="modal-body">
          <header class="transfer-list-header">
            <span class="item-name">Items</span>
            <span class="item-destination">Destination</span>
            <span class="need-by">Need By</span>
          </header>
          <ol class="transfer-list">
            <li v-for="(value, name) in transferOrder" :key="name">
              <ul class="items">
                <li v-for="(item, index) in value" :key="index" class="item">
                  <div class="item-title">
                    {{ item.item.fields.TitleClean }}
                  </div>
                  <div class="item-id">ID: {{ item.item.id }}</div>
                  <ItemTransferNotes
                    :key="`item_note_${item.item.id}`"
                    :item-id="item.item.id"
                    @update-item-note="handleUpdateItemNote"
                  />
                </li>
              </ul>
              <span class="item-destination">
                <span class="item-arrow">&#x279e;</span>
                <span class="location">{{ name }}</span>
              </span>
              <!-- datepicker -->
              <Datepicker
                name="need by"
                wrapper-class="need-by"
                calendar-class="calendar-wrap"
                placeholder="Transfer by"
                input-class="c-dropdown__title js-dropdown-title"
                @selected="handleSetNeedByDate($event, value)"
              />
            </li>
          </ol>
          <footer class="modal-footer">
            <!-- email -->
            <div class="enter-email">
              <div class="checkbox-wrap">
                <div class="form-group">
                  <input
                    id="enableEmail"
                    class="squarecheck"
                    type="checkbox"
                    @click="handleCheckbox"
                  />
                  <label for="enableEmail">
                    Check here for an email copy.
                  </label>
                </div>
              </div>
              <div class="email-wrap">
                <div class="form-group">
                  <input
                    id="managerEmail"
                    class="c-form__input"
                    type="email"
                    placeholder="Enter email"
                    aria-label="Search"
                    onfocus="this.placeholder=''"
                    onblur="this.placeholder='Enter email'"
                    :disabled="isDisabled"
                    @keyup="handleChange($event)"
                  />
                </div>
              </div>
            </div>
            <div class="btn-toolbar">
              <button
                class="c-btn btn-cancel"
                aria-label="Cancel"
                @click="$emit('close-location-modal')"
              >
                Cancel
              </button>
              <button
                class="c-btn btn-success"
                :disabled="networkBusyStatus"
                aria-label="Confirm"
                @click="sendTransferRequest"
              >
                <span v-if="!isSending">Send request</span>
                <span v-if="isSending" class="is-loading">
                  <Progress fill-color="#FFFFFF" />
                </span>
              </button>
            </div>
          </footer>
        </section>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapState } from "vuex";
import Progress from "../ui/Progress";
import Datepicker from "vuejs-datepicker";
import ItemTransferNotes from "../ui/ItemTransferNotes";

export default {
  name: "ConfirmTransferModal",
  components: {
    Datepicker,
    ItemTransferNotes,
    Progress,
  },
  props: {
    transferOrder: {
      type: Object,
      required: true,
      default: () => {},
    },
    isSending: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      ccEmail: "",
      isDisabled: true,
      finalOrder: [],
    };
  },
  computed: mapState(["networkBusyStatus", "curUserData"]),
  created() {
    for (const value of Object.entries(this.transferOrder)) {
      let collection = [];
      let notesCombined = [];
      value.forEach((item) => {
        collection.push(item.item.id);
        notesCombined.push({
          itemId: item.item.id,
          itemTitle: `${item.item.fields.TitleClean.substring(0, 22)}...`,
          itemNote: "",
        });
      });
      this.finalOrder.push({
        fields: {
          Collection: collection,
          Location: value[0].newLocationId,
          LocationName: value[0].newLocationName,
          NeedByDate: null,
          Notes: notesCombined,
          Status: "Submitted",
          User: this.curUserData.fields.FullName,
        },
      });
    }
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    handleChange(e) {
      this.ccEmail = e.target.value;
    },
    handleUpdateItemNote(note, itemId) {
      for (let i in this.finalOrder) {
        if (this.finalOrder.hasOwnProperty(i)) {
          if (this.finalOrder[i].fields.Collection.includes(itemId)) {
            this.finalOrder[i].fields.Notes.forEach((item, n) => {
              if (item.itemId === itemId) {
                this.finalOrder[i].fields.Notes[n].itemNote = note;
              }
            });
          }
        }
      }
    },
    handleSetNeedByDate(event, order) {
      let locationId = order[0].newLocationId[0];
      let date = new Date(event);
      let options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      for (let i in this.finalOrder) {
        if (this.finalOrder.hasOwnProperty(i)) {
          if (this.finalOrder[i].fields.Location[0].includes(locationId)) {
            this.finalOrder[i].fields.NeedByDate = date.toLocaleDateString(
              "en-US",
              options,
            );
          }
        }
      }
    },
    handleCheckbox() {
      this.isDisabled = !this.isDisabled;
    },
    handleKeyDown(event) {
      if (event.keyCode === 27) {
        this.$emit("close-location-modal");
      }
    },
    sendTransferRequest() {
      this.$emit("close-location-modal");
      this.$emit("send-transfer-request", this.finalOrder, this.ccEmail);
    },
  },
};
</script>
